import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { IndiceEntidade } from "../entidades/IndiceEntidade";
import { IndiceValoresEntidade } from "../entidades/IndiceValoresEntidade";

class Indice extends BaseService {
	constructor() {
		super("Indice");
	}

	BuscarPorCodigo = (codIndice: string) =>
		this.CreateRequest<IndiceEntidade>(RequestType.GET, `BuscarPorCodigo/${codIndice}`);

	BuscarUltimoPorCodigo = (codIndice: string) =>
		this.CreateRequest<IndiceEntidade>(RequestType.GET, `BuscarUltimoPorCodigo/${codIndice}`);

	BuscarUltimoPorCodigoData = (codIndice: string) =>
		this.CreateRequest<IndiceValoresEntidade>(RequestType.GET, `BuscarUltimoPorCodigoData/${codIndice}`);

}

export const IndiceService = new Indice();
