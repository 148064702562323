import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { DependenteEntidade } from "../entidades/DependenteEntidade";

class Dependente extends BaseService {
	constructor() {
		super("Dependente");
	}

	BuscarTodos = () =>
		this.CreateRequest<Array<DependenteEntidade>>(RequestType.GET, `BuscarTodos`);

	BuscarPorSeqDep = (seqDep: string) =>
		this.CreateRequest<DependenteEntidade>(RequestType.GET, `BuscarPorSeqDep/${seqDep}`);

	Atualizar = (entidade: DependenteEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Atualizar`, entidade);

	Incluir = (entidade: DependenteEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Incluir`, entidade);

	Excluir = (entidade: DependenteEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Excluir`, entidade);

}

export const DependenteService = new Dependente();
