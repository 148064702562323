import { Alert, Box, Button, Col, useModal, Row, StaticField } from "@intech/web-ui";
import React, { useEffect, useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { LoadingComponent } from "@components";
import {
  PlanoVinculadoEntidade,
  ProcessoBeneficioEntidade,
} from "@entidades";
import { HistSaldoService } from "@services";
import defaultTheme from "@styles/theme";
import { Container } from "./styles";

interface IProps {
  plano: PlanoVinculadoEntidade;
  beneficio: ProcessoBeneficioEntidade;
}

export const SaldoAtual: React.FC<IProps> = ({ plano, beneficio }) => {

  const history = useHistory();
  const modal = useModal();

  const [Erro, setErro] = useState<boolean>(false);
  const [Saldo, setSaldo] = useState<any>(null);

  const [PopupMessage, setPopupMessage] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        var saldo = await HistSaldoService.BuscarPorPlano(plano.CD_PLANO);
        setSaldo(saldo);
      }
      catch (e) {
        console.log(e);
        setErro(true);
      }
    })();
  }, [plano]);

  if (Erro) {
    return null;
  }

  if (Saldo) {
    return (
      <Container
        title="Saldo Atual"
        badgeText={`Referência: ${Saldo.DataIndice}`}
      >
        <Row>
          <Col>
            <StaticField
              title="Saldo Atual em Cotas"
              labelSize={4}
              value={
                Saldo.TotalCotas.toLocaleString("pt-br", {
                  minimumFractionDigits: 8,
                  maximumFractionDigits: 8,
                })!
              }
            />

            <StaticField
              title="Valor da Cota"
              labelSize={4}
              value={`${Saldo.ValorIndice.toLocaleString("pt-br", {
                minimumFractionDigits: 8,
                maximumFractionDigits: 8,
              })} (em ${Saldo.DataIndice})`}
            />

            <StaticField
              title="Saldo Atual em Reais"
              labelSize={4}
              value={Saldo.Valor}
              type="money"
            />

            <StaticField
              title="Total de Parcelas Pagas"
              labelSize={4}
              value={Saldo.TotalParcelasPagas}
            />
          </Col>

          <Col>
            <Box>
              {(beneficio.CD_TIPO_CALC_CD === 5 || plano.CD_PLANO === "0001") &&
                <div className="text-center">
                  O tempo de exaurimento da sua renda é de
                <br />
                  <h1>{beneficio.DS_TEMPO_EXAURIMENTO}</h1>
                  <br />
                  <Button
                    type="link"
                    title="Entenda o Cálculo"
                    onClick={() =>
                      modal.openModal({
                        title: beneficio.DS_TIPO_CALC_CD,
                        type: "message",
                        icon: <FaQuestionCircle
                          color={defaultTheme.colors.primary}
                          size={45}
                          style={{ marginBottom: "20px" }}
                        />,
                        content: <>
                          {beneficio.CD_TIPO_CALC_CD === 5 && (
                            <>
                              Os benefícios pagos sob forma de <b>Prazo Determinado</b>{" "}
              consideram na projeção de exaurimento da renda, o prazo inicial
              definido quando do requerimento do benefício, descontados os
              benefícios já pagos. O valor mensal do benefício é pago em
              quantidade quotas, variando em reais pelo valor da cota vigente.
            </>
                          )}

                          {beneficio.CD_TIPO_CALC_CD === 6 && (
                            <>
                              Os benefícios pagos sob forma de <b>Renda Mensal Atuarial</b>{" "}
              consideram na projeção de exaurimento da renda a expectativa de
              vida e a rentabilidade alvo definida pela SABESPREV no exercício.
            </>
                          )}

                          {beneficio.CD_TIPO_CALC_CD === 8 && (
                            <>
                              Os benefícios pagos sob forma de <b>Renda Definida em Reais</b>
              consideram na projeção de exaurimento da renda o valor bruto do
              benefício mensal atual, bem como a rentabilidade projetada pela
              SABESPREV no exercício citado, de forma a estimar o tempo
              remanescente da sua renda atual.
            </>
                          )}
                        </>,
                      })}
                  />
                </div>
              }
              {(beneficio.CD_TIPO_CALC_CD !== 5 && plano.CD_PLANO !== "0001") &&
                <Alert type="info">
                  Tempo de Exaurimento em construção.
                </Alert>
              }
            </Box>
          </Col>
        </Row>
      </Container>
    );
  }

  return <LoadingComponent />;
};
