import React from 'react';

export const Header: React.FC = () => {

  return (
    <thead>
      <tr>
        <th>Referência</th>
        <th>Tipo</th>
        <th className={"text-right"}>Bruto</th>
        <th className={"text-right"}>Descontos</th>
        <th className={"text-right"}>Líquido</th>
        <th></th>
      </tr>
    </thead>
  );
}