import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { PlanoEntidade } from "../entidades/PlanoEntidade";
import { PlanoVinculadoEntidade } from "../entidades/PlanoVinculadoEntidade";

class Plano extends BaseService {
  constructor() {
    super("Plano");
  }

  BuscarTodos = () =>
    this.CreateRequest<Array<PlanoEntidade>>(RequestType.GET, `BuscarTodos`);

  Buscar = () =>
    this.CreateRequest<Array<PlanoVinculadoEntidade>>(RequestType.GET, `Buscar`);

  BuscarPorCodigo = (cdPlano: string) =>
    this.CreateRequest<PlanoVinculadoEntidade>(RequestType.GET, `BuscarPorCodigo/${cdPlano}`);

  BuscarPorEmpresa = (cdEmpresa: string) =>
    this.CreateRequest<Array<PlanoEntidade>>(RequestType.GET, `BuscarPorEmpresa/${cdEmpresa}`);

  BuscarTempoPlano = (cdPlano: string) =>
    this.CreateRequest<string>(RequestType.GET, `BuscarTempoPlano/${cdPlano}`);

  RelatorioExtratoPorPlanoEmpresaReferencia = (cdPlano: string, dtInicio: string, dtFim: string, enviarPorEmail: Boolean) =>
    this.CreateRequest<any>(RequestType.GET, `RelatorioExtratoPorPlanoEmpresaReferencia/${cdPlano}/${dtInicio}/${dtFim}/${enviarPorEmail}`, null, ResponseType.Blob);

  RelatorioCertificado = (cdPlano: string, enviarPorEmail: Boolean) =>
    this.CreateRequest<any>(RequestType.GET, `RelatorioCertificado/${cdPlano}/${enviarPorEmail}`, null, ResponseType.Blob);

  PossuiCertificadoSeguro = () =>
    this.CreateRequest<boolean>(RequestType.GET, `PossuiCertificadoSeguro`);

  RelatorioCertificadoSeguro = (enviarPorEmail: Boolean) =>
    this.CreateRequest<any>(RequestType.GET, `RelatorioCertificadoSeguro/${enviarPorEmail}`, null, ResponseType.Blob);

}

export const PlanoService = new Plano();
