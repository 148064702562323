export class UltimaContribuicaoEntidade {
    
    public DataReferencia: Date;
    
    public ValorAposentadoriaParticipante: number;
	public ValorAposentadoriaEmpresa: number;
    public ValorAposentadoriaTotal: number;

    public ValorBeneficioRiscoParticipante: number;
	public ValorBeneficioRiscoEmpresa: number;
    public ValorBeneficioRiscoTotal: number;


    public ValorSobrevivenciaParticipante: number;
	public ValorSobrevivenciaEmpresa: number;
    public ValorSobrevivenciaTotal: number;


    public ValorCarregamentoParticipante: number;
	public ValorCarregamentoEmpresa: number;
    public ValorCarregamentoTotal: number;

    public ValorTotalParticipante: number;
	public ValorTotalEmpresa: number;
    public ValorTotal: number;

}