import React from 'react';
import { Button, ButtonType } from '@intech/web-ui';

interface IProps {
  avancarHabilitado?: boolean;
  tituloAvancarAlternativo?: string;
  tipeAvancarAlternativo?: ButtonType;
  avancar?: () => void;
  voltar?: () => void;
}

export const FooterBar: React.FC<IProps> = ({
  avancarHabilitado = true,
  tituloAvancarAlternativo,
  tipeAvancarAlternativo,
  avancar,
  voltar
}) => {
  return (
    <div>
      {voltar &&
        <Button
          className={"float-left"}
          title={"Anterior"}
          type={"info"}
          onClick={voltar}
        />}

      {avancar &&
        <Button
          className={"float-right"}
          title={tituloAvancarAlternativo ? tituloAvancarAlternativo : "Próximo"}
          type={tipeAvancarAlternativo ? tipeAvancarAlternativo : "info"}
          onClick={avancar}
          disabled={!avancarHabilitado}
        />}
    </div>
  );
}