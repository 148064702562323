import { Col } from "@intech/web-ui";
import styled, { css } from "styled-components";
import { lighten } from "polished";
import { IconProps, TableItemProps } from "./";

const colors = {
  danger: css`
    color: ${props => lighten(0.1, props.theme.colors.red)};
  `,
  warning: css`
    color: ${props => lighten(0.1, props.theme.colors.yellow)};
  `,
  info: css`
    color: ${props => lighten(0.2, props.theme.colors.blue)};
  `,
  success: css`
    color: ${props => lighten(0.05, props.theme.colors.green)};
  `
}

export const Cols = styled(Col)`
  flex-direction: Col;
`;

export const Title = styled.h2`
  color: #7c7e80;
`;

export const Icon = styled.span<IconProps>`
  margin: 0 5px;
  ${props => colors[props.type]}
`;
