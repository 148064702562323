import { BaseService, RequestType, ResponseType } from "@intech/react-service";

class Email extends BaseService {
	constructor() {
		super("Email");
	}

	Enviar = (data: any) =>
		this.CreateRequest<any>(RequestType.POST, `Enviar`, data);

}

export const EmailService = new Email();
