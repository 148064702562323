import React, { Component } from "react";
import moment from "moment";

import { IndiceService, SimNaoParticipanteService } from "@services";

import { SimulacaoBeneficioEntidade } from "@entidades";

import { FooterBar } from "./../FooterBar";
import { Col, Row, StaticField } from "@intech/web-ui";
import { LoadingComponent } from "@components";

interface Props {
  Dados: any;
  passoAnterior: () => void;
  passoInicial: () => void;
}

interface State {
  resultadoSimulacao: SimulacaoBeneficioEntidade
}

export class Passo4 extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      resultadoSimulacao: null
    }
  }

  componentDidMount = async () => {
    try {
      await this.calcularSimulacao();
    } catch (err) {
      console.error(err);
    }
  }

  CalculaIdadeDecimal = () => {
    const dtn = this.props.Dados.dadosPessoais.DadosPessoais.DT_NASCIMENTO;

    return moment().diff(moment(dtn, "DD/MM/YYYY"), "years", true).toFixed(2);
  }

  calcularSimulacao = async () => {
    const taxaJurosReal = await IndiceService.BuscarUltimoPorCodigo("TX_ANUAL");

    var dadosSimulacao = {
      idade: this.props.Dados.idadeAtual,
      // idadeDecimal: this.props.Dados.idadeDecimal,
      idadeDecimal: this.CalculaIdadeDecimal(),
      tipoAtivo: this.props.Dados.tipoAtivo,
      idadeAposentadoria: this.props.Dados.idadeAposentadoria,
      tempoAposentadoria: this.props.Dados.tempoAposentadoria,
      tempoSobrevivencia: this.props.Dados.tempoSobrevivencia,
      remuneracaoInicial: this.props.Dados.remuneracaoInicial,
      remuneracaoFinal: this.props.Dados.remuneracaoFinal,

      taxaCrescimentoSalarial: this.props.Dados.taxaCrescimentoSalarial,
      taxaJurosReal: taxaJurosReal && taxaJurosReal.VALORES && taxaJurosReal.VALORES.length > 0 ? taxaJurosReal.VALORES[0].VALOR_IND : 0,//3.75, //this.props.Dados.taxaJurosReal,

      // participante
      percentualBasicoParticipante: this.props.Dados.percentualBasicoParticipante,
      beneficioRiscoInvalidezParticipante: this.props.Dados.beneficioRiscoInvalidezParticipante,
      beneficioRiscoPensaoMorteParticipante: this.props.Dados.beneficioRiscoMorteParticipante,
      sobrevivenciaParticipante: this.props.Dados.sobrevivenciaParticipante,
      carregamentoContribuicaoBasicaParticipante: this.props.Dados.carregamentoContribuicaoBasicaParticipante,

      // patrocinadora -- recebe os mesmos valores do participante
      percentualBasicoPatrocinador: this.props.Dados.percentualBasicoPatrocinador,
      beneficioRiscoInvalidezPatrocinador: this.props.Dados.beneficioRiscoInvalidezPatrocinador,
      beneficioRiscoPensaoMortePatrocinador: this.props.Dados.beneficioRiscoMortePatrocinador,
      sobrevivenciaPatrocinador: this.props.Dados.sobrevivenciaPatrocinador,
      carregamentoContribuicaoBasicaPatrocinador: this.props.Dados.carregamentoContribuicaoBasicaPatrocinador,

      contribuicaoAdicionalAposentadoria: this.props.Dados.aposentadoriaAdicional,
      contribuicaoAdicionalInvalidez: this.props.Dados.invalidezAdicional,
      contribuicaoAdicionalPensaoMorte: this.props.Dados.pensaoMorteAdicional,
      contribuicaoAdicionalSobrevivencia: this.props.Dados.sobrevivenciaAdicional,
      carregamentoContribuicaoAdicional: this.props.Dados.carregamentoContribuicaoAdicional,
      // dtNascimento: this.props.Dados.dadosPessoais.DadosPessoais.DT_NASCIMENTO,
      saldoAcumulado: this.props.Dados.SaldoAcumulado
    }

    try {
      var resultadoSimulacao = await SimNaoParticipanteService.Calcular(dadosSimulacao);

      await this.setState({
        resultadoSimulacao
      })
    } catch (err) {
      alert('Erro' + err)
      console.error(err);
    }
  }

  mostrarResultado() {

  }

  anterior = () => {
    // metódo do formulario Pai
    this.props.passoAnterior();
  };

  novaSimulacao = () => {
    // metódo do formulario Pai
    this.props.passoInicial();
  };

  atualizaDados() {

  };

  render() {

    return (
      <div>
        {this.state.resultadoSimulacao === null && <LoadingComponent />}

        {this.state.resultadoSimulacao &&
          <>
            <div>

              <Row>
                <Col>
                  <h2 className="text-center">Vamos ver quanto você vai contribuir inicialmente e quanto você vai acumular até a data da sua aposentadoria!.</h2>
                </Col>
              </Row>

              <div className="container">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Contrib. Inicial Participante</th>
                        <th>Contrib. Inicial Patrocinador</th>
                        <th>Contribuição Inicial Total</th>
                        <th>Montante Acumulado</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Aposentadoria</td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialAposentadoriaParticipante}
                            type={"money"}
                          />
                        </td>

                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialAposentadoriaPatrocinador}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialTotalAposentadoria}
                            type={"money"} />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.montanteAposentadoria}
                            type={"money"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Benefício de Risco - Invalidez</td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialInvalidezParticipante}
                            type={"money"}
                          />
                        </td>

                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialInvalidezPatrocinador}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialTotalInvalidez}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.montanteInvalidez}
                            type={"money"}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Benefício de Risco - Pensão por Morte</td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialPensaoMorteParticipante}
                            type={"money"}
                          />
                        </td>

                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialPensaoMortePatrocinador}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialTotalPensaoMorte}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.montantePensaoMorte}
                            type={"money"}
                          />
                        </td>

                        {/* <td><BotaoAjuda textoModal="Sujeito a aceitação da Seguradora." /></td> */}
                      </tr>

                      <tr>
                        <td>Sobrevivência</td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialSobrevivenciaParticipante}
                            type={"money"}
                          />
                        </td>

                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialSobrevivenciaPatrocinador}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialTotalSobrevivencia}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.montanteSobrevivencia}
                            type={"money"}
                          />
                        </td>

                      </tr>

                      <tr>
                        <td>Carregamento Total</td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.carregamentoTotalParticipante}
                            type={"money"}
                          />
                        </td>

                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.carregamentoTotalPatrocinador}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoInicialTotalCarregamentoTotal}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>-</td>
                        <td></td>
                      </tr>

                      <tr>
                        <td>Contribuição Total</td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoTotalParticipante}
                            type={"money"}
                          />
                        </td>

                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoTotalPatrocinador}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>
                          <StaticField
                            value={this.state.resultadoSimulacao.contribuicaoTotal}
                            type={"money"}
                          />
                        </td>
                        <td className={'text-right'}>-</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <Row>
                <Col>
                  <h2 className="text-center">Com base nos parâmetros informados e nas projeções calculadas, esses são os resultados comparativos estimados:</h2>
                </Col>
              </Row>

              <div className="responsive-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>BENEFÍCIO MENSAL SIMULADO</th>
                      <th>PREVES</th>
                      <th>PREVES + RPPS/RGPS</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Aposentadoria</td>
                      <td>
                        <StaticField
                          value={this.state.resultadoSimulacao.beneficioMensalAposentadoria}
                          type={"money"}
                        />
                      </td>
                      <td>
                        <StaticField
                          value={this.props.Dados.tipoAtivo === 'A' ? this.state.resultadoSimulacao.beneficioMensalAposentadoria + this.props.Dados.rgps : this.state.resultadoSimulacao.beneficioMensalAposentadoria}
                          type={"money"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Benefício de risco - Invalidez</td>
                      <td>
                        <StaticField
                          value={this.state.resultadoSimulacao.beneficioMensalInvalidez}
                          type={"money"}
                        />
                      </td>
                      <td>
                        <StaticField
                          value={this.props.Dados.tipoAtivo === 'A' ? this.state.resultadoSimulacao.beneficioMensalInvalidez + this.props.Dados.rgps : this.state.resultadoSimulacao.beneficioMensalInvalidez}
                          type={"money"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Benefício de risco - Pensão por Morte</td>
                      <td>
                        <StaticField
                          value={this.state.resultadoSimulacao.beneficioMensalPensaoMorte}
                          type={"money"}
                        />
                      </td>
                      <td>
                        <StaticField
                          value={this.props.Dados.tipoAtivo === 'A' ? this.state.resultadoSimulacao.beneficioMensalPensaoMorte + this.props.Dados.rgps : this.state.resultadoSimulacao.beneficioMensalPensaoMorte}
                          type={"money"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Sobrevivência</td>
                      <td>
                        <StaticField
                          value={this.state.resultadoSimulacao.beneficioMensalSobrevivencia}
                          type={"money"}
                        />
                      </td>
                      <td>
                        <StaticField
                          value={this.props.Dados.tipoAtivo === 'A' ? this.state.resultadoSimulacao.beneficioMensalSobrevivencia + this.props.Dados.rgps : this.state.resultadoSimulacao.beneficioMensalSobrevivencia}
                          type={"money"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="row">
                <div className="col">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>OUTRAS INFORMAÇÕES</th>
                        <th></th>
                      </tr>
                    </thead>
                    {this.props.Dados.tipoAtivo !== 'A' &&
                      <tbody>
                        <tr>
                          <td>Remuneração Final estimulada pelo Crescimento Bianual</td>
                          <td className={'text-right'}>
                            <StaticField
                              value={this.state.resultadoSimulacao.remuneracaoFinalCrescimentoBianual}
                              type={"money"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Remuneração Máxima paga pelo RPPS/RGPS na aposentadoria</td>
                          <td className={'text-right'}>-</td>
                        </tr>
                        <tr>
                          <td>Remuneração Adicional Desejada na Aposentadoria</td>
                          <td className={'text-right'}>
                            <StaticField
                              value={this.state.resultadoSimulacao.remuneracaoFinalCrescimentoBianual}
                              type={"money"}
                            />
                          </td>
                        </tr>
                      </tbody>
                    }
                    {this.props.Dados.tipoAtivo === 'A' &&
                      <tbody>
                        <tr>
                          <td>Remuneração Líquida Final estimulada pelo Crescimento Bianual</td>
                          <td className={'text-right'}>
                            <StaticField
                              value={this.state.resultadoSimulacao.salarioLiquido}
                              type={"money"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Remuneração Líquida Máxima paga pelo RPPS/RGPS + RPC na aposentadoria</td>
                          <td className={'text-right'}>
                            <StaticField
                              value={this.state.resultadoSimulacao.rendaLiquida}
                              type={"money"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Percentual de Remuneração Líquida esperada</td>
                          <td className={'text-right'}>
                            <StaticField
                              value={((this.state.resultadoSimulacao.rendaLiquida / this.state.resultadoSimulacao.salarioLiquido) * 100).toString()}
                              type={"percent"}
                            />
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>

            <hr />

            <FooterBar
              tituloAvancarAlternativo={"Nova Simulação"}
              tipeAvancarAlternativo={"danger"}
              avancar={this.novaSimulacao}
              voltar={this.anterior}
            />

          </>
        }
      </div>
    )
  }
}


export default Passo4