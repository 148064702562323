import React, { useEffect, useState } from 'react';
import { DependenteEntidade } from '@entidades';

import { LoadingComponent } from '@components';
import { Alert } from '@intech/web-ui';
import { DependenteService } from '@services';
import { Regular } from './Regular';
import { Pensionista } from './Pensionista';

const Beneficiarios: React.FC = () => {

  const [AlertText, setAlertText] = useState<string>("");
  const [ListaDependentes, setListaDependentes] = useState<Array<DependenteEntidade>>(undefined);

  useEffect(() => {
    (async () => {
      try {

        const ListaDependentes = await DependenteService.BuscarTodos();
        setListaDependentes(ListaDependentes);

      }
      catch (err) {
        const error = err.response ? err.response.data : err;

        setAlertText(error);
      }
    })();
  }, []);

  function renderDependentes() {
    if (AlertText !== "") {
      return <Alert type={"danger"}>{AlertText}</Alert>;
    }

    if (ListaDependentes !== undefined) {
      if (ListaDependentes.length > 0 && ListaDependentes[0].NUM_SEQ_GR_FAMIL)
        return (
          <Pensionista ListaDependentes={ListaDependentes} />
        );
      return (
        <Regular ListaDependentes={ListaDependentes} />
      );
    }

    return <LoadingComponent />;
  }

  return renderDependentes();
}

export default Beneficiarios;