import { Row, Col, ComboBox, Alert } from "@intech/web-ui";
import { buscarBloqueio, NumFuncionalidade } from "@src/helpers/BloqueioFuncionalidade";
import React, { useEffect, useState } from "react";
import { PlanoVinculadoEntidade, ProcessoBeneficioEntidade } from "@entidades";
import { ProcessoBeneficioService } from "@services";
import { HomeCard } from "./../HomeCard/HomeCard";
import { SaldoAtual } from "./SaldoAtual";
import { UltimaFolha } from "./UltimaFolha";
import { Beneficiarios, LoadingComponent } from "@components";

interface IProps {
  plano: PlanoVinculadoEntidade;
}

export const Assistido: React.FC<IProps> = ({
  plano
}) => {
  const [beneficios, setBeneficios] = useState<ProcessoBeneficioEntidade[]>([]);
  const [beneficio, setBeneficio] = useState<ProcessoBeneficioEntidade | null>(
    null
  );
  const [EspecieAnoNumProcesso, setEspecieAnoNumProcesso] = useState<string>("");
  const [Loading, setLoading] = useState<boolean>(true);
  const [MotivoBloqueio, setMotivoBloqueio] = useState<string>("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setMotivoBloqueio(await buscarBloqueio(NumFuncionalidade.HOME_ASISSTIDOS));
        var processosBeneficio = await ProcessoBeneficioService.BuscarPorPlano(
          plano.CD_PLANO
        );
        setBeneficios(processosBeneficio);
        setBeneficio(processosBeneficio[0]);
      }
      catch (err) {
        // eslint-disable-next-line
        const e: any = err;
        alert(e.response ? e.response.data : e);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [plano]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const beneficio = beneficios.filter((b) => b.ESPECIE_ANO_NUM_PROCESSO === EspecieAnoNumProcesso)[0];
        setBeneficio(beneficio);
      }
      catch (err) {
        // eslint-disable-next-line
        const e: any = err;
        alert(e.response ? e.response.data : e);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [EspecieAnoNumProcesso]);

  if (MotivoBloqueio !== "") {
    return (
      <Alert type={"danger"}>
        {MotivoBloqueio}
      </Alert>
    );
  }

  if (beneficio) {
    return (
      <>
        {beneficios.length > 1 && (
          <div className="mb-3">
            <ComboBox
              value={EspecieAnoNumProcesso}
              title={"Benefício"}
              memberName={"DS_PROCESSO"}
              memberValue={"ESPECIE_ANO_NUM_PROCESSO"}
              data={beneficios}
              onChange={setEspecieAnoNumProcesso}
            />
          </div>
        )}

        {Loading && <LoadingComponent />}

        {!Loading && <>
          <Row>
            <Col>
              <HomeCard titulo={"Plano"}>
                {plano.DS_PLANO}
                <br />
                <small style={{ fontSize: 12 }}>{`CNPB ${plano.COD_CNPB}`}</small>
              </HomeCard>
            </Col>
            <Col>
              <HomeCard titulo={"Tipo de Benefício"} >
                {beneficio.DS_ESPECIE}
              </HomeCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <HomeCard titulo={"Data de Concessão do Benefício"}>
                {beneficio.DT_INICIO_FUND}
              </HomeCard>
            </Col>
            <Col>
              <HomeCard titulo={"Tipo de Renda"}>
                {beneficio.DS_TIPO_CALC_CD}
              </HomeCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <HomeCard titulo={"Regime de Tributação"}>
                {plano.TIPO_IRRF === "2" ? "Regressivo" : "Progressivo"}
              </HomeCard>
            </Col>
            <Col>
              <HomeCard titulo={"Situação"}>{beneficio.DS_SITUACAO}</HomeCard>
            </Col>
          </Row>

          <SaldoAtual plano={plano} beneficio={beneficio} />


          <Row>
            <Col>
              <Beneficiarios />
            </Col>

            <Col>
              <UltimaFolha beneficio={beneficio} />
            </Col>
          </Row>
        </>}
      </>
    );
  }

  return null;
};
