import React, { useRef, useState } from "react";
import moment from 'moment';
import { Row, Col, Alert, Form, Input, Button } from "@intech/web-ui";
import { PlanoService } from "@services";
import { AvisoDownloadPDF } from "@components";

interface IProps {
  cdPlano: string;
  dataInicio: string;
}

export const ModalConteudo: React.FC<IProps> = ({
  cdPlano,
  dataInicio
}) => {

  const [RodouDownloadPDF, setRodouDownloadPDF] = useState<boolean>(false);
  const [FormText, setFormText] = useState(null);
  const [DataInicio, setDataInicio] = useState<string>(dataInicio);
  const [DataFim, setDataFim] = useState<string>(moment().format("DD/MM/YYYY"));

  const form = useRef<Form>(null);

  async function gerarExtrato() {
    try {
      setRodouDownloadPDF(false);
      setFormText("");

      const erros = await form.current.validate();
      if (erros) {
        setFormText(erros);
        return null;
      }

      const dataInicio = moment(DataInicio, "DD/MM/YYYY");
      const dataFim = moment(DataFim, "DD/MM/YYYY");

      if (!dataInicio.isValid()) {
        setFormText('Campo "Data de Início" inválido.');
        return null;
      }

      if (!dataFim.isValid()) {
        setFormText('Campo "Data Fim" inválido.');
        return null;
      }

      if (dataInicio.isAfter(dataFim)) {
        setFormText("A data inicial é superior à data final");
        return null;
      }

      if (dataFim.isAfter(moment())) {
        setFormText("A data final é superior à data atual");
        return null;
      }

      if (true) {
        var relatorio = await PlanoService.RelatorioExtratoPorPlanoEmpresaReferencia(cdPlano, dataInicio.format("DD.MM.YYYY"), dataFim.format("DD.MM.YYYY"), false);
        if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
          return navigator.msSaveBlob(new Blob([relatorio]), "Extrato.pdf");
        } else {
          const blobURL = window.URL.createObjectURL(new Blob([relatorio]));
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('download', "Extrato.pdf");

          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
          }

          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }
      }
      setRodouDownloadPDF(true);

    } catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
      setFormText("Erro ao baixar o Extrato.");
    }
  }

  return (

    <Form ref={form}>
      <Row>
        <Col className={"col-lg-6"}>
          <Input
            type={"date"}
            value={DataInicio}
            title={"Data de Início"}
            onChange={setDataInicio}
            labelPosition={"up"}
            required
          />
        </Col>

        <Col className={"col-lg-6"}>
          <Input
            type={"date"}
            value={DataFim}
            title={"Data Final"}
            onChange={setDataFim}
            labelPosition={"up"}
            required
          />
        </Col>
      </Row>

      {RodouDownloadPDF && <AvisoDownloadPDF />}

      <Alert type={"danger"}>{FormText}</Alert>

      <Button
        title={"Gerar"}
        type={"primary"}
        onClick={gerarExtrato}
        loadingText={"Gerando..."}
        usesLoading
        submit
      />

    </Form>

  );

}