import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Alert, Box, Button, Col, Row, StaticField, useModal
} from "@intech/web-ui";

import { PlanoService, SalarioBaseService, FichaFechamentoService } from "@services";

import { PlanoVinculadoEntidade, SalarioBaseEntidade, SaldoContribuicoesEntidade } from "@entidades";
import { AvisoDownloadPDF, MasterPage } from '@components';
import { ModalConteudo } from './ModalConteudo';

interface IProps {
  match?: any;
}

export const PlanoDetalhe: React.FC<IProps> = ({
  match,
  ...rest
}) => {
  const [AlertText, setAlertText] = useState<string>("");
  const [CdPlano, setCdPlano] = useState<string>("");
  const [Plano, setPlano] = useState<PlanoVinculadoEntidade>(new PlanoVinculadoEntidade());
  const [Salario, setSalario] = useState<SalarioBaseEntidade>(new SalarioBaseEntidade());
  const [Saldo, setSaldo] = useState<SaldoContribuicoesEntidade>(new SaldoContribuicoesEntidade());
  const [PossuiSeguro, setPossuiSeguro] = useState<boolean>(false);
  const [isPesquisando, setIsPesquisando] = useState<boolean>(true);
  const [RodouDownloadCertificadoSeguro, setRodouDownloadCertificadoSeguro] = useState<boolean>(false);
  const [RodouDownloadCertificadoParticipacao, setRodouDownloadCertificadoParticipacao] = useState<boolean>(false);

  const modal = useModal();

  useEffect(() => {
    (async () => {
      try {

        setCdPlano(match.params.plano);

      }
      catch (e) {
        const error = e.response ? e.response.data : e;
        setAlertText(error);
      }

    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (CdPlano !== "") {
          const plano = await PlanoService.BuscarPorCodigo(CdPlano);
          const possuiSeguro = await PlanoService.PossuiCertificadoSeguro();
          const salario = await SalarioBaseService.Buscar();
          const saldo = await FichaFechamentoService.BuscarSaldoPorPlano(CdPlano);

          setPlano(plano);
          setPossuiSeguro(possuiSeguro);
          setSalario(salario);
          setSaldo(saldo);

          setIsPesquisando(false);
        }
      } catch (e) {
        const error = e.response ? e.response.data : e;
        setAlertText(error);
        setIsPesquisando(false);
      }
    })();
  }, [CdPlano]);

  async function toggleModal() {
    modal.openModal({
      title: "Período",
      type: "body",
      content: <ModalConteudo
        cdPlano={CdPlano}
        dataInicio={moment(Plano.DT_INSC_PLANO, "DD/MM/YYYY").format("DD/MM/YYYY")}
      />,
    })
  }

  async function gerarCertificado() {
    try {
      setAlertText("");
      setRodouDownloadCertificadoParticipacao(false);
      var relatorio = await PlanoService.RelatorioCertificado(CdPlano, false);

      if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
        return navigator.msSaveBlob(new Blob([relatorio]), 'Certificado de Participação.pdf');
      } else {
        const url = window.URL.createObjectURL(new Blob([relatorio]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Certificado de Participação.pdf');
        document.body.appendChild(link);
        link.click();
      }
      setRodouDownloadCertificadoParticipacao(true);
    }
    catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
      setAlertText("Erro ao baixar o Extrato.");
    }
  }

  async function gerarCertificadoSeguro() {
    try {
      setRodouDownloadCertificadoSeguro(false);
      setAlertText("");
      var relatorio = await PlanoService.RelatorioCertificadoSeguro(false);

      if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
        return navigator.msSaveBlob(new Blob([relatorio]), 'Certificado de Seguro.zip');
      } else {
        const url = window.URL.createObjectURL(new Blob([relatorio]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Certificado de Seguro.zip');
        document.body.appendChild(link);
        link.click();
      }
      setRodouDownloadCertificadoSeguro(true);

    } catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
      setAlertText("Erro ao baixar o Extrato.");
    }
  }

  return (
    <MasterPage {...rest}>
      {!isPesquisando &&
        <Row>
          <Alert type="danger">{AlertText}</Alert>

          <Col>
            <Box>
              <Row form>
                <StaticField
                  title="Plano"
                  value={Plano.DS_PLANO}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Data de inscrição"
                  value={moment(Plano.DT_INSC_PLANO, "DD/MM/YYYY").format("DD/MM/YYYY")}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Situação no Plano"
                  value={Plano.DS_SIT_PLANO}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Categoria"
                  value={Plano.DS_CATEGORIA}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              {Plano.CD_CATEGORIA !== "2" &&
                <Row form>
                  <StaticField
                    title="Salário de Participação"
                    value={Salario.VL_SALARIO}
                    type={"money"}
                    labelPosition={"up"}
                    inline
                  />
                </Row>
              }
            </Box>

            {Plano.CD_CATEGORIA !== "2" &&
              <Box>
                <h4>Saldo</h4><br />

                {CdPlano === "0001" &&
                  <div>
                    <Row form>
                      <StaticField
                        title="Quantidade de Cotas Participante"
                        value={Saldo.QuantidadeCotasParticipante}
                        labelPosition={"up"}
                        inline
                      />

                      {Saldo.QuantidadeCotasPatrocinadora !== 0 &&
                        <StaticField
                          title="Quantidade de Cotas Patrocinadora"
                          value={Saldo.QuantidadeCotasPatrocinadora}
                          labelPosition={"up"}
                          inline
                        />
                      }
                    </Row>

                    <Row form>
                      <StaticField
                        title="Saldo Participante"
                        value={Saldo.ValorParticipante}
                        type={"money"}
                        labelPosition={"up"}
                        inline
                      />

                      {Saldo.ValorPatrocinadora !== 0 &&
                        <StaticField
                          title="Saldo Patrocinadora"
                          value={Saldo.ValorPatrocinadora}
                          type={"money"}
                          labelPosition={"up"}
                          inline
                        />
                      }
                    </Row>

                    <Row form>
                      <StaticField
                        title="Saldo Total"
                        value={Saldo.ValorTotal}
                        type={"money"}
                        labelPosition={"up"}
                        inline
                      />

                      <StaticField
                        title="Saldo Atualizado"
                        value={Saldo.ValorSaldoAtualizado}
                        type={"money"}
                        labelPosition={"up"}
                        inline
                      />
                    </Row>

                    <Row form>
                      <StaticField
                        title="Data do Indice"
                        value={moment(Saldo.DataCotaAtualizacao, "DD/MM/YYYY").format("DD/MM/YYYY")}
                        labelPosition={"up"}
                        inline
                      />

                      <StaticField
                        title="Valor do Indice"
                        value={Saldo.ValorCotaAtualizacao}
                        labelPosition={"up"}
                        inline
                      />
                    </Row>
                  </div>
                }

                {CdPlano === "0002" &&
                  <div>
                    <Row form>
                      <StaticField
                        title="Quantidade de Cotas"
                        value={Saldo.QuantidadeCotasParticipante}
                        labelPosition={"up"}
                        inline
                      />

                      <StaticField
                        title="Saldo"
                        value={Saldo.ValorSaldoAtualizado}
                        type={"money"}
                        labelPosition={"up"}
                        inline
                      />

                    </Row>

                    <Row form>
                      <StaticField
                        title="Data do Indice"
                        value={moment(Saldo.DataCotaAtualizacao, "DD/MM/YYYY").format("DD/MM/YYYY")}
                        labelPosition={"up"}
                        inline
                      />

                      <StaticField
                        title="value do Indice"
                        value={Saldo.ValorCotaAtualizacao}
                        labelPosition={"up"}
                        inline
                      />
                    </Row>
                  </div>
                }
              </Box>
            }
          </Col>

          <Col>
            <Box>
              <h4>Extrato de Contribuições</h4>

              <p>Imprima o seu extrato para visualizar suas contribuições por período, assim como seu saldo do período.</p>

              <Button
                type={"primary"}
                size={"normal"}
                onClick={toggleModal}
                title={"Gerar extrato"}
              />

            </Box>

            {Plano.CD_CATEGORIA !== "2" &&
              <Box>
                <h4>Certificado de Participação</h4>

                <p>Faça download do seu certificado de participação no plano {Plano.DS_PLANO}.</p>

                {RodouDownloadCertificadoParticipacao && <AvisoDownloadPDF />}

                <Button
                  title={"Baixar Certificado de Participação"}
                  type={"primary"}
                  onClick={gerarCertificado}
                  loadingText={"Baixando..."}
                  usesLoading
                />

              </Box>
            }

            {PossuiSeguro &&
              <Box>
                <h4>Certificado de Seguro</h4>

                <p>Faça download do seu certificado de seguro.</p>

                {RodouDownloadCertificadoSeguro && <AvisoDownloadPDF />}

                <Button
                  title={"Baixar Certificado de Seguro"}
                  type={"primary"}
                  onClick={gerarCertificadoSeguro}
                  loadingText={"Baixando..."}
                  usesLoading
                />

              </Box>
            }
          </Col>
        </Row>
      }

      {isPesquisando &&
        <Alert type="info">Aguarde processando...</Alert>
      }
    </MasterPage>
  );

}