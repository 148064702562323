export class DependenteEntidade {
	public CD_FUNDACAO: string;
	public NUM_INSCRICAO: string;
	public NUM_SEQ_DEP: number;
	public NOME_DEP: string;
	public CD_GRAU_PARENTESCO: string;
	public SEXO_DEP: string;
	public DT_NASC_DEP: Date;
	public ABATIMENTO_IRRF: string;
	public DT_VALIDADE_DEP: Date;
	public CD_MOT_PERDA_VALIDADE: string;
	public DT_INCLUSAO_DEP: Date;
	public PLANO_ASSISTENCIAL: string;
	public PLANO_PREVIDENCIAL: string;
	public DT_INIC_IRRF?: Date;
	public DT_TERM_IRRF?: Date;
	public PECULIO: string;
	public PERC_PECULIO?: number;
	public NUM_PROTOCOLO: string;
	public DT_INC_MOV?: Date;
	public DT_EXC_MOV?: Date;
	public CD_SIT_PLANO_MOV: string;
	public POLIT_EXP: string;
	public CPF: string;
	public IDENTIDADE: string;
	public ORGAO_EXP: string;
	public DT_EXPEDICAO?: Date;
	public CD_OCUPACAO?: number;
	public BENEF_IND: string;
	public CD_PLANO: string;
	public UF_IDENT_DEP: string;
	public CD_NACIONALIDADE: string;
	public CD_ESTADO_CIVIL: string;
	public NATURALIDADE: string;
	public UF_NATURALIDADE: string;
	public EMAIL_DEP: string;
	public FONE_CELULAR: string;
	public NOME_PAI: string;
	public NOME_MAE: string;
	public ISS: string;
	public NUM_BANCO: string;
	public NUM_AGENCIA: string;
	public NUM_CONTA: string;
	public END_DEP: string;
	public NR_END_DEP: string;
	public COMP_END_DEP: string;
	public BAIRRO_DEP: string;
	public CID_DEP: string;
	public UF_DEP: string;
	public CD_PAIS: string;
	public FONE_DEP: string;
	public FONE_COM_DEP: string;
	public CEP_DEP: string;
	public PLANO_SAUDE: string;
	public DT_RECONHECIMENTO?: Date;
	public CD_TIPO_CORRESP: string;
	public CX_POSTAL: string;
	public DS_GRAU_PARENTESCO: string;
	public NUM_SEQ_GR_FAMIL: number;
	public DT_TERMINO_VALIDADE: Date;
}
