import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { SexoEntidade } from "../entidades/SexoEntidade";

class Sexo extends BaseService {
	constructor() {
		super("Sexo");
	}

	BuscaTodos = () =>
		this.CreateRequest<Array<SexoEntidade>>(RequestType.GET, `BuscaTodos`);

}

export const SexoService = new Sexo();
