import React from "react";
import { InfoRendEntidade, InfoRendGrupoEntidade } from "@entidades";
import { Linha } from "../Linha";

interface GrupoProps {
  grupo: InfoRendGrupoEntidade
}

export const Grupo: React.FC<GrupoProps> = ({
  grupo
}) => {

  function RenderItens(grupo: InfoRendGrupoEntidade) {
    return grupo.Itens.map((linha: InfoRendEntidade, index: number) => {
      return (
        <Linha key={index} linha={linha} />
      );
    });
  };

  return (
    <div>
      <h5>
        <b>{grupo.DES_GRUPO}</b>
      </h5>
      <table className={"table table-striped"}>
        <tbody>{RenderItens(grupo)}</tbody>
      </table>
      <br />
    </div>
  );
}
