import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { GrauInstrucaoEntidade } from "../entidades/GrauInstrucaoEntidade";

class GrauInstrucao extends BaseService {
	constructor() {
		super("GrauInstrucao");
	}

	BuscarTodos = () =>
		this.CreateRequest<Array<GrauInstrucaoEntidade>>(RequestType.GET, `BuscarTodos`);

}

export const GrauInstrucaoService = new GrauInstrucao();
