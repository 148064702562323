import { FuncionalidadeService, PlanoService } from "./../services";

export async function buscarBloqueio(Funcionalidade: NumFuncionalidade) {
  let motivoBloqueio = "";
  if (Funcionalidade) {
    const planos = await PlanoService.Buscar();

    /* Código do plano do participante logado. */
    const cdPlano1 = planos[0].CD_PLANO;
    /* Código do segundo plano do participante logado (repetir o primeiro caso só tenha um plano). */
    const cdPlano2 = planos.length > 1 ? planos[1].CD_PLANO : cdPlano1;
    /* Código do terceiro plano do participante logado (repetir o segundo caso só tenha um ou dois planos ) */
    const cdPlano3 = planos.length > 2 ? planos[2].CD_PLANO : cdPlano2;

    // motivoBloqueio = await FuncionalidadeService.BuscarBloqueiosPorNumFuncionalidade(
    //   Funcionalidade,
    //   cdPlano1,
    //   cdPlano2,
    //   cdPlano3
    // );
  }
  return ("");
};

export enum NumFuncionalidade {
  HOME_ATIVOS_E_AUTOPATROCINADOS = 1,
  HOME_ASISSTIDOS = 2,
  SEUS_DADOS = 3,
  EXTRATO = 4,
  CONTRACHEQUE_ASISSTIDOS_PENSIONISTAS = 5,
  EMPRESTIMOS_CONSULTA = 6,
  EMPRESTIMOS_SIMULAÇÃO = 7,
  EMPRESTIMOS_CONTRATAÇÃO = 8,
  INFORME_DE_RENDIMENTOS_ASSISTIDOS_PENSIONISTAS = 9,
  INFORME_PLANO_DE_SAÚDE = 10,
  FORMULARIOS = 11,
  DOCUMENTOS = 12,
  FALE_CONOSCO = 13,
  SIMULADOR_APOSENTADORIA = 14,
  SIMULADOR_EMPRESTIMO = 15,
  RECADASTRAMENTO = 16,
  EXTRATO_DO_ASSISTIDO = 17
}