import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { ArquivoUploadEntidade } from "../entidades/ArquivoUploadEntidade";
import { DocumentoEntidade } from "../entidades/DocumentoEntidade";
import { DocumentoPastaEntidade } from "../entidades/DocumentoPastaEntidade";

class Documento extends BaseService {
	constructor() {
		super("Documento");
	}

	BuscarPorPasta = (oidPasta: number) =>
		this.CreateRequest<any>(RequestType.GET, `BuscarPorPasta/${oidPasta}`);

	Buscar = (oidPasta: number) =>
		this.CreateRequest<any>(RequestType.GET, `Buscar/${oidPasta}`);

	BuscarPorOidDocumento = (oidDocumento: number) =>
		this.CreateRequest<ArquivoUploadEntidade>(RequestType.POST, `BuscarPorOidDocumento/${oidDocumento}`);

	Criar = (documento: DocumentoEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Criar`, documento);

	Deletar = (oidDocumento: number) =>
		this.CreateRequest<any>(RequestType.POST, `Deletar/${oidDocumento}`);

	CriarPasta = (pasta: DocumentoPastaEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `CriarPasta`, pasta);

	DeletarPasta = (oidDocumentoPasta: number) =>
		this.CreateRequest<any>(RequestType.POST, `DeletarPasta/${oidDocumentoPasta}`);

	Download = (oidDocumento: number) =>
		this.CreateRequest<any>(RequestType.GET, `Download/${oidDocumento}`, null, ResponseType.Blob);

	EnviarDocumento = (oidDocumento: number) =>
		this.CreateRequest<any>(RequestType.GET, `EnviarDocumento/${oidDocumento}`);

}

export const DocumentoService = new Documento();
