import React, { useState, useEffect } from 'react';
import moment from "moment";
import { FichaFinanceiraAssistidoEntidade } from '@entidades';
import { Alert } from '@intech/web-ui';

import { LoadingComponent } from '@components';

import { Header } from './Header';
import { Item } from './Item';

interface IProps {
  lista: Array<FichaFinanceiraAssistidoEntidade>,
  CdPlano: string;
}

export const Lista: React.FC<IProps> = ({
  lista,
  CdPlano
}) => {
  const [AlertText, setAlertText] = useState<string>("");
  const [Lista, setlista] = useState<Array<FichaFinanceiraAssistidoEntidade>>(undefined);

  useEffect(() => {
    (async () => {
      try {
        setlista(lista);
      }
      catch (e) {
        const error = e.response ? e.response.data : e;
        console.log(error);
        setAlertText(error);
      }

    })();
  }, []);

  function renderLista() {
    if (Lista !== undefined) {
      if (Lista.length > 0) {
        const Itens = Lista.map((valor: FichaFinanceiraAssistidoEntidade, index: number) => {
          const Data = moment(valor.DT_REFERENCIA, "DD/MM/YYYY").format("DD.MM.YYYY");
          const textoLink = `${CdPlano}/${Data}/${valor.CD_TIPO_FOLHA}/${valor.CD_ESPECIE}`;
          return (
            <Item key={index} item={valor} textoLink={textoLink} />
          );
        });

        return (
          <div className={"table-responsive"}>
            <table className={"table table-striped table-sm"}>
              <Header />

              <tbody>
                {Itens}
              </tbody>

            </table>
          </div>
        );
      }

      if (Lista.length === 0) {
        return (
          <Alert type={"danger"}>{"Nenhum contracheque disponível para este plano."}</Alert>
        );
      }
    }

    return <LoadingComponent />
  }

  return renderLista();
}
