import React from 'react';
import { UltimaContribuicaoEntidade } from '@entidades';

import { LoadingComponent } from '@components';
import { Box } from '@intech/web-ui';

interface IProps {
  ultimaContribuicao: UltimaContribuicaoEntidade | undefined;
  referencia: string;
}

export const UltimaContribuicaoDetalhe: React.FC<IProps> = ({
  ultimaContribuicao,
  referencia
}) => {

  function ToMoney(Valor: number) {
    if (Valor === undefined || isNaN(Valor))
      return 0;
    if (Valor < 0)
      return 0;
    return (
      Valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })
    );
  }

  function renderContribuicao() {
    if (ultimaContribuicao !== undefined) {
      return (
        <Box title={"Última Contribuição"} badgeText={`Referência em ${referencia}`}>
          <div className={"table-responsive"}>
            <table className={"table table-striped table-sm"}>
              <thead>
                <tr>
                  <th><b>Contribuição</b></th>
                  <th style={{ "textAlign": "right" }}><b>Valor</b></th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style={{ "textAlign": "left" }}>
                    {"CONTRIB NORMAL"}
                  </td>
                  <td style={{ "textAlign": "right" }}>
                    R$ {ToMoney(ultimaContribuicao.ValorAposentadoriaParticipante! + ultimaContribuicao.ValorAposentadoriaEmpresa!)}
                  </td>
                </tr>
                <tr>
                  <td style={{ "textAlign": "left" }}>
                    <b>
                      {"TOTAL"}
                    </b>
                  </td>
                  <td style={{ "textAlign": "right" }}>
                    <b>
                      R$ {ToMoney(ultimaContribuicao.ValorTotalParticipante! + ultimaContribuicao.ValorTotalEmpresa!)}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Box>
      );
    }

    return null;
  }

  return renderContribuicao();
}