/*import React from 'react';
import { RouteProps } from 'react-router-dom';
import { Rota } from '@intechprev/componentes-web';

import {
  Login, EsqueciSenha,
  Home,
  DadosPessoais,
  Planos,
  PlanoDetalhe,
  Contracheque,
  InformeRendimentos,
  ContrachequeDetalhe,
  Documentos,
  TrocarSenha,
  Mensagem,
  Simulador,
  SelecionarMatricula,
  AlteracaoCadastral
} from "./pages";



const rotas: Array<Rota> = [
  {
    titulo: "Login",
    caminho: "/login",
    componente: (routeProps: RouteProps) => <Login {...routeProps} />,
    mostrarMenu: false,
    caminhoExato: false,
    id: "",
    icone: ""
  },
  {
    titulo: "Esqueci Minha Senha",
    caminho: "/esqueciSenha",
    componente: (routeProps: RouteProps) => <EsqueciSenha {...routeProps} />,
    mostrarMenu: false,
    caminhoExato: false,
    id: ""
  },
  {
    titulo: "Home",
    icone: "fas fa-home",
    caminho: "/",
    componente: (routeProps: RouteProps) => <Home {...routeProps} />,
    mostrarMenu: true,
    caminhoExato: true,
    id: "home"
  },
  {
    titulo: "Seus Dados",
    icone: "fas fa-user",
    caminho: "/dados",
    componente: (routeProps: RouteProps) => <DadosPessoais {...routeProps} />,
    mostrarMenu: true,
    id: "dadosPessoais"
  },
  {
    titulo: "Planos",
    icone: "fas fa-list",
    caminho: "/planos",
    componente: (routeProps: RouteProps) => <Planos {...routeProps} />,
    mostrarMenu: true,
    caminhoExato: true,
    id: "planos"
  },
  {
    titulo: "Simulador Benefício",
    // icone: "fas fa-donate",
    icone: "fas fa-money-check-alt",
    caminho: "/simulador",
    componente: (routeProps: RouteProps) => <Simulador {...routeProps} />,
    mostrarMenu: true,
    caminhoExato: true,
    id: "simulador"
  },
  {
    titulo: "Detalhes do Plano",
    icone: "fas fa-list",
    caminho: "/planos/:plano",
    caminhoLink: "/planos/",
    componente: (routeProps: RouteProps) => <PlanoDetalhe {...routeProps} />,
    mostrarMenu: false,
    caminhoExato: true,
    id: "planoDetalhe"
  },
  {
    titulo: "Contracheque",
    icone: "fas fa-closed-captioning",
    caminho: "/contracheque",
    componente: (routeProps: RouteProps) => <Contracheque {...routeProps} />,
    mostrarMenu: true,
    caminhoExato: true,
    id: "contracheque"
  },
  {
    titulo: "Inf. Rendimentos",
    icone: "fas fa-chart-pie",
    caminho: "/infoRend",
    componente: (routeProps: RouteProps) => <InformeRendimentos {...routeProps} />,
    mostrarMenu: true,
    caminhoExato: true,
    id: "informeRendimentos"
  },
  {
    titulo: "Contracheque Detalhe",
    icone: "fas fa-closed-captioning",
    caminho: "/contracheque/:plano/:data/:cdTipoFolha/:cdEspecie",
    caminhoLink: "/contracheque/",
    componente: (routeProps: RouteProps) => <ContrachequeDetalhe {...routeProps} />,
    mostrarMenu: false,
    caminhoExato: true,
    id: "contrachequeDetalhe"
  },
  {
    titulo: "Documentos",
    icone: "fas fa-file",
    caminho: "/documentos/:pasta?",
    caminhoLink: "/documentos/",
    componente: (routeProps: RouteProps) => <Documentos {...routeProps} />,
    mostrarMenu: true,
    id: "documentos"
  },
  {
    titulo: "Mensagens",
    icone: "fas fa-envelope",
    caminho: "/mensagens",
    componente: (routeProps: RouteProps) => <Mensagem {...routeProps} />,
    mostrarMenu: true,
    id: "mensagens"
  },
  {
    titulo: "Trocar senha",
    icone: "fas fa-lock",
    caminho: "/trocarSenha",
    componente: (routeProps: RouteProps) => <TrocarSenha {...routeProps} />,
    mostrarMenu: true,
    id: "trocarSenha"
  },
  {
    titulo: "Selecionar Matrícula",
    caminho: "/selecionarMatricula",
    icone: "fas fa-exchange-alt",
    componente: (routeProps: RouteProps) => <SelecionarMatricula {...routeProps} />,
    mostrarMenu: true,
    caminhoExato: false,
    id: "selecionarMatricula"
  },
  {
    titulo: "Alteração Cadastral",
    caminho: "/alteracaocadastral",
    icone: "fas fa-exchange-alt",
    componente: (routeProps: RouteProps) => <AlteracaoCadastral {...routeProps} />,
    mostrarMenu: true,
    caminhoExato: false,
    id: "alteracaocadastral"
  }
];

export default rotas;*/


import React from "react";
import { RouteProps } from "react-router-dom";
import { WebRoute, WebArea } from "@intech/web-ui";

import {
  Login, EsqueciSenha,
  Home,
  DadosPessoais,
  Planos,
  PlanoDetalhe,
  Contracheque,
  InformeRendimentos,
  ContrachequeDetalhe,
  Documentos,
  TrocarSenha,
  Mensagem,
  Simulador,
  SelecionarMatricula,
  AlteracaoCadastral,
  ListarParticipantes
} from "./pages";

import {
  FaChartPie,
  FaClosedCaptioning,
  FaEnvelope,
  FaExchangeAlt,
  FaFile,
  FaHome,
  FaList,
  FaLock,
  FaMoneyCheckAlt,
  FaSignOutAlt,
  FaUser
} from "react-icons/fa";



const rotas: Array<WebRoute | WebArea> = [
  new WebRoute({
    title: "Esqueci Minha Senha",
    path: "/esqueciSenha",
    component: (routeProps: RouteProps) => <EsqueciSenha {...routeProps} />,
    showInMenu: false,
    exactPath: false,
    id: ""
  }),
  new WebRoute({
    title: "Home",
    icon: <FaHome />,
    path: "/",
    component: (routeProps: RouteProps) => <Home {...routeProps} />,
    showInMenu: true,
    exactPath: true,
    id: "home"
  }),
  new WebRoute({
    title: "Seus Dados",
    icon: <FaUser />,
    path: "/dados",
    component: (routeProps: RouteProps) => <DadosPessoais {...routeProps} />,
    showInMenu: true,
    id: "dadosPessoais"
  }),
  new WebRoute({
    title: "Planos",
    icon: <FaList />,
    path: "/planos",
    component: (routeProps: RouteProps) => <Planos {...routeProps} />,
    showInMenu: true,
    exactPath: true,
    id: "planos"
  }),
  new WebRoute({
    title: "Simulador Benefício",
    icon: <FaMoneyCheckAlt />,
    path: "/simulador",
    component: (routeProps: RouteProps) => <Simulador {...routeProps} />,
    showInMenu: true,
    exactPath: true,
    id: "simulador"
  }),
  new WebRoute({
    title: "Detalhes do Plano",
    icon: <FaList />,
    path: "/planos/:plano",
    linkPath: "/planos/",
    component: (routeProps: RouteProps) => <PlanoDetalhe {...routeProps} />,
    showInMenu: false,
    exactPath: true,
    id: "planoDetalhe"
  }),
  new WebRoute({
    title: "Contracheque",
    icon: <FaClosedCaptioning />,
    path: "/contracheque",
    component: (routeProps: RouteProps) => <Contracheque {...routeProps} />,
    showInMenu: true,
    exactPath: true,
    id: "contracheque"
  }),
  new WebRoute({
    title: "Inf. Rendimentos",
    icon: <FaChartPie />,
    path: "/infoRend",
    component: (routeProps: RouteProps) => <InformeRendimentos {...routeProps} />,
    showInMenu: true,
    exactPath: true,
    id: "informeRendimentos"
  }),
  new WebRoute({
    title: "Contracheque Detalhe",
    icon: <FaClosedCaptioning />,
    path: "/contrachequeDetalhe/:plano/:data/:cdTipoFolha/:cdEspecie",
    linkPath: "/contrachequeDetalhe/",
    component: (routeProps: RouteProps) => <ContrachequeDetalhe {...routeProps} />,
    showInMenu: false,
    exactPath: true,
    id: "contrachequeDetalhe"
  }),
  new WebRoute({
    title: "Documentos",
    icon: <FaFile />,
    path: "/documentos/:pasta?",
    linkPath: "/documentos/",
    component: (routeProps: RouteProps) => <Documentos {...routeProps} />,
    showInMenu: true,
    id: "documentos"
  }),
  new WebRoute({
    title: "Mensagens",
    icon: <FaEnvelope />,
    path: "/mensagens",
    component: (routeProps: RouteProps) => <Mensagem {...routeProps} />,
    showInMenu: true,
    id: "mensagens"
  }),
  new WebRoute({
    title: "Trocar senha",
    icon: <FaLock />,
    path: "/trocarSenha",
    component: (routeProps: RouteProps) => <TrocarSenha {...routeProps} />,
    showInMenu: true,
    id: "trocarSenha"
  }),
  new WebRoute({
    title: "Selecionar Matrícula",
    path: "/selecionarMatricula",
    icon: <FaExchangeAlt />,
    component: (routeProps: RouteProps) => <SelecionarMatricula {...routeProps} />,
    showInMenu: true,
    exactPath: false,
    id: "selecionarMatricula",
    tooltip: "Se você possui vinculo em mais de uma patrocinadora e/ou em mais de uma matricula. clique aqui para selecionar"
  }),
  new WebRoute({
    title: "Alteração Cadastral",
    path: "/alteracaocadastral",
    icon: <FaExchangeAlt />,
    component: (routeProps: RouteProps) => <AlteracaoCadastral {...routeProps} />,
    showInMenu: true,
    exactPath: false,
    id: "alteracaocadastral"
  }),
  new WebRoute({
    id: "login",
    path: "/login",
    title: "Sair",
    component: (routeProps: RouteProps) => <Login {...routeProps} />,
    icon: <FaSignOutAlt />,
    exactPath: true,
    showInMenu: true,
  }),
  new WebRoute({
    id: "listarParticipantes",
    path: "/listarParticipantes",
    title: "Listar Participante",
    component: (routeProps: RouteProps) => (
      <ListarParticipantes {...routeProps} />
    ),
    exactPath: true,
    showInMenu: false,
  }),
];

export default rotas;
