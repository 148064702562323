import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { EntidadeEntidade } from "../entidades/EntidadeEntidade";
import { EmpresaEntidade } from "../entidades/EmpresaEntidade";

class Empresa extends BaseService {
  constructor() {
    super("Empresa");
  }

  Atualizar = (entidade: EntidadeEntidade) =>
    this.CreateRequest<EntidadeEntidade>(RequestType.GET, ``, entidade);

  Buscar = () =>
    this.CreateRequest<Array<EmpresaEntidade>>(RequestType.GET, `Buscar`);

}

export const EmpresaService = new Empresa();
