import React from "react";
import { Box, StaticField } from "@intech/web-ui";
import { Cols, Title, Icon } from "./styles";

interface Props {
  Rendimentos: any;
  icon: any;
  titulo: string;
  type: 'danger' | 'info' | 'warning' | 'success';
}

export interface IconProps {
  type: 'danger' | 'info' | 'warning' | 'success';
}

export interface TableItemProps {
  align: 'left' | 'right';
}

export const Resumo: React.FC<Props> = ({
  Rendimentos,
  icon,
  titulo,
  type
}) => {

  function ToMoney(Valor: number) {
    return (
      Valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })
    );
  }

  return (
    <Cols>
      <Title>
        <Icon type={type}>
          {icon}
        </Icon>
        {titulo}
      </Title>
      <div className={"table-responsive"}>
        <table className={"table table-striped table-sm"}>
          <thead>
            <tr>
              <th align={"left"}>Descrição</th>
              <th align={"right"}>Valor</th>
            </tr>
          </thead>
          <tbody>
            {
              Rendimentos.map((rendimento: any, index: number) => {
                return (
                  <tr key={index}>
                    <td align={"left"}>{rendimento.DS_RUBRICA}</td>
                    <td align={"right"}>R$ {ToMoney(rendimento.VALOR_MC)}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </Cols>
  );
}
