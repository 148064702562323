import React from "react";
import { Desconto, Provento, Rubrica, Titulo, Valor } from "./styles";

export const UltimaFolhaItem: React.FC<any> = ({ provento, rubrica }) => {
  if (rubrica) {
    return (
      <Rubrica>

        {provento
          ? <Provento>Provento</Provento>
          : <Desconto>Desconto</Desconto>}

        <Titulo>
          {rubrica.DS_RUBRICA}
          {/* <Data>({rubrica.DT_COMPETENCIA})</Data> */}
        </Titulo>

        <Valor>
          R$ {rubrica.VALOR_MC ? rubrica.VALOR_MC.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}
        </Valor>

      </Rubrica>
    );
  }

  return null;
}
