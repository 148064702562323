import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Botao, ModalConfirma } from "@intechprev/componentes-web";

import { toast, ToastContainer } from 'react-toastify';
import { DocumentoService, UsuarioService } from "../../services";

interface Props {
    itens: Array<any>;
    tipo: string;
    icone: string;
    campoTexto: string;
}

interface State {
    admin: boolean;
    modalExclusaoStatus: boolean;
    oidDocExclusao: number;
    oidPastaExclusao: number;
    textoExclusao: string;
}

export class Tabelas extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            admin: false,
            modalExclusaoStatus: false,
            oidDocExclusao: 0,
            oidPastaExclusao: 0,
            textoExclusao: "",
        }
    }

    componentDidMount = async () => {
        var admin = await UsuarioService.VerificarAdmin();
        await this.setState({
            admin
        });
    }

    toggleModalExcluir = async (oidDocumento: number) => {

        var texto;
        if (this.props.tipo === "pasta")
           texto = "Confirma exclusão da Pasta?";
        else
           texto = "Confirma exclusão do Documento?";

        await this.setState({
            modalExclusaoStatus: !this.state.modalExclusaoStatus,
            oidDocExclusao: oidDocumento,
            textoExclusao: texto,
        });
    }

    cancelaExclusaoDocumento = async () => {
        this.setState({ modalExclusaoStatus: !this.state.modalExclusaoStatus, })
    }

    confirmaExclusaoDocumento = async () => {
        this.setState({ modalExclusaoStatus: !this.state.modalExclusaoStatus })
        try {
            if (this.props.tipo === "pasta")
            {
               await DocumentoService.DeletarPasta(this.state.oidDocExclusao);
               toast.success("Pasta Excluida com sucesso!");
            }
            else
            {
               await DocumentoService.Deletar(this.state.oidDocExclusao);
               toast.success("Documento Excluido com sucesso!");
            }
            document.location.reload();
        }
        catch {
            toast.warning("Erro ao Excluir o Registro!");
        }
    }

    deletarDocumento = async (oidDocumento: number) => {
        if (window.confirm('Deseja realmente excluir o documento?')) {
            await DocumentoService.Deletar(oidDocumento);
            document.location.reload();
        }
    }

    deletarPasta = async (oidDocumentPasta: number) => {
        if (window.confirm('Deseja realmente excluir a pasta e todo seu conteúdo?')) {
            await DocumentoService.DeletarPasta(oidDocumentPasta);
            document.location.reload();
        }
    }

    downloadDocumento = async (oidDocumento: number) => {
        try {
            var documento = await DocumentoService.BuscarPorOidDocumento(oidDocumento);

            var documentoBlob = await DocumentoService.Download(oidDocumento);

            const blobURL = window.URL.createObjectURL(new Blob([documentoBlob]));
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', documento.NOM_ARQUIVO_LOCAL);

            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);

        } catch (err) {
            if (err.response)
                console.error(err.response.data);
            else
                console.error(err);
        }
    }

    render() {
        return (
            <div>
                {this.props.itens.map((item, index) => {
                    return (
                        <Row key={index} className={"m-3"}>
                            <Col tamanho={"1"}>
                                <i className={"fa fa-2x " + this.props.icone}></i>
                            </Col>

                            <Col className={"mt-1"}>
                                {this.props.tipo === "pasta" &&
                                    <Link className={"btn btn-link"} to={`/documentos/${item.OID_DOCUMENTO_PASTA}`}>{item[this.props.campoTexto]}</Link>
                                }

                                {this.props.tipo !== "pasta" &&
                                    <Botao className={"btn btn-link"} onClick={() => this.downloadDocumento(item.OID_DOCUMENTO)} titulo={item[this.props.campoTexto]} />
                                }
                            </Col>

                            {this.state.admin &&
                                <Col tamanho={"1"}>
                                    <Botao className={"btn btn-sm btn-danger"} icone={"fa-trash"}
                                        onClick={async () => {
                                            // if (this.props.tipo === "pasta")
                                            //     await this.deletarPasta(item.OID_DOCUMENTO_PASTA);
                                            // else
                                            //     await this.deletarDocumento(item.OID_DOCUMENTO);

                                            if (this.props.tipo === "pasta")
                                                await this.toggleModalExcluir(item.OID_DOCUMENTO_PASTA);
                                            else
                                                await this.toggleModalExcluir(item.OID_DOCUMENTO);
                                        }}>
                                    </Botao>
                                </Col>
                            }
                        </Row>
                    );
                })}

                <ModalConfirma
                    onClose={this.cancelaExclusaoDocumento}
                    onConfirm={this.confirmaExclusaoDocumento}
                    visivel={this.state.modalExclusaoStatus}
                    titulo={"Confirma?"}
                >
                    {this.state.textoExclusao}
                </ModalConfirma>
                <ToastContainer/>
            </div>
        );
    }
}