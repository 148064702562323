import styled from "styled-components";

export const Green = styled.span`
  color: #28a745 !important;
`;

export const Red = styled.span`
  color: #dc3545 !important;
`;

export const Blue = styled.span`
  color: #17a2b8  !important;
`;
