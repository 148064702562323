import React from 'react';
import moment from "moment";
import { FichaFinanceiraAssistidoEntidade } from '@entidades';
import { useHistory } from 'react-router-dom';
import { Button, StaticField } from '@intech/web-ui';

export interface ItdProps {
  align?: "left" | "right";
  color?: "green" | "blue" | "red";
}

interface IProps {
  item: FichaFinanceiraAssistidoEntidade;
  textoLink: string;
}

export const Item: React.FC<IProps> = ({
  item,
  textoLink
}) => {

  const history = useHistory();

  function AbrirDetalhe() {
    history.push(`contrachequeDetalhe/${textoLink}`);
  }

  return (
    <tr>
      <td>
        <StaticField
          value={moment(item.DT_REFERENCIA, "DD/MM/YYYY").format("MM/YYYY")}
        />
      </td>

      <td>
        {item.DS_TIPO_FOLHA}
      </td>

      <td className={"text-right text-info"}>
        <StaticField
          value={item.VAL_BRUTO!}
          type={"money"}
        />
      </td>

      <td className={"text-right text-danger"}>
        <StaticField
          value={item.VAL_DESCONTOS!}
          type={"money"}
        />
      </td>

      <td className={"text-right text-success"}>
        <StaticField
          value={item.VAL_LIQUIDO!}
          type={"money"}
        />
      </td>

      <td className={"text-right"}>
        <Button
          onClick={AbrirDetalhe}
          title={"Detalhar"}
          type={"primary"}
        />
      </td>
    </tr>
  );
}