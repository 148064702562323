import { lighten, readableColor } from "polished";
import styled from "styled-components";

export const Card = styled.div`
`;

export const CardSubTitle = styled.div`
  font-size: 9pt;
`;

export const Container = styled.div`

`;

export const Title = styled.div`
  padding: 10px 15px 0 15px;
  font-size: 11pt;
  text-align: center;
`;

export const Badge = styled.div`
  margin: 0 10px 10px;
  padding: 5px;
  color: #FFF;
`;

export const Content = styled.div`
  padding: 0px 15px 10px 15px;
  font-weight: bold;
  font-size: 14pt;
  text-align: center;
`;