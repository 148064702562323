import React, { Component } from "react";
import { DocumentoAtualizacaoCadastralEntidade } from "../../entidades";

import {
    Row, Col, Box, Form, Botao, TipoBotao, Alerta, CampoTexto, TipoAlerta, PosicaoTituloCampoTexto, Tabela,
    ColunaTabela
} from '@intechprev/componentes-web';

import { DocAtualizacaoCadastralService } from "../../services";

interface Props {
    history?: any;
    match?: any;
}

interface State {
    admin: boolean;
    planos: Array<any>,
    arquivoUpload: string,
    oidArquivoUpload: number,
    visibilidadeFileInput: boolean,
    uploadPercentage: number,
    uploading: boolean,
    listaDocumentos: Array<DocumentoAtualizacaoCadastralEntidade>,
    numInscricao: string,
    isPesquisa: boolean,
}

export class AbaDownload extends Component<Props, State> {
    public formDownload = React.createRef<Form>();
    public alert = React.createRef<Alerta>();

    constructor(props: Props) {
        super(props);

        this.state = {
            admin: false,
            planos: [],
            arquivoUpload: "",
            oidArquivoUpload: 0,
            visibilidadeFileInput: true,
            uploadPercentage: 0,
            uploading: false,
            listaDocumentos: new Array<DocumentoAtualizacaoCadastralEntidade>(),
            numInscricao: "",
            isPesquisa: false
        }
    }

    validaForm = async () => {
        await this.alert.current.limparErros();
        await this.formDownload.current.validar();

        if (this.formDownload.current.state.valido) {
            this.pesquisaDocumentos();
        }
    }

    pesquisaDocumentos = async () => {
        var docs = await DocAtualizacaoCadastralService.ObterPorFundacaoInscricao(this.state.numInscricao)

        await this.setState({ listaDocumentos: docs, isPesquisa : true });
    }

    fazerUpload = async (documento : DocumentoAtualizacaoCadastralEntidade ) => {

        var arquivo = documento.NOM_ARQUIVO_ORIGINAL;

        var blob = await DocAtualizacaoCadastralService.Download(documento.OID_ARQUIVO_UPLOAD)

        if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
            return navigator.msSaveBlob(new Blob([blob]), arquivo);
        } else {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', arquivo);
            document.body.appendChild(link);
            link.click();
        }

    }

    render() {
        return (
            <div>
                <Box titulo={"Pesquisar Documentos"}>
                    <Form ref={this.formDownload}>
                        <Row>
                            <Col col-lg="8">
                                <CampoTexto
                                    contexto={this}
                                    nome={"numInscricao"}
                                    max={50}
                                    valor={this.state.numInscricao}
                                    titulo={"Inscrição"}
                                    obrigatorio
                                    posicaoTitulo={PosicaoTituloCampoTexto.esquerda}
                                    tituloClassName={"text-primary"}
                                />
                            </Col>
                            <Col col-lg="4">
                                <Botao
                                    titulo={"Pesquisar"}
                                    onClick={this.validaForm}
                                    className={"mb-3"}
                                    submit
                                    usaLoading
                                    tipo={TipoBotao.info}
                                    icone={"fa fa-search"}
                                />
                            </Col>
                        </Row>
                        <Alerta ref={this.alert} tipo={TipoAlerta.danger} padraoFormulario />
                    </Form>
                </Box>

                {(this.state.listaDocumentos.length > 0) &&
                    <Box>

                        <Tabela dados={this.state.listaDocumentos}
                            paginacaoHabilitada={true}
                            onSelecionar={this.fazerUpload}
                            exclusaoHabilitada={false}
                        >
                            <ColunaTabela titulo={"Participante"} propriedadeValor={"NOME_ENTID"} />
                            <ColunaTabela titulo={"Arquivo"} propriedadeValor={"NOM_ARQUIVO_ORIGINAL"} />
                            <ColunaTabela titulo={"Data Upload"} propriedadeValor={"DTA_UPLOAD"} />
                        </Tabela>
                    </Box>
                }

                {this.state.listaDocumentos.length === 0 && this.state.isPesquisa &&
                    <div className="alert alert-danger">Nenhum arquivo disponível para esta Inscrição.</div>
                }
            </div>
        );
    }
}