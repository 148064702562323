import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { EstadoCivilEntidade } from "../entidades/EstadoCivilEntidade";

class EstadoCivil extends BaseService {
	constructor() {
		super("EstadoCivil");
	}

	BuscarTodos = () =>
		this.CreateRequest<Array<EstadoCivilEntidade>>(RequestType.GET, `BuscarTodos`);

}

export const EstadoCivilService = new EstadoCivil();
