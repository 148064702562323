import React, { ReactNode, useRef, useState } from 'react';

import { UsuarioService } from "@services";
import { LoginEntidade } from "@entidades";
import { MasterPage } from '@components';
import { Alert, Box, Button, Form, Input } from '@intech/web-ui';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const TrocarSenha: React.FC = (props) => {
  const form = useRef<Form>(null);

  const [AlertType, setAlertType] = useState<"success" | "danger" | "warning" | "info">("danger");
  const [AlertText, setAlertText] = useState<ReactNode>("");

  const [SenhaAtual, setSenhaAtual] = useState<string>('');
  const [SenhaAtualVisivel, setSenhaAtualVisivel] = useState(false);

  const [SenhaNova, setSenhaNova] = useState<string>('');
  const [SenhaNovaVisivel, setSenhaNovaVisivel] = useState(false);

  const [ConfirmarSenha, setConfirmarSenha] = useState<string>('');
  const [ConfirmarSenhaVisivel, setConfirmarSenhaVisivel] = useState(false);

  async function TrocarSenha() {

    try {
      // Define os estados iniciais de alertas.
      setAlertText("");

      const erros = await form.current.validate();
      if (erros) {
        setAlertText(erros);
      }

      // Valida se a nova senha tem 6 ou mais caracteres.
      if (SenhaNova.length < 6) {
        setAlertType("danger");
        setAlertText("A nova senha deve possuir no mínimo 6 caracteres.");
        return null;
      }

      // Valida se os campos de nova senha e confirmação são iguais.
      if (SenhaNova !== ConfirmarSenha) {
        setAlertType("danger");
        setAlertText("As senhas não coincidem.");
        return null;
      }

      let le = new LoginEntidade();
      le.SenhaAntiga = SenhaAtual;
      le.SenhaNova = SenhaNova;

      const res = await UsuarioService.AlterarSenha(le);
      setAlertText(res);
      setAlertType("info");

      setSenhaAtual("");
      setSenhaNova("");
      setConfirmarSenha("");

      setSenhaAtualVisivel(false);
      setSenhaNovaVisivel(false);
      setConfirmarSenhaVisivel(false);

    } catch (e) {
      setAlertType("danger");
      const error = e.response ? e.response.data : e;
      setAlertText(error);
    }

  }

  return (
    <MasterPage {...props}>

      <Box>
        <Form ref={form}>

          <Input
            value={SenhaAtual}
            onChange={setSenhaAtual}
            title={"Senha atual"}
            type={SenhaAtualVisivel ? 'text' : 'password'}
            icon={SenhaAtualVisivel ? <FaEyeSlash /> : <FaEye />}
            buttonLeft={false}
            group
            onButtonClick={() => setSenhaAtualVisivel(old => !old)}
            required
          />

          <Input

            value={SenhaNova}
            onChange={setSenhaNova}
            title={"Nova senha"}
            type={SenhaNovaVisivel ? 'text' : 'password'}
            icon={SenhaNovaVisivel ? <FaEyeSlash /> : <FaEye />}
            buttonLeft={false}
            group
            onButtonClick={() => setSenhaNovaVisivel(old => !old)}
            required
          />


          <Input

            value={ConfirmarSenha}
            onChange={setConfirmarSenha}
            title={"Confirme nova senha"}
            type={ConfirmarSenhaVisivel ? 'text' : 'password'}
            icon={ConfirmarSenhaVisivel ? <FaEyeSlash /> : <FaEye />}
            buttonLeft={false}
            group
            onButtonClick={() => setConfirmarSenhaVisivel(old => !old)}
            required
          />

          {AlertText !== "" && <Alert type={AlertType}>
            {AlertText}
          </Alert>}
          <hr />

          <Button
            onClick={TrocarSenha}
            title={"Trocar Senha"}
            type={"primary"}
            usesLoading
            loadingText={"Enviando..."}
            submit
          />

        </Form>
      </Box>
    </MasterPage>
  );
}