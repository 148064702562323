import { Button } from "@intech/web-ui";
import { darken, lighten } from "polished";
import styled from "styled-components";

export const ProcessoButtonContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin: 10px;

  @media (min-width: 768px) {
    width: 350px;
  }
`;

export const ProcessoButton = styled.button <any>`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  background: ${(props) =>
    props.ativo ? props.theme.colors.primary : "#FFFFFF"};
  color: ${(props) => (props.ativo ? "#FFFFFF" : props.theme.colors.primary)};
  padding: 5px;
  text-align: center;
  transition: background 0.3s;

  &:hover {
    background: ${(props) =>
    props.ativo
      ? darken(0.1, props.theme.colors.primary)
      : lighten(0.4, props.theme.colors.gray)};
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.65;
  }

  @media (min-width: 768px) {
    width: 350px;
  }
`;
