import React, { useState, useEffect } from 'react';
import { PlanoVinculadoEntidade } from '@entidades';
import { PlanoService } from '@services';

import { Header } from './Header';
import { useHistory } from 'react-router-dom';
import { buscarBloqueio, NumFuncionalidade } from "./../../../helpers/BloqueioFuncionalidade";
import { Beneficiarios } from '@components';
import { Alert, Button, Col, Row } from '@intech/web-ui';

interface IProps {
  plano: PlanoVinculadoEntidade;
}

export const Desligado: React.FC<IProps> = ({
  plano
}) => {
  const history = useHistory();

  const [AlertText, setAlertText] = useState<string>("");
  const [TempoPlano, setTempoPlano] = useState<string>("");
  const [MotivoBloqueio, setMotivoBloqueio] = useState<string>("");

  useEffect(() => {
    (async () => {
      setMotivoBloqueio(await buscarBloqueio(NumFuncionalidade.HOME_ATIVOS_E_AUTOPATROCINADOS));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const tempoPlano = await PlanoService.BuscarTempoPlano(plano.CD_PLANO);
        setTempoPlano(tempoPlano);
      }
      catch (e) {
        const error = e.response ? e.response.data : e;
        setAlertText(error);
      }

    })();
  }, [plano]);

  if (MotivoBloqueio !== "") {
    return (
      <Alert type={"danger"}>
        {MotivoBloqueio}
      </Alert>
    );
  }

  return (
    <>
      {AlertText !== "" && <Alert type={"danger"}>
        {AlertText}
      </Alert>}

      <Header
        plano={plano}
        tempoPlano={TempoPlano}
      />

      <Row>
        <Col>
          <Beneficiarios />
        </Col>
      </Row>

      <Button
        className={"w-100"}
        onClick={() => history.push(`/planos/${plano.CD_PLANO}`)}
        title={"Extrato de Contribuições"}
        type={"primary"}
        block
      />
    </>
  );
}