import React from "react";
import { Alert } from "@intech/web-ui";

const AvisoDownloadPDF: React.FC = () => {
  return (
    <Alert type={"info"}>{"Foi realizado o Download, caso não apareça nenhuma notificação, verifique na pasta de downloads de seu dispositivo."}</Alert>
  );
};

export default AvisoDownloadPDF;
