import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { HeaderInfoRendEntidade } from "../entidades/HeaderInfoRendEntidade";

class InfoRend extends BaseService {
  constructor() {
    super("InfoRend");
  }

  RelatorioCertificado = (referencia: number) =>
    this.CreateRequest<any>(RequestType.GET, `RelatorioCertificado/${referencia}`, null, ResponseType.Blob);

  BuscarReferencias = () =>
    this.CreateRequest<Array<HeaderInfoRendEntidade>>(RequestType.GET, `BuscarReferencias`);

  BuscarPorReferencia = (referencia: number) =>
    this.CreateRequest<HeaderInfoRendEntidade>(RequestType.GET, `BuscarPorReferencia/${referencia}`);

}

export const InfoRendService = new InfoRend();
