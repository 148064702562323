import { BaseService, RequestType, ResponseType } from "@intech/react-service";

class Recadastramento extends BaseService {
	constructor() {
		super("Recadastramento");
	}

	Get = () =>
		this.CreateRequest<any>(RequestType.GET, ``);

}

export const RecadastramentoService = new Recadastramento();
