import React from "react";
import ReactDOM from "react-dom";
import 'rc-slider/assets/index.css';
// import './styles/custom.scss';

import Rotas from "./Rotas";
import { Roteador } from "@intechprev/componentes-web";
import { WebRouter, WebUiProvider } from "@intech/web-ui";

import './styles/variables.scss'
import theme from "@styles/theme";
import { ThemeProvider } from "styled-components";

ReactDOM.render(
  <WebUiProvider theme={theme}>
    <ThemeProvider theme={theme}>
      {/* <Roteador rotas={Rotas} /> */}
      <WebRouter hashRouter rotas={Rotas} />
    </ThemeProvider>
  </WebUiProvider>, document.getElementById('root'));