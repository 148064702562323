import React, { useEffect, useState } from "react";
import { Alert, SideMenuPage, WebRoute } from "@intech/web-ui";
import { Session } from "@intech/react-service";

import Rotas from "./../../Rotas";
import packageJson from "../../../package.json";
import config from "../../config.json";

import "./index.scss";
import { useHistory } from "react-router-dom";
import {
  UsuarioService,
  // LGPDService,
  FuncionarioService,
} from "../../services";
import { buscarBloqueio, NumFuncionalidade } from "./../../helpers/BloqueioFuncionalidade";

interface IProps {
  title?: string;
  Funcionalidade?: NumFuncionalidade;
}

const MasterPage: React.FC<IProps> = ({
  title,
  Funcionalidade,
  ...rest
}) => {
  const history = useHistory();

  const [nome, setNome] = useState("");
  const [admin, setAdmin] = useState(false);
  const [Routes, setRoutes] = useState<WebRoute[]>([]);
  const [MotivoBloqueio, setMotivoBloqueio] = useState<string>("");

  useEffect(() => {
    (async () => {
      if (Funcionalidade !== undefined)
        setMotivoBloqueio(await buscarBloqueio(Funcionalidade));
    })();
  }, []);

  /*
  codigo a ser liberado quando tiver menu dinamico
  useEffect(() => {
    (async () => {
      const menu = await UsuarioService.Menu();
      const filteredRoutes = routes.filter(r => !menu.includes(r.id));
      setRoutes(filteredRoutes);
    })();
  }, []);
  */

  useEffect(() => {
    (async () => {
      try {
        /*
        codigo a ser liberado quando tiver rotina de primeiro acesso
        var token = await localStorage.getItem(`@${config.appName}:token`);

        if (token) {
          var admin = await UsuarioService.VerificarAdmin();
          var termo = await LGPDService.Buscar();

          if (!termo && !admin) {
            history.push("/termos");
          } else {
            var dados = await FuncionarioService.Buscar();
            var nomeUsuario = dados.DadosPessoais.NOME_ENTID;

            if (
              !admin &&
              dados.Usuario &&
              dados.Usuario.IND_PRIMEIRO_ACESSO === "S"
            ) {
              setTimeout(() => history.push("/primeiroAcesso"), 500);
            } else {
            }

            setNome(nomeUsuario);
            setAdmin(admin);
          }
        } else {
          await Session.clear();
          history.push("/login");
        }
        */
        setRoutes(Rotas);

        var token = await Session.getToken();

        if (token) {
          var admin = await UsuarioService.VerificarAdmin();

          var dados = await FuncionarioService.Buscar();
          var nomeUsuario = dados.DadosPessoais.NOME_ENTID;


          setNome(nomeUsuario);
          setAdmin(admin);

        } else {
          await Logout();
        }
      } catch (err) {
        if (err.message.indexOf("401") > -1) {
          await Logout();
        } else {
          alert("Ops! Ocorreu um erro ao processar sua requisição.");
          console.error(err);
        }
      }
    })();
  }, []);

  async function Logout() {
    await Session.clear();
    history.push("/login");
  }

  if (MotivoBloqueio !== "") {
    return (
      <SideMenuPage
        admin={false}
        loading={false}
        logo={"./imagens/logo.jpg"}
        routes={Routes}
        userComponent={null}
        username={nome}
        version={packageJson.version}
        title={title}
        {...rest}
      >
        <Alert type={"danger"}>
          {MotivoBloqueio}
        </Alert>
      </SideMenuPage>
    );
  }

  return (
    <SideMenuPage
      admin={admin}
      loading={false}
      logo={"./imagens/logo.jpg"}
      routes={Routes}
      userComponent={null}
      username={nome}
      version={packageJson.version}
      title={title}
      {...rest}
    />
  );
};

export default MasterPage;