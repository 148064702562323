export class SalarioBaseEntidade {
	public CD_FUNDACAO: string;
	public CD_EMPRESA: string;
	public NUM_MATRICULA: string;
	public DT_BASE: Date;
	public VL_SALARIO?: number;
	public VL_SALARIO_13?: number;
	public VL_SALARIO_NAOINCORP?: number;
	public VL_SALARIO_NAOINCORP_13?: number;
	public VL_SALARIO_PROPORCIONAL?: number;
	public IND_NAOINCORPORAVEL: string;
}
