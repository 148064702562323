import React from 'react';
import { ContrachequeEntidade } from '@entidades';
import { Box, Col, Row } from '@intech/web-ui';

import { Lista } from '../Lista';

interface IProps {
  especie: ContrachequeEntidade
}

export const Especie: React.FC<IProps> = ({
  especie
}) => {

  return (
    <Row>
      <Col>
        <Box title={especie.Especie.DS_PLANO}>

          <div className={"mb-4"}>
            <b>ESPÉCIE:</b> {especie.Especie.DS_ESPECIE}<br />
            <b>PROCESSO:</b> {especie.Especie.NUM_PROCESSO}/{especie.Especie.ANO_PROCESSO}
          </div>

          <br />

          <Lista lista={especie.Lista} CdPlano={especie.Especie.CD_PLANO} />

        </Box>
      </Col>
    </Row>
  );
}