import { DadosPessoaisEntidade, FuncionarioEntidade, UsuarioEntidade, EntidadeEntidade, EmpresaEntidade } from ".";

export class FuncionarioDados {
    public DadosPessoais: DadosPessoaisEntidade;
    public Funcionario: FuncionarioEntidade;
    public Entidade: EntidadeEntidade;
    public Empresa: EmpresaEntidade;
    public Usuario: UsuarioEntidade;
    public NOME_EMPRESA: string;
    public CPF: string;
    public SEXO: string;
    public IDADE: string;
    public CEP: string;
    public DS_ESTADO_CIVIL: string;
    public CNPJ_EMPRESA: string;
    public TIPO: string;
}