export class LoginEntidade {
  public Cpf: string;
  public Inscricao?: string;
  public Senha?: string;
  public DataNascimento?: string;
  public Chave?: string;
  public SenhaAtual?: string;
  public SenhaAntiga?: string;
  public ConfirmarSenha?: string;
  public SenhaNova?: string;
}