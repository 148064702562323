import React, { ReactNode, ReactNodeArray } from "react";
import { Card, CardSubTitle, Title, Content, Badge } from "./styles";


interface Props {
  titulo: string;
  subTitulo?: string;
  label?: string;
  children: ReactNode | ReactNodeArray;
}

export const HomeCard: React.FC<Props> = ({
  titulo,
  subTitulo,
  label,
  children
}) => {

  return (
    <Card className="card mb-3" >
      <Title>{titulo}
        {label && <Badge className={"badge badge-secondary"}>{label}</Badge>}
        <br />

        {subTitulo &&
          <CardSubTitle>
            {subTitulo}
          </CardSubTitle>
        }

      </Title>

      <Content>{children}</Content>

    </Card>
  );
}