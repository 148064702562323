import React from 'react';
import { PlanoVinculadoEntidade } from '@entidades';
import { HomeCard } from '../../HomeCard/HomeCard';
import { Row, Col } from '@intech/web-ui';

interface IProps {
  plano: PlanoVinculadoEntidade;
  tempoPlano: string;
}

export const Header: React.FC<IProps> = ({
  plano,
  tempoPlano
}) => {

  function ToMoney(Valor: number) {
    return (
      "R$ " + Valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })
    );
  }

  return (
    <>
      <Row>
        <Col>
          <HomeCard titulo={"Plano"}>
            {plano.DS_PLANO}<br />
            <small>
              <span style={{ "fontWeight": "normal" }}>
                {`CNPB: ${plano.COD_CNPB}`}
              </span>
            </small>
          </HomeCard>
        </Col>

        <Col>
          <HomeCard titulo={"Situação no Plano"}>
            {plano.DS_SIT_PLANO}
          </HomeCard>
        </Col>
      </Row>

      <Row>
        <Col>
          <HomeCard titulo={"Data de Inscrição"}>
            {plano.DT_INSC_PLANO}
          </HomeCard>
        </Col>

        <Col>
          <HomeCard titulo={"Tempo de Plano"}>
            {tempoPlano}
          </HomeCard>
        </Col>
      </Row>

      <Row>
        <Col>
          <HomeCard titulo={"Regime de Tributação"}>
            {plano.TIPO_IRRF === "2" ? "Regressivo" : "Progressivo"}
          </HomeCard>
        </Col>
      </Row>
    </>
  );
}