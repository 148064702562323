import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { SaldoContribuicoesEntidade } from "../entidades/SaldoContribuicoesEntidade";
import { UltimaContribuicaoEntidade } from "../entidades/UltimaContribuicaoEntidade";

class FichaFechamento extends BaseService {
  constructor() {
    super("FichaFechamento");
  }

  BuscarSaldoPorPlano = (cdPlano: string) =>
    this.CreateRequest<SaldoContribuicoesEntidade>(RequestType.GET, `BuscarSaldoPorPlano/${cdPlano}`);

  BuscarUltimaContribuicaoPorPlano = (cdPlano: string) =>
    this.CreateRequest<UltimaContribuicaoEntidade>(RequestType.GET, `BuscarUltimaContribuicaoPorPlano/${cdPlano}`);

  BuscarContribuicaoParticipanteTotalAtualizada = (cdPlano: string) =>
    this.CreateRequest<number>(RequestType.GET, `BuscarContribuicaoParticipanteTotalAtualizada/${cdPlano}`);

}

export const FichaFechamentoService = new FichaFechamento();
