import styled, { css } from "styled-components";

export const Rubrica = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  font-size: 15px;
  min-height: 30px;
  align-items: center;

  &:nth-of-type(2n+1) {
    background-color: rgba(0,0,0,0.09);
  }
`;

export const Titulo = styled.div`
  display: flex;
  flex: 1;
  padding: 5px;
`;

const BadgeProvDesc = css`
  display: flex;
  color: #FFF;
  padding: 0px 10px;
  border-radius: 30px;
  font-size: 12px;
  align-items: center;
  max-height: 30px;
  margin: 5px;
`;

export const Provento = styled.div`
  background: ${props => props.theme.colors.green};
  
  ${BadgeProvDesc};
`;

export const Desconto = styled.div`
  background: ${props => props.theme.colors.red};
  
  ${BadgeProvDesc};
`;

export const Data = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.gray};
  margin-left: 10px;
`;

export const Valor = styled.div`
  display: flex;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
`;