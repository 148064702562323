import React from "react";

import packageJson from '../../../../package.json';

export const BaseAuth: React.FC<any> = ({ children, tamanho }) => {
  return (
    <div className="panel-login middle-box"
      style={{
        width: tamanho
      }}>
      <div className="logo">
        <img src={require("../../../imagens/logo.jpg")} alt="Logo da Preves" />
      </div>

      <h4>Bem-vindo ao portal da Preves</h4>

      {children}

      <br />
      <br />

      <div className="text-center">Versão {packageJson.version}</div>
    </div>
  );
};
