import React from 'react';

export const ResultadoHeader: React.FC = () => {

  return (
    <thead>
      <tr>
        <th style={{ width: "1px" }}>

        </th>
        <th>Nome</th>
        <th>Matrícula</th>
        <th>CPF</th>
        <th>Empresa</th>
        <th>Plano</th>
        <th>

        </th>
      </tr>
    </thead>
  );
}