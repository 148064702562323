import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { FuncionarioDados } from "../entidades/FuncionarioDados";
import { FuncionarioEntidade } from "../entidades/FuncionarioEntidade";
import { PesquisaDadosEntidade } from "../entidades/PesquisaDadosEntidade";

class Funcionario extends BaseService {
  constructor() {
    super("Funcionario");
  }

  Buscar = () =>
    this.CreateRequest<FuncionarioDados>(RequestType.GET, `Buscar`);

  BuscarPorCpf = () =>
    this.CreateRequest<Array<FuncionarioEntidade>>(RequestType.GET, `BuscarPorCpf`);

  BuscarPorInscricao = (num_inscricao: string) =>
    this.CreateRequest<Array<FuncionarioEntidade>>(RequestType.GET, `BuscarPorInscricao/${num_inscricao}`);

  Pesquisar = (dados: PesquisaDadosEntidade) =>
    this.CreateRequest<Array<FuncionarioEntidade>>(RequestType.POST, `Pesquisar`, dados);

}

export const FuncionarioService = new Funcionario();
