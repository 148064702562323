import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { GrauParentescoEntidade } from "../entidades/GrauParentescoEntidade";

class GrauParentesco extends BaseService {
	constructor() {
		super("GrauParentesco");
	}

	BuscarTodos = () =>
		this.CreateRequest<Array<GrauParentescoEntidade>>(RequestType.GET, `BuscarTodos`);

}

export const GrauParentescoService = new GrauParentesco();
