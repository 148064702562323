import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { DocumentoAtualizacaoCadastralEntidade } from "../entidades/DocumentoAtualizacaoCadastralEntidade";

class DocAtualizacaoCadastral extends BaseService {
	constructor() {
		super("DocAtualizacaoCadastral");
	}

	Incluir = (oid_arquivo_upload: number, num_inscricao: string, seq_recebedor: number) =>
		this.CreateRequest<any>(RequestType.POST, `Incluir/${oid_arquivo_upload}/${num_inscricao}/${seq_recebedor}`);

	ObterPorFundacaoInscricao = (num_inscricao: string) =>
		this.CreateRequest<Array<DocumentoAtualizacaoCadastralEntidade>>(RequestType.GET, `ObterPorFundacaoInscricao/${num_inscricao}`);

	Download = (oidArquivo: number) =>
		this.CreateRequest<any>(RequestType.GET, `Download/${oidArquivo}`, null, ResponseType.Blob);

}

export const DocAtualizacaoCadastralService = new DocAtualizacaoCadastral();
