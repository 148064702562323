import React, { Component, FormEvent } from "react";
import { Box, Botao, TipoBotao, Row, Col, CampoTexto, PosicaoTituloCampoTexto, Alerta, TipoAlerta } from "@intechprev/componentes-web";
import { DependenteEntidade } from "../../entidades";

interface Props {
    history?: History;
    dependente: DependenteEntidade;
}

interface State {

}

export class EnderecoDependente extends React.Component<Props, State> {

    state: State = {
        dependente: this.props.dependente,
    }

    componentDidMount = async () => {
        //await this.load();
    }

    salvar = async () => {

    }

    render() {
        return (
            <div className="tab-content" >
                <Box titulo="Endereço Residencial">
                    <Row>
                        <Col>
                            <CampoTexto
                                contexto={this}
                                parent="dependente"
                                nome={"END_DEP"}
                                titulo="Endereço"
                                valor={this.props.dependente.END_DEP}
                                tituloClassName={"text-primary"}
                                posicaoTitulo={PosicaoTituloCampoTexto.cima} 
                            />
                        </Col>
                        <Col>
                            <CampoTexto
                                contexto={this}
                                parent="dependente"
                                nome={"NR_END_DEP"}
                                titulo="Número"
                                valor={this.props.dependente.NR_END_DEP}
                                tituloClassName={"text-primary"}
                                posicaoTitulo={PosicaoTituloCampoTexto.cima}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <CampoTexto
                                contexto={this}
                                parent="dependente"
                                nome={"COMP_END_DEP"}
                                titulo="Complemento"
                                valor={this.props.dependente.COMP_END_DEP}
                                tituloClassName={"text-primary"}
                                posicaoTitulo={PosicaoTituloCampoTexto.cima} 
                            />
                        </Col>
                        <Col>
                            <CampoTexto
                                contexto={this}
                                parent="dependente"
                                nome={"BAIRRO_DEP"}
                                titulo="Bairro"
                                valor={this.props.dependente.BAIRRO_DEP}
                                tituloClassName={"text-primary"}
                                posicaoTitulo={PosicaoTituloCampoTexto.cima}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <CampoTexto
                                contexto={this}
                                parent="dependente"
                                nome={"CID_DEP"}
                                titulo="Cidade"
                                valor={this.props.dependente.CID_DEP}
                                tituloClassName={"text-primary"}
                                posicaoTitulo={PosicaoTituloCampoTexto.cima}
                            />
                        </Col>
                        <Col>
                            <CampoTexto
                                contexto={this}
                                parent="dependente"
                                nome={"UF_DEP"}
                                titulo="UF"
                                valor={this.props.dependente.UF_DEP}
                                tituloClassName={"text-primary"}
                                posicaoTitulo={PosicaoTituloCampoTexto.cima}
                            />
                        </Col>
                        <Col>
                            <CampoTexto
                                contexto={this}
                                parent="dependente"
                                nome={"CEP_DEP"}
                                titulo="Cep"
                                valor={this.props.dependente.CEP_DEP}
                                tituloClassName={"text-primary"}
                                posicaoTitulo={PosicaoTituloCampoTexto.cima}
                            />
                        </Col>
                    </Row>
                </Box>
           </div>
        );
    }
}