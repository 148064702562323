import React, { useRef, useState } from 'react';
import { Button, Input, Alert, Form } from '@intech/web-ui';

// import { Subtitle } from '../styles';

import { FaShareSquare, FaArrowLeft } from 'react-icons/fa';
import { UsuarioService } from '@services';
import { LoginEntidade } from '@entidades';
import { useHistory } from 'react-router-dom';
import { BaseAuth } from '../Base';
import { CheckboxContainer, CheckboxTitle } from './styles';
import { Checkbox } from './Checkbox';

export const EsqueciSenha: React.FC = () => {
  const history = useHistory();
  const form = useRef<Form>(null);

  const [cpf, setCpf] = useState<string>('');
  const [bday, setBday] = useState<string>('');
  const [enviarEmail, setEnviarEmail] = useState(true);
  const [enviarSms, setEnviarSms] = useState(false);
  const [AlertText, setAlertText] = useState<React.ReactNode>("");

  async function SendNewPassword() {
    try {
      setAlertText("");

      const erros = await form.current.validate();

      if (erros) {
        setAlertText(erros);
        return null;
      }

      if (cpf.length < 11) {
        setAlertText("Campo \"CPF\" inválido.");
        return;
      }

      const user = new LoginEntidade();
      user.Cpf = cpf;
      user.DataNascimento = bday;

      var resultado = await UsuarioService.CriarAcesso(user);
      window.alert(resultado);
      console.log(resultado);
      history.push("login");
    }
    catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
      setAlertText(error);
    }
  }

  function toggleSms() {
    setEnviarSms(true);
    setEnviarEmail(false);
  }

  function toggleEmail() {
    setEnviarSms(false);
    setEnviarEmail(true);
  }

  return (
    <BaseAuth>

      {/* <Subtitle> */}
      <h5>
        <b>
          {"Esqueci minha senha / Primeiro Acesso"}
        </b>

        <br />
        <br />

        <small>
          {"Preencha as informações para que possamos gerar uma senha que será enviada para seu e-mail cadastrado na fundação Preves."}
        </small>
      </h5>
      {/* </Subtitle> */}

      <Form ref={form}>
        <Input
          value={cpf}
          onChange={setCpf}
          placeholder={"CPF (somente números)"}
        />

        <Input
          value={bday}
          onChange={setBday}
          placeholder={"Data de Nascimento"}
          type={"date"}
        />

        {/* <CheckboxTitle>Enviar nova senha por:</CheckboxTitle>
        <CheckboxContainer>
          <Checkbox
            title={"E-mail"}
            active={enviarEmail}
            onClick={toggleEmail}
          />
          <Checkbox
            title={"SMS"}
            active={enviarSms}
            onClick={toggleSms}
          />
        </CheckboxContainer> */}

        <Button
          icon={<FaShareSquare />}
          onClick={SendNewPassword}
          type="primary"
          title={"Enviar Nova Senha"}
          loadingText={"Enviando..."}
          usesLoading
          // blocks
          className={"w-100 mb-2"}
          submit
        />

        <Button
          icon={<FaArrowLeft />}
          onClick={() => history.push('login')}
          type={"light"}
          title={"Voltar para o login"}
          // block
          className={"w-100 mb-2"}
        />

        <Alert type={"danger"}>
          {AlertText}
        </Alert>
      </Form>

    </BaseAuth>
  );
}