import React, { useEffect, useState } from 'react';
import { Button, Input, ComboBox, Alert, Form, Box } from '@intech/web-ui';
import { useHistory } from "react-router-dom";

import { FuncionarioService, EmpresaService } from "@services";

import { PesquisaDadosEntidade, EmpresaEntidade, FuncionarioEntidade } from "@entidades";

import { ResultadoItem } from './ResultadoItem';
import { ResultadoHeader } from './ResultadoHeader';
import { LoadingComponent } from '@components';

export const ListarParticipantes: React.FC = () => {

  const history = useHistory();

  const [Loading, setLoading] = useState<boolean>(true);
  const [Matricula, setMatricula] = useState<string>("");
  const [Nome, setNome] = useState<string>("");
  const [CdEmpresa, setCdEmpresa] = useState<string | null>(null);
  const [Empresas, setEmpresas] = useState<Array<EmpresaEntidade>>([]);
  const [ResultadoPesquisa, setResultadoPesquisa] = useState<Array<FuncionarioEntidade> | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const Empresas = await EmpresaService.Buscar();
        setEmpresas(Empresas);

        setLoading(false);
      }
      catch (e) {
        const error = e.response ? e.response.data : e;
        console.log(error);
        if (e.message.indexOf("401") > -1) {
          history.push('login');
        }
        setLoading(false);
      }
    })();
  }, []);


  async function Pesquisar() {
    try {
      let pd = new PesquisaDadosEntidade();
      pd.CD_FUNDACAO = null;
      pd.CD_EMPRESA = CdEmpresa;
      pd.CD_PLANO = null;
      pd.CD_SIT_PLANO = null;
      pd.NUM_MATRICULA = Matricula;
      pd.NOME = Nome;
      const resultadoPesquisa = await FuncionarioService.Pesquisar(pd);
      setResultadoPesquisa(resultadoPesquisa);
    }
    catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
      if (e.message.indexOf("401") > -1) {
        history.push('login');
      }
    }
  };

  function renderResultado() {
    if (ResultadoPesquisa !== null) {
      if (ResultadoPesquisa.length > 0) {
        const Resultados = ResultadoPesquisa.map((func: FuncionarioEntidade, index: number) => {
          return (
            <ResultadoItem
              key={index}
              Funcionario={func}
              Empresa={Empresas.filter(x => x.CD_EMPRESA === func.CD_EMPRESA)[0].NOME_ENTID}
            />
          );
        });
        return (
          <div className={"table-responsive"}>
            <table className={"table table-striped table-sm"}>
              <ResultadoHeader />

              <tbody>
                {Resultados}
              </tbody>
            </table>
          </div>
        );
      }
      return (
        <Alert className={"mt-2"} type="danger">
          {"NÂO LOCALIZADO"}
        </Alert>
      );
    }
    return null;
  }

  if (Loading) {
    return (
      <LoadingComponent />
    );
  }

  return (
    <Box title={"Listagem de Participantes"}>
      <Form>

        <Input
          placeholder={"Matricula"}
          value={Matricula}
          onChange={setMatricula}
        />

        <Input
          placeholder={"Nome"}
          value={Nome}
          onChange={setNome}
        />

        <ComboBox
          value={CdEmpresa}
          data={Empresas}
          memberName={"SIGLA_ENTID"}
          memberValue={"CD_EMPRESA"}
          emptyText={"TODAS"}
          onChange={setCdEmpresa}
        />

        <Button
          type={"primary"}
          onClick={Pesquisar}
          title={"Procurar"}
          loadingText={"Procurando..."}
          usesLoading
          submit
        />

      </Form>

      <br />

      {renderResultado()}

    </Box>
  );
}
