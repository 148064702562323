import { Session } from "@intech/react-service";
import { Col, Row } from "@intech/web-ui";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FuncionarioService, UsuarioService } from "@services";
import { BaseAuth } from "../Base";
import { FuncionarioEntidade, LoginEntidade } from "@entidades";
import { LoadingComponent } from "@components";

export const SelecionarMatricula: React.FC = () => {
  const history = useHistory();

  const [Funcionarios, setFuncionarios] = useState<Array<FuncionarioEntidade>>(new Array<FuncionarioEntidade>());

  useEffect(() => {
    (async () => {
      const funcionarios = await FuncionarioService.BuscarPorCpf();
      setFuncionarios(funcionarios);
    })();
  }, []);

  const selecionar = async (inscricao: FuncionarioEntidade) => {
    const user = new LoginEntidade();
    user.Cpf = inscricao.CPF_CGC;
    user.Inscricao = inscricao.NUM_INSCRICAO;

    var funcionarioLogin = await UsuarioService.SelecionarInscricao(user);
    await Session.setToken(funcionarioLogin.AccessToken, true);

    history.push("/");
  };

  return (
    <BaseAuth>
      <br />
      <h4>Selecione uma inscrição</h4>
      <br />
      <br />

      {Funcionarios.length === 0 && <LoadingComponent />}

      {Funcionarios.map((inscricao, index) => {
        return (
          <Row key={index} className={"mb-3"}>
            <Col>
              <div
                className="matricula-card"
                onClick={() => selecionar(inscricao)}
              >
                <Row>
                  <Col>
                    <b>Inscrição: </b><label style={{ fontSize: 15 }}>{inscricao.NUM_INSCRICAO}</label><br />
                    <b>Matrícula: </b><label style={{ fontSize: 15 }}>{inscricao.NUM_MATRICULA}</label>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      })}
    </BaseAuth>
  );
};
