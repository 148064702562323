import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { LoginEntidade } from "../entidades/LoginEntidade";
import { JsonWebToken } from "../entidades/JsonWebToken";

class Usuario extends BaseService {
  constructor() {
    super("Usuario");
  }

  Get = () =>
    this.CreateRequest<any>(RequestType.GET, ``);

  VerificarAdmin = () =>
    this.CreateRequest<boolean>(RequestType.GET, `admin`);

  Login = (login: LoginEntidade) =>
    this.CreateRequest<JsonWebToken>(RequestType.POST, `Login`, login);

  CriarAcessoIntech = (data: any) =>
    this.CreateRequest<any>(RequestType.POST, `CriarAcessoIntech`, data);

  CriarAcesso = (data: any) =>
    this.CreateRequest<any>(RequestType.POST, `CriarAcesso`, data);

  AlterarSenha = (data: any) =>
    this.CreateRequest<any>(RequestType.POST, `AlterarSenha`, data);

  SelecionarInscricao = (login: LoginEntidade) =>
    this.CreateRequest<JsonWebToken>(RequestType.POST, `SelecionarInscricao`, login);

  AlterarSenhaPrimeiroAcesso = (data: LoginEntidade) =>
    this.CreateRequest<any>(RequestType.POST, `AlterarSenhaPrimeiroAcesso`, data);

  BuscarMatriculas = () =>
    this.CreateRequest<Array<string>>(RequestType.GET, `BuscarMatriculas`);

  SelecionarParticipante = (login: LoginEntidade) =>
    this.CreateRequest<JsonWebToken>(RequestType.POST, `SelecionarParticipante`, login);

}

export const UsuarioService = new Usuario();
