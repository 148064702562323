import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { ProcessoBeneficioEntidade } from "../entidades/ProcessoBeneficioEntidade";

class ProcessoBeneficio extends BaseService {
	constructor() {
		super("ProcessoBeneficio");
	}

	BuscarPorPlano = (cdplano: string) =>
		this.CreateRequest<Array<ProcessoBeneficioEntidade>>(RequestType.GET, `BuscarPorPlano/${cdplano}`);

	BuscarExtratoAssistidoPorPlanoEspecieNumeroProcessoAnoProcesso = (cdPlano: string, especie: string, numero: number, ano: string) =>
		this.CreateRequest<Array<any>>(RequestType.GET, `BuscarExtratoAssistidoPorPlanoEspecieNumeroProcessoAnoProcesso/${cdPlano}/${especie}/${numero}/${ano}`);

}

export const ProcessoBeneficioService = new ProcessoBeneficio();
