import React, { Component, FormEvent } from "react";

import _ from "lodash";

import { default as NumberFormat } from 'react-number-format';

import { FooterBar } from './../FooterBar';

import { Alert, Button, ButtonSwitch, Col, Modal, Row } from "@intech/web-ui";
import { FaQuestion } from "react-icons/fa";

import textos from "./../Textos.json";
import { ModalHolder } from "../styles";
import { IndiceEntidade } from "@entidades";
import { IndiceService } from "@services";

interface Props {
  Dados: any;
  proximoPasso: () => void;
  passoAnterior: () => void;
}

interface State {
  idadeMinima: number;
  idadeMaxima: number;
  idadeAposentadoria: number;
  idadeAtual: number;
  percentualAVista: number;
  aporte: number;
  termoAceito: boolean;
  dadosValidos: boolean;

  tempoAposentadoriaMinima: number;
  tempoAposentadoriaMaxima: number;
  tempoAposentadoria: number;

  tempoSobrevivenciaMinima: number;
  tempoSobrevivenciaMaxima: number;
  tempoSobrevivencia: number;

  remuneracaoInicialMinima: number;
  remuneracaoInicialMaxima: number;
  remuneracaoInicial: number;

  remuneracaoFinalMinima: number;
  remuneracaoFinalMaxima: number;
  remuneracaoFinal: number;

  taxaCrescimentoSalarial: number;
  taxaCrescimentoSalarialMinima: number;
  taxaCrescimentoSalarialMaxima: number;

  rgps: number;
  termosAceitos: boolean;

  modalStatus: boolean;
  textoModal: any;

  msgRemuneracaoInicialInvalida: string;
  msgRemuneracaoFinalInvalida: string;

  taxaJurosReal: IndiceEntidade;
}

export class Passo2 extends Component<Props, Partial<State>> {

  constructor(props: Props) {
    super(props);

    this.state = {
      // States Passo 2:
      idadeMinima: this.props.Dados.idadeMinima,
      idadeMaxima: this.props.Dados.idadeMaxima,
      idadeAposentadoria: this.props.Dados.idadeAposentadoria,
      idadeAtual: this.props.Dados.idadeAtual,
      percentualAVista: this.props.Dados.percentualAVista,
      aporte: this.props.Dados.aporte,
      termoAceito: this.props.Dados.termoAceito,

      tempoAposentadoriaMinima: this.props.Dados.tempoAposentadoriaMinima,
      tempoAposentadoriaMaxima: this.props.Dados.tempoAposentadoriaMaxima,
      tempoAposentadoria: this.props.Dados.tempoAposentadoria,

      tempoSobrevivenciaMinima: this.props.Dados.tempoSobrevivenciaMinima,
      tempoSobrevivenciaMaxima: this.props.Dados.tempoSobrevivenciaMaxima,
      tempoSobrevivencia: this.props.Dados.tempoSobrevivencia,

      remuneracaoInicialMinima: this.props.Dados.remuneracaoInicialMinima,
      remuneracaoInicialMaxima: this.props.Dados.remuneracaoInicialMaxima,
      remuneracaoInicial: this.props.Dados.remuneracaoInicial,

      remuneracaoFinalMinima: this.props.Dados.remuneracaoFinalMinima,
      remuneracaoFinalMaxima: this.props.Dados.remuneracaoFinalMaxima,
      remuneracaoFinal: this.props.Dados.remuneracaoFinal,

      taxaCrescimentoSalarial: this.props.Dados.taxaCrescimentoSalarial,
      taxaCrescimentoSalarialMinima: this.props.Dados.taxaCrescimentoSalarialMinima,
      taxaCrescimentoSalarialMaxima: this.props.Dados.taxaCrescimentoSalarialMaxima,

      rgps: this.props.Dados.rgps,

      termosAceitos: false, //this.props.Dados.termosAceitos,
      dadosValidos: true,

      modalStatus: false,
      textoModal: "",
      msgRemuneracaoInicialInvalida: "",
      msgRemuneracaoFinalInvalida: "",

      taxaJurosReal: new IndiceEntidade()
    }

  }

  componentDidMount = async () => {
    //await this.toggleModal();

    const taxaJurosReal = await IndiceService.BuscarUltimoPorCodigo("TX_ANUAL");

    this.setState({
      taxaJurosReal
    });
  }

  proximo = () => {
    this.atualizaDados();

    // metódo do formulario Pai
    this.props.proximoPasso();
  };

  anterior = () => {
    // metódo do formulario Pai
    this.props.passoAnterior();
  };

  validaPagina() {
    var termosAceitos = !this.state.termosAceitos;

    var dadosValidos = true;
    if (this.state.remuneracaoInicial <= this.state.rgps && this.props.Dados.tipoAtivo === "A") {
      dadosValidos = false;
    }
    else if (this.state.remuneracaoInicial == 0) {
      dadosValidos = false;
    }

    if (this.state.remuneracaoFinal < this.state.remuneracaoInicial) {
      dadosValidos = false;
    }

    this.setState({ termosAceitos, dadosValidos })
    this.props.Dados.termosAceitos = termosAceitos;
  }

  atualizaDados() {
    // States Passo 2:
    this.props.Dados.idadeMinima = this.state.idadeMinima;
    this.props.Dados.idadeMaxima = this.state.idadeMaxima;
    this.props.Dados.idadeAposentadoria = this.state.idadeAposentadoria;
    this.props.Dados.idadeAtual = this.state.idadeAtual;
    this.props.Dados.percentualAVista = this.state.percentualAVista;
    this.props.Dados.aporte = this.state.aporte;
    this.props.Dados.termoAceito = this.state.termoAceito;

    this.props.Dados.tempoAposentadoriaMinima = this.state.tempoAposentadoriaMinima;
    this.props.Dados.tempoAposentadoriaMaxima = this.state.tempoAposentadoriaMaxima;
    this.props.Dados.tempoAposentadoria = this.state.tempoAposentadoria;

    this.props.Dados.tempoSobrevivenciaMinima = this.state.tempoSobrevivenciaMinima;
    this.props.Dados.tempoSobrevivenciaMaxima = this.state.tempoSobrevivenciaMaxima;
    this.props.Dados.tempoSobrevivencia = this.state.tempoSobrevivencia;

    this.props.Dados.remuneracaoInicialMinima = this.state.remuneracaoInicialMinima;
    this.props.Dados.remuneracaoInicialMaxima = this.state.remuneracaoInicialMaxima;
    this.props.Dados.remuneracaoInicial = this.state.remuneracaoInicial;

    this.props.Dados.remuneracaoFinalMinima = this.state.remuneracaoFinalMinima;
    this.props.Dados.remuneracaoFinalMaxima = this.state.remuneracaoFinalMaxima;
    this.props.Dados.remuneracaoFinal = this.state.remuneracaoFinal;

    this.props.Dados.taxaCrescimentoSalarial = this.state.taxaCrescimentoSalarial;
    this.props.Dados.taxaCrescimentoSalarialMinima = this.state.taxaCrescimentoSalarialMinima;
    this.props.Dados.taxaCrescimentoSalarialMaxima = this.state.taxaCrescimentoSalarialMaxima;

    this.props.Dados.rgps = this.state.rgps;

    this.props.Dados.termosAceitos = this.state.termosAceitos;
  }

  validaRemuneracaoInicial = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    var nome = ((e.target) as any).name;
    var remuneracaoInicial = ((e.target) as any).value;
    var rgps = this.state.rgps;
    var ativo = this.props.Dados.tipoAtivo;
    var msg = "";

    remuneracaoInicial = remuneracaoInicial.replace('.', '');
    remuneracaoInicial = remuneracaoInicial.replace(',', '.');
    remuneracaoInicial = parseFloat(remuneracaoInicial);

    var dadosValidos = true;
    if (remuneracaoInicial <= rgps && ativo === "A") {
      msg = 'Para os Ativos, a remuneração inicial deverá ser superior ao teto do RPPS/RGPS, atualmente em R$: ' + rgps.toString();
      dadosValidos = false;
    }
    else if (remuneracaoInicial == 0) {
      msg = 'Campo Obrigatório'
      dadosValidos = false;
    }

    this.setState({
      msgRemuneracaoInicialInvalida: msg,
      remuneracaoInicial: remuneracaoInicial,
      dadosValidos: dadosValidos
    });
  }

  /**
   * @returns {boolean} Valor que representa se a remuneração final está inválida.
   * @description Método que acessa o campo e valida o valor da remuneração final, que deve ser maior que a remuneração inicial.
   */
  validaRemuneracaoFinal = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    var nome = ((e.target) as any).name;
    var remuneracaoFinal = ((e.target) as any).value;
    var msg = "";

    var remuneracaoInicial = this.state.remuneracaoInicial;
    remuneracaoFinal = remuneracaoFinal.replace('.', '');
    remuneracaoFinal = remuneracaoFinal.replace(',', '.');
    remuneracaoFinal = parseFloat(remuneracaoFinal);
    var dadosValidos = true;

    if (remuneracaoFinal < remuneracaoInicial) {
      msg = 'A Remuneração Final deve ser igual ou superior à Remuneração Inicial'
      dadosValidos = false;
    }

    this.setState({
      msgRemuneracaoFinalInvalida: msg,
      dadosValidos: dadosValidos,
      remuneracaoFinal: remuneracaoFinal
    });
  }

  toggleModal = (texto: any) => {
    var textoModal = texto;

    const body = document.querySelector("body");
    if (!this.state.modalStatus) {
      body.classList.add('modal-open');
    }
    else {
      body.classList.remove('modal-open');
    }

    this.setState({
      modalStatus: !this.state.modalStatus,
      textoModal: textoModal
    })
  }

  handleidadeAposentadoria = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);
    this.setState({ idadeAposentadoria: valor })
  }

  handletempoAposentadoria = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);
    this.setState({ tempoAposentadoria: valor })
  }

  handletempoSobrevivencia = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);
    this.setState({ tempoSobrevivencia: valor })
  }

  handletaxaCrescimentoSalarial = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);
    this.setState({ taxaCrescimentoSalarial: valor })
  }

  render() {

    return (
      <div>
        {this.state.modalStatus &&
          <ModalHolder>
            <Modal
              type={"body"}
              onClose={this.toggleModal}
              title={"Informações"}
              closeButtonTitle={"Ok, entendi!"}
              content={this.state.textoModal}
            />
          </ModalHolder>
        }

        <div>

          <Row>
            <Col>
              <h4 className={"mt-5 mb-2"}>Você pretende se aposentar com quantos anos?</h4>
              <div className="islider mb-5 pl-4 pr-4">
                <input type="range" id={"idadeMaxima"} name={"idadeMaxima"}
                  min={this.state.idadeMinima}
                  max={this.state.idadeMaxima}
                  step={1}
                  value={this.state.idadeAposentadoria}
                  className="slider"
                  onChange={this.handleidadeAposentadoria}
                />
                <div className="value">&nbsp; {this.state.idadeAposentadoria} anos</div>
              </div>
            </Col>

            <Col>
              <h4 className={"mt-5 mb-2"}>Você pretende receber sua aposentadora por quantos anos?</h4>
              <div className="islider mb-5 pl-4 pr-4">
                <input type="range" id={"sobrevivencia"} name={"sobrevivencia"}
                  min={this.state.tempoAposentadoriaMinima}
                  max={this.state.tempoAposentadoriaMaxima}
                  step={1}
                  value={this.state.tempoAposentadoria}
                  className="slider"
                  onChange={this.handletempoAposentadoria}
                />
                <div className="value">&nbsp; {this.state.tempoAposentadoria} anos</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className={"mt-5 mb-2"}>Você pretende receber sobrevivência por quantos anos?</h4>
              <div className="islider mb-5 pl-4 pr-4">
                <input type="range" id={"sobrevivencia"} name={"sobrevivencia"}
                  min={this.state.tempoSobrevivenciaMinima}
                  max={this.state.tempoSobrevivenciaMaxima}
                  step={1}
                  value={this.state.tempoSobrevivencia}
                  className="slider"
                  onChange={this.handletempoSobrevivencia}
                />
                <div className="value">&nbsp; {this.state.tempoSobrevivencia} anos</div>
              </div>
            </Col>

            <Col>
              <h4 className={"mt-5 mb-2"}>Taxa de Crescimento Salarial a cada dois anos %</h4>
              <div className="islider mb-5 pl-4 pr-4">
                <input type="range" id={"sobrevivencia"} name={"sobrevivencia"}
                  min={this.state.taxaCrescimentoSalarialMinima}
                  max={this.state.taxaCrescimentoSalarialMaxima}
                  step={1}
                  value={this.state.taxaCrescimentoSalarial}
                  className="slider"
                  onChange={this.handletaxaCrescimentoSalarial}
                />
                <div className="value">&nbsp; {this.state.taxaCrescimentoSalarial} %</div>
              </div>
            </Col>
          </Row>

          <Row className={"mb-3"}>
            <Col className={"col-lg-4"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"remuneracaoInicial"}>
                    {"Remuneração Atual"}
                  </label>
                </b>
              </div>

              <div className={"input-group"}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">R$</span>
                </div>

                {/* <CampoValor contexto={this} nome={"remuneracaoInicial"} valor={this.state.remuneracaoInicial}
                  titulo={"Remuneração Atual"}
                  tituloClassName={"text-primary"}
                  posicaoTitulo={PosicaoTituloCampoValor.cima}
                  tipo={"dinheiro"}
                  grupo
                  onBotaoClick={() => { this.toggleModal(textos.remuneracaoInicial) }}
                  iconeBotao={"fa-question"}
                  iconeBotaoDireita
                  onBlur={this.validaRemuneracaoInicial}
                /> */}

                <NumberFormat
                  name={"remuneracaoInicial"}
                  id={"remuneracaoInicial"}
                  className={"form-control"}
                  thousandSeparator={"."}
                  decimalSeparator=","
                  decimalScale={2}
                  allowedDecimalSeparators={[","]}
                  //prefix="R$"
                  fixedDecimalScale={true}
                  value={Number(this.state.remuneracaoInicial)}
                  onBlur={this.validaRemuneracaoInicial}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => { this.toggleModal(textos.remuneracaoInicial) }}
                    type={"primary"}
                    icon={<FaQuestion />}
                  />
                </div>
              </div>

              {this.state.msgRemuneracaoInicialInvalida !== "" &&
                <Alert type={"danger"}>{this.state.msgRemuneracaoInicialInvalida}</Alert>
              }
            </Col>

            <Col className={"col-lg-4"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"remuneracaoFinal"}>
                    {"Remuneração Final"}
                  </label>
                </b>
              </div>

              <div className={"input-group"}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">R$</span>
                </div>

                {/* <CampoValor
                contexto={this}
                nome={"remuneracaoFinal"}
                valor={this.state.remuneracaoFinal}
                titulo={"Remuneração Final"}
                tituloClassName={"text-primary"}
                posicaoTitulo={PosicaoTituloCampoValor.cima}
                tipo={"dinheiro"}
                grupo
                onBotaoClick={() => { this.toggleModal(textos.remuneracaoFinal) }}
                iconeBotao={"fa-question"}
                iconeBotaoDireita
                onBlur={this.validaRemuneracaoFinal}
              /> */}

                <NumberFormat
                  name={"remuneracaoFinal"}
                  id={"remuneracaoFinal"}
                  className={"form-control"}
                  thousandSeparator={"."}
                  decimalSeparator=","
                  decimalScale={2}
                  allowedDecimalSeparators={[","]}
                  //prefix="R$"
                  fixedDecimalScale={true}
                  value={Number(this.state.remuneracaoFinal)}
                  onBlur={this.validaRemuneracaoFinal}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => { this.toggleModal(textos.remuneracaoFinal) }}
                    type={"primary"}
                    icon={<FaQuestion />}
                    iconRight
                  />
                </div>
              </div>

              {this.state.msgRemuneracaoFinalInvalida !== "" &&
                <Alert type={"danger"}>{this.state.msgRemuneracaoFinalInvalida}</Alert>
              }
            </Col>

            <Col className={"col-lg-4"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>
                    {"Taxa de Juros Real (meta financeira)"}
                  </label>
                </b>
              </div>

              <div className={"input-group"}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">%</span>
                </div>

                {/* <CampoValor
                  contexto={this}
                  nome={""}
                  valor={3.75}
                  // titulo={"Taxa de Juros Real (meta financeira)"}
                  tituloClassName={"text-primary"}
                  posicaoTitulo={PosicaoTituloCampoValor.cima}
                  tipo={"percentual"}
                  grupo
                  onBotaoClick={() => { this.toggleModal(textos.taxaJurosReal) }}
                  iconeBotao={"fa-question"}
                  iconeBotaoDireita
                  desabilitado={true}
                /> */}

                <NumberFormat
                  name={"taxaJuros"}
                  id={"taxaJuros"}
                  className={"form-control"}
                  decimalSeparator=","
                  decimalScale={2}
                  // prefix="%"
                  fixedDecimalScale={true}
                  disabled={true}
                  value={this.state.taxaJurosReal && this.state.taxaJurosReal.VALORES && this.state.taxaJurosReal.VALORES.length > 0 ? this.state.taxaJurosReal.VALORES[0].VALOR_IND : 0}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => { this.toggleModal(textos.taxaJurosReal) }}
                    type={"primary"}
                    icon={<FaQuestion />}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Alert type={"danger"}>
            <h4>Antes de continuar, é muito importante entender as informações abaixo:</h4>
            <p className="justify">
              A) As informações constantes nesta simulação partem de dados informados pelos participantes no ato da opção pela previdência complementar. B) A simulação
              apresentará resultado diferenciado, caso o participante altere, durante sua vida funcional, os dados aqui fornecidos. C) Toda simulação trabalha com eventos
              futuros incertos e seus resultados são completamente estimados em relação a estes eventos. D) O valor da remuneração paga pelo rpps somente será efetivamente
              conhecido quando o servidor tornar-se elegível ao benefício requerido. E) A preves não se responsabiliza pelas informações prestadas pelo participante para a
              geração da simulação previdenciária. F) Os resultados apresentados, referentes a esta simulação, não são garantia de benefício futuro. G) Benefício Pecúlio por
              Morte (598) N° Processo SUSEP: 15414.005367/2012-77 H) Benefício Pecúlio por Invalidez (599) N° Processo SUSEP: 15414.005364/2012-33 I) O registro destes planos
              na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação para sua comercialização. J) BENEFÍCIOS DE RISCO - PECÚLIOS GARANTIDOS PELA MONGERAL AEGON
              SEGUROS E PREVIDÊNCIA S.A - CNPJ: 33.608.308/0001-73
            </p>
            <hr />

            <Row className={"mb-2"}>
              <Col className={"col-lg-1 text-center"}>
                <ButtonSwitch
                  activeType={"success"}
                  checked={this.state.termosAceitos}
                  onChange={() => this.validaPagina()}
                />
              </Col>
              <Col>
                <h4 onClick={() => this.validaPagina()}>
                  {"Li e entendi!"}
                </h4>
              </Col>
            </Row>
          </Alert>
        </div >
        <hr />

        <FooterBar
          avancarHabilitado={this.state.termosAceitos && this.state.dadosValidos}
          avancar={this.proximo}
          voltar={this.anterior}
        />

      </div>
    )
  }
}

export default Passo2;
