import React, { useEffect, useState } from "react";
import { FichaFinanceiraService, InfoRendService } from "@services";
import {
  Alert,
  Box,
  Button, Col, ComboBox, Row
} from "@intech/web-ui";
import { FichaFinanceiraEntidade, HeaderInfoRendEntidade, InfoRendGrupoEntidade } from "@entidades";
import { Grupo } from "./Grupo";
import { AvisoDownloadPDF, LoadingComponent, MasterPage } from "@components";

import { NumFuncionalidade } from "../../helpers/BloqueioFuncionalidade";

export const InformeRendimentos: React.FC = (props) => {
  const [AlertText, setAlertText] = useState<string>("");
  const [Datas, setDatas] = useState<Array<HeaderInfoRendEntidade>>(new Array<HeaderInfoRendEntidade>());
  const [Informe, setInforme] = useState<HeaderInfoRendEntidade>(undefined);
  const [Referencia, setReferencia] = useState<number>(0);

  const [DatasContribuicoes, setDatasContribuicoes] = useState<Array<FichaFinanceiraEntidade>>(new Array<FichaFinanceiraEntidade>());
  const [InformeContribuicoes, setInformeContribuicoes] = useState<Array<FichaFinanceiraEntidade>>(new Array<FichaFinanceiraEntidade>());
  const [ReferenciaContribuicoes, setReferenciaContribuicoes] = useState<string>("");
  const [RodouDownloadPDF, setRodouDownloadPDF] = useState<boolean>(false);
  const [LoadingInforme, setLoadingInforme] = useState<boolean>(true);
  const [LoadingContribuicoes, setLoadingContribuicoes] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setLoadingInforme(true);
      setLoadingContribuicoes(true);
      try {
        const datas = await InfoRendService.BuscarReferencias();

        if (datas.length > 0) {
          setDatas(datas);
          setReferencia(datas[0].OID_HEADER_INFO_REND);
        }

        const datasInformeContribuicoes = await FichaFinanceiraService.BuscarDatasInforme();

        if (datasInformeContribuicoes.length > 0) {
          setDatasContribuicoes(datasInformeContribuicoes);
          setReferenciaContribuicoes(datasInformeContribuicoes[0].ANO_REF);
        }
      }
      catch (e) {
        const error = e["response"] ? e["response"]["data"] : e;
        setAlertText(error);
      }
      finally {
        setLoadingInforme(false);
        setLoadingContribuicoes(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingInforme(true);
      try {
        if (Referencia > 0) {
          const informe = await InfoRendService.BuscarPorReferencia(Referencia);
          setInforme(informe);
        }
      }
      catch (e) {
        setInforme(undefined);
        const error = e["response"] ? e["response"]["data"] : e;
        setAlertText(error);
      }
      finally {
        setLoadingInforme(false);
      }
    })();
  }, [Referencia]);

  useEffect(() => {
    (async () => {
      setLoadingContribuicoes(true);
      try {
        if (ReferenciaContribuicoes) {
          var informeContribuicoes = await FichaFinanceiraService.BuscarInformePorAno(ReferenciaContribuicoes);
          setInformeContribuicoes(informeContribuicoes);
        }
      }
      catch (e) {
        setInformeContribuicoes([]);
        const error = e["response"] ? e["response"]["data"] : e;
        setAlertText(error);
      }
      finally {
        setLoadingContribuicoes(false);
      }
    })();
  }, [ReferenciaContribuicoes]);

  async function GerarRelatorio() {
    try {
      setRodouDownloadPDF(false);
      const relatorio = await InfoRendService.RelatorioCertificado(Referencia);

      if (navigator.msSaveBlob) {
        // IE10+ : (has Blob, but not a[download] or URL)
        return navigator.msSaveBlob(
          new Blob([relatorio]),
          "Informe de Rendimentos.pdf"
        );
      }
      else {
        const url = window.URL.createObjectURL(new Blob([relatorio]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "informe de rendimentos.pdf");
        document.body.appendChild(link);

        link.click();
        URL.revokeObjectURL(url);
      }
      setRodouDownloadPDF(true);
    }
    catch (e) {
      const error = e["response"] ? e["response"]["data"] : e;
      setAlertText(
        (typeof error === "object") ?
          await error.text() :
          error
      );
    }
  };

  return (
    <MasterPage Funcionalidade={NumFuncionalidade.INFORME_DE_RENDIMENTOS_ASSISTIDOS_PENSIONISTAS} {...props}>
      {LoadingInforme && LoadingContribuicoes && <LoadingComponent />}

      {AlertText !== "" && <Alert type={"danger"}>{AlertText}</Alert>}

      <Row>
        {!LoadingInforme &&
          <Col>
            {Datas.length > 0 && <>
              <Box title={"Resumo"}>
                <div className="mb-3">
                  <ComboBox
                    value={Referencia}
                    title={"Calendário"}
                    data={Datas}
                    onChange={setReferencia}
                    memberName={"ANO_EXERCICIO_DESC_NATUREZA"}
                    memberValue={"OID_HEADER_INFO_REND"}
                  />
                </div>

                {LoadingInforme && <LoadingComponent />}

                {!LoadingInforme && Informe !== undefined &&
                  <>
                    <h4>
                      {"Ano Exercício: "}
                      <span className={"text-primary"}>
                        {Informe.ANO_EXERCICIO}
                      </span>
                    </h4>
                    <h4>
                      {"Ano Calendário: "}
                      <span className={"text-primary"}>
                        {Informe.ANO_CALENDARIO}
                      </span>
                    </h4>
                    <br />

                    {Informe!.Grupos.map((grupo: InfoRendGrupoEntidade, index: number) =>
                      <Grupo key={index} grupo={grupo} />
                    )}

                    {RodouDownloadPDF && <AvisoDownloadPDF />}

                    <Button
                      onClick={GerarRelatorio}
                      title={"Gerar Informe de Rendimentos"}
                      type={"primary"}
                      loadingText={"Baixando..."}
                      usesLoading
                    />
                  </>}
              </Box>
            </>}

            {Datas.length === 0 && <Alert type={"info"}>{"Nenhum informe de rendimentos disponível"}</Alert>}
          </Col>
        }

        {!LoadingContribuicoes &&
          <Col>
            {DatasContribuicoes.length > 0 &&
              <Box title={"Contribuições Esporádicas ou de Autopatrocínio"}>
                <p>
                  {"Esse demonstrativo relaciona as contribuições Esporádicas ou de Autopatrocinado que foram pagas pelo(a) Sr(a) e que são dedutíveis em sua Declaração de Imposto de Renda."}
                </p>

                <ComboBox
                  title={"Referência"}
                  value={ReferenciaContribuicoes}
                  data={DatasContribuicoes}
                  memberName={"ANO_REF"}
                  memberValue={"ANO_REF"}
                  onChange={setReferenciaContribuicoes}
                />

                {LoadingContribuicoes && <LoadingComponent />}

                {!LoadingContribuicoes && <div>
                  <table className={"table table-striped"}>
                    <tbody>
                      {InformeContribuicoes.map((informe, index) => {
                        return (
                          <tr key={index}>
                            <td>{informe.DES_MES_REF}</td>
                            <td className={"text-right"}>
                              <b>R$ {informe.CONTRIB_PARTICIPANTE.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <br />
                </div>
                }
              </Box>
            }

            {DatasContribuicoes.length === 0 && <Alert type={"info"}>{"Nenhum informe de contribuições disponível."}</Alert>}
          </Col>
        }
      </Row>
    </MasterPage>
  );
}
