import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Row, Col, Box, Form, Botao, Alerta, CampoTexto, TipoAlerta, TipoBotao, Abas, AbasLista } from '@intechprev/componentes-web';

import { DocumentoService, UsuarioService } from "../../services";
import { DocumentoPastaEntidade } from "../../entidades"
import { DocumentoEntidade } from "../../entidades"

import { AbaUpload } from "./AbaUpload";
import { AbaDownload } from "./AbaDownload";

import { MasterPage } from "..";
import config from '../../config.json';
import { Tabelas } from "./Tabelas";

const apiUrl = config.apiUrl;

interface Props {
    history?: any;
    match?: any;
}

interface State {
    admin: boolean;
    planos: Array<any>,
    documentos: Array<any>,
    pastas: Array<any>,
    nomePasta: string,
    nomeDocumento: string,
    arquivoUpload: string,
    podeCriarDocumento: boolean,
    oidArquivoUpload: number,
    oidPasta: number,
    pastaAtual: any,
    pastaPai: any,
    visibilidadeFileInput: boolean,
    uploadPercentage: number,
    uploading: boolean,
    documentoPastaEntidade: DocumentoPastaEntidade;
    documentoEntidade: DocumentoEntidade;
}

export class Documentos extends Component<Props, State> {
    public masterPage = React.createRef<MasterPage>();
    public formDocumento = React.createRef<Form>();
    public formPasta = React.createRef<Form>();
    public alertDocumento = React.createRef<Alerta>();
    public alertPasta = React.createRef<Alerta>();

    constructor(props: Props) {
        super(props);

        this.state = {
            admin: false,
            planos: [],
            documentos: [],
            pastas: [],
            nomePasta: "",
            nomeDocumento: "",
            arquivoUpload: "",
            podeCriarDocumento: false,
            oidArquivoUpload: 0,
            oidPasta: props.match.params.pasta,
            pastaAtual: null,
            pastaPai: "",
            visibilidadeFileInput: true,
            uploadPercentage: 0,
            uploading: false,
            documentoPastaEntidade: new DocumentoPastaEntidade(),
            documentoEntidade: new DocumentoEntidade(),
        }

        this.masterPage = React.createRef();
    }

    componentDidMount = async () => {
        await this.buscarLista();
        var admin = await UsuarioService.VerificarAdmin();
        await this.setState({
            admin
        });
        await this.masterPage.current.loading(false);
    }

    UNSAFE_componentWillReceiveProps() {
        window.location.reload();
    }

    buscarLista = async () => {
        var resultado = await DocumentoService.BuscarPorPasta(this.state.oidPasta);

        var pastaPai = "";

        if (resultado.pastaAtual && resultado.pastaAtual.OID_DOCUMENTO_PASTA_PAI)
            pastaPai = resultado.pastaAtual.OID_DOCUMENTO_PASTA_PAI;

        await this.setState({
            documentos: resultado.documentos,
            documentoEntidade: resultado.documentos,

            pastas: resultado.pastas,
            pastaAtual: resultado.pastaAtual,
            pastaPai
        });
    }
    
    render() {
        return (
            <MasterPage ref={this.masterPage} {...this.props}>
                {this.state.admin &&
                    <Abas>
                        <AbasLista iconClassName="" linkClassName="Upload">
                            <AbaUpload
                              oidPasta = {this.state.oidPasta}
                            />
                        </AbasLista>

                        <AbasLista iconClassName="" linkClassName="Dowloads">
                            <AbaDownload
                            // dados={this.state.dados}
                            />
                        </AbasLista>
                    </Abas>
                }

                {!this.state.admin &&
                    <Col tamanho={"8"}>
                        <Box>
                            {this.state.pastaAtual &&
                                <Link className={"btn btn-primary mb-4"} to={`/documentos/${this.state.pastaPai}`}>
                                    <i className={"fa fa-chevron-left mr-2"}></i>
                                    Voltar
                                </Link>
                            }

                            {(this.state.pastas.length > 0 || this.state.documentos.length > 0) &&
                                <div>
                                    <Tabelas {...this.props} itens={this.state.pastas} campoTexto={"NOM_PASTA"} icone={"fa-folder-open text-warning"} tipo={"pasta"} />
                                    <Tabelas {...this.props} itens={this.state.documentos} campoTexto={"TXT_TITULO"} icone={"fa-file text-info"} tipo={"documento"} />
                                </div>
                            }

                            {this.state.pastas.length === 0 && this.state.documentos.length === 0 &&
                                <div className="alert alert-danger">Nenhum item disponível.</div>
                            }
                        </Box>
                    </Col>
                }

            </MasterPage>
        );
    }
}