import React from 'react';
import { History } from "history";
import { Row, Col, Box, Botao, CampoTexto, Form, Alerta, TipoAlerta } from '@intechprev/componentes-web';

import { MasterPage } from "..";
import { RelacionamentoService } from "../../services";
import { EmailEntidade } from "../../entidades"


interface Props {
    history?: History;
}

interface State {
    email: string,
    assunto: string,
    mensagem: string,
    emailEntidade: EmailEntidade,
}

export class Relacionamento extends React.Component<Props, State> {
    public masterPage = React.createRef<MasterPage>();
    public alert = React.createRef<Alerta>();
    public form = React.createRef<Form>();

    state: State = {
        email: "",
        assunto: "",
        mensagem: "",
        emailEntidade: new EmailEntidade(),
    }

    componentDidMount() {
        //this.page.current.loading(false);
    }

    validar = async () => {
        await this.alert.current.limparErros();
        await this.form.current.validar();

        if(this.alert.current.state.mensagem.length === 0 && this.alert.current.props.mensagem.length === 0) {
            try { 
                //await RelacionamentoService.Enviar(this.state.email, this.state.assunto, this.state.mensagem);
                await RelacionamentoService.Enviar(this.state.emailEntidade);
                
                alert("Mensagem enviada com sucesso!");
                await this.limparCampos();
            } catch(err) {
                if(err.response)
                    alert(err.response.data);
                else
                    console.error(err);
            }
        } else {
            
        }
    }

    /**
     * @description Método que limpa os states de campo para limpar o formulário de nova mensagem.
     */
    limparCampos = () => {
        this.setState({
            email: "",
            assunto: "",
            mensagem: "",
        })
    }

    render() {
        return (
            <MasterPage ref={this.masterPage} {...this.props}>
                <Row>
                    <Col>
                        <Box>
                            <Form ref={this.form}>
                                <CampoTexto contexto={this} nome={"email"} max={50} valor={this.state.email} titulo={"Seu e-mail"} tipo={"email"} />
                                <CampoTexto contexto={this} nome={"assunto"} max={50} valor={this.state.assunto} titulo={"Assunto"} obrigatorio />
                                <CampoTexto contexto={this} nome={"mensagem"} max={4000} textarea valor={this.state.mensagem} rows={10} titulo={"Mensagem"} obrigatorio />

                                <Botao titulo={"Enviar"} submit onClick={this.validar} />
                                <br /><br />
                                <Alerta ref={this.alert} padraoFormulario tipo={TipoAlerta.danger} tamanho={"6"}/>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            </MasterPage>
        )
    }
}