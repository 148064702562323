import React, { useEffect, useState } from 'react';
import { Row, Alert, Box, Button, Col } from "@intech/web-ui";
import { PlanoService, FichaFinanceiraAssistidoService } from '@services';
import { Header } from './Header';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { Resumo } from './Resumo';
import { useHistory } from 'react-router-dom';
import { AvisoDownloadPDF, LoadingComponent, MasterPage } from '@components';
import { NumFuncionalidade } from '../../../helpers/BloqueioFuncionalidade';
import { PlanoVinculadoEntidade } from '@entidades';

interface IProps {
  match?: any;
}

export const ContrachequeDetalhe: React.FC<IProps> = ({
  match,
  ...props
}) => {
  const history = useHistory();

  const [AlertText, setAlertText] = useState<string>("");
  const [plano, setplano] = useState<PlanoVinculadoEntidade>(new PlanoVinculadoEntidade());
  const [Contracheque, setContracheque] = useState<any>(undefined);
  const [cdPlano, setcdPlano] = useState<any>(match.params.plano);
  const [dataReferencia, setdataReferencia] = useState<any>(match.params.data);
  const [cdTipoFolha, setcdTipoFolha] = useState<any>(match.params.cdTipoFolha);
  const [cdEspecie, setcdEspecie] = useState<any>(match.params.cdEspecie);
  const [RodouDownloadPDF, setRodouDownloadPDF] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const plano = await PlanoService.BuscarPorCodigo(cdPlano);
        setplano(plano);

        const contracheque = await FichaFinanceiraAssistidoService.BuscarPorDataReferencia(cdPlano, dataReferencia, cdTipoFolha, cdEspecie);
        setContracheque(contracheque);
      }
      catch (e) {
        const error = e.response ? e.response.data : e;
        console.log(error);
        setAlertText(error);
      }

    })();
  }, []);

  function Voltar() {
    history.push("/contracheque");
  }

  async function gerarRelatorio() {
    try {
      setRodouDownloadPDF(false);
      var relatorio = await FichaFinanceiraAssistidoService.Relatorio(cdPlano, dataReferencia, cdTipoFolha, cdEspecie);

      if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
        return navigator.msSaveBlob(new Blob([relatorio]), "Contracheque.pdf");
      } else {
        const url = window.URL.createObjectURL(new Blob([relatorio]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'contracheque.pdf');
        document.body.appendChild(link);
        link.click();
      }
      setRodouDownloadPDF(true);
    }
    catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
      setAlertText(error);
    }
  }

  function renderDetalhes() {
    if (Contracheque !== undefined) {
      return (
        <>
          <Col>
            <Button
              onClick={Voltar}
              title={"Voltar"}
              type={"primary"}

            />
          </Col>
          <br />

          <Header
            DS_PLANO={plano.DS_PLANO}
            DS_CATEGORIA={plano.DS_CATEGORIA}
            DS_ESPECIE={Contracheque.Proventos.length > 0 ? Contracheque.Proventos[0].DS_ESPECIE : ""}
            Resumo={Contracheque.Resumo}
          />

          <Box>
            <Row>
              <Resumo
                Rendimentos={Contracheque.Proventos}
                icon={<FaPlusCircle />}
                titulo={"Rendimentos"}
                type={"success"}
              />

              <Resumo
                Rendimentos={Contracheque.Descontos}
                icon={<FaMinusCircle />}
                titulo={"Descontos"}
                type={"danger"}
              />
            </Row>

            {RodouDownloadPDF && <AvisoDownloadPDF />}

            <Button
              onClick={gerarRelatorio}
              title={"Baixar Contracheque em PDF"}
              type={"primary"}
              loadingText={"Baixando..."}
              usesLoading
            />
          </Box>
        </>
      );
    }

    if (AlertText !== "") {
      return (
        <Alert type={"danger"}>
          {AlertText}
        </Alert>
      );
    }

    return <LoadingComponent />;
  }

  return (
    <MasterPage Funcionalidade={NumFuncionalidade.CONTRACHEQUE_ASISSTIDOS_PENSIONISTAS} {...props}>
      {renderDetalhes()}
    </MasterPage>
  );
}