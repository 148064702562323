import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PlanoVinculadoEntidade, UltimaContribuicaoEntidade, SaldoContribuicoesEntidade } from '@entidades';
import { FichaFechamentoService, FichaFinanceiraService, PlanoService } from '@services';

import { Header } from './Header';
import { UltimaContribuicaoDetalhe } from './UltimaContribuicaoDetalhe';
import { SaldoAcumulado } from './SaldoAcumulado';
import { useHistory } from 'react-router-dom';
import { buscarBloqueio, NumFuncionalidade } from "./../../../helpers/BloqueioFuncionalidade";
import { Beneficiarios } from '@components';
import { Alert, Button, Col, Row } from '@intech/web-ui';

interface IProps {
  plano: PlanoVinculadoEntidade;
}

export const Ativo: React.FC<IProps> = ({
  plano
}) => {
  const history = useHistory();

  const [AlertText, setAlertText] = useState<string>("");
  const [SRC, setSRC] = useState<number>(0);
  const [Referencia, setReferencia] = useState<string>("");
  const [TempoPlano, setTempoPlano] = useState<string>("");
  const [UltimaContribuicao, setUltimaContribuicao] = useState<UltimaContribuicaoEntidade>(new UltimaContribuicaoEntidade());
  const [Saldo, setSaldo] = useState<SaldoContribuicoesEntidade>(undefined);
  const [MotivoBloqueio, setMotivoBloqueio] = useState<string>("");

  useEffect(() => {
    (async () => {
      setMotivoBloqueio(await buscarBloqueio(NumFuncionalidade.HOME_ATIVOS_E_AUTOPATROCINADOS));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const tempoPlano = await PlanoService.BuscarTempoPlano(plano.CD_PLANO);
        setTempoPlano(tempoPlano);

        const ultimaContribuicao = await FichaFechamentoService.BuscarUltimaContribuicaoPorPlano(plano.CD_PLANO);
        setUltimaContribuicao(ultimaContribuicao);

        const salario = await FichaFinanceiraService.BuscarContribuicao(plano.CD_PLANO);;

        setSRC(salario.length > 0 ? salario[0].SRC! : 0);
        setReferencia(salario.length > 0 ? salario[0].MES_COMP + "/" + salario[0].ANO_COMP : moment().format("MM/YYYY"));

        const saldo = await FichaFechamentoService.BuscarSaldoPorPlano(plano.CD_PLANO);
        setSaldo(saldo);

      }
      catch (e) {
        const error = e.response ? e.response.data : e;
        setAlertText(error);
      }

    })();
  }, [plano]);

  if (MotivoBloqueio !== "") {
    return (
      <Alert type={"danger"}>
        {MotivoBloqueio}
      </Alert>
    );
  }

  return (
    <>
      {AlertText !== "" && <Alert type={"danger"}>
        {AlertText}
      </Alert>}

      <Header
        plano={plano}
        tempoPlano={TempoPlano}
        SRC={SRC}
        referencia={Referencia}
      />

      <Row>
        <Col>
          <Beneficiarios />
        </Col>
      </Row>

      <Row>
        <Col>
          <UltimaContribuicaoDetalhe ultimaContribuicao={UltimaContribuicao} referencia={Referencia} />
        </Col>

        <Col>
          <SaldoAcumulado saldoAcumulado={Saldo} referencia={Referencia} />
        </Col>
      </Row>

      <Button
        className={"w-100"}
        onClick={() => history.push(`/planos/${plano.CD_PLANO}`)}
        title={"Extrato de Contribuições"}
        type={"primary"}
        block
      />

      <Button
        className={"w-100"}
        onClick={() => history.push(`/planos/${plano.CD_PLANO}`)}
        title={"Certificado de Participação"}
        type={"primary"}
        block
      />


      <Button
        className={"w-100"}
        onClick={() => history.push("/simulador")}
        title={"Simulador de Benefícios"}
        type={"primary"}
        block
      />
    </>
  );
}