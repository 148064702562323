import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { CronogProcEntidade } from "@src/entidades/CronogProcEntidade";

class FichaFinanceiraAssistido extends BaseService {
  constructor() {
    super("FichaFinanceiraAssistido");
  }
  BuscarUltimaPorPlanoProcesso = (cdPlano: string, cdEspecie: string, ano: string, num: string) =>
    this.CreateRequest<any[]>(RequestType.GET, `BuscarUltimaPorPlanoProcesso/${cdPlano}/${cdEspecie}/${ano}/${num}`);

  BuscarDataPagamentoPorTipoFolhaReferencia = (TipoFolha: string, Referencia: string) =>
    this.CreateRequest<CronogProcEntidade>(RequestType.GET, `BuscarDataPagamentoPorTipoFolhaReferencia/${TipoFolha}/${Referencia}`);

  BuscarDatas = (cdPlano: string) =>
    this.CreateRequest<Array<any>>(RequestType.GET, `BuscarDatas/${cdPlano}`);

  BuscarPorDataReferencia = (cdPlano: string, referencia: string, cdTipoFolha: string, cdEspecie: string) =>
    this.CreateRequest<any>(RequestType.GET, `BuscarPorDataReferencia/${cdPlano}/${referencia}/${cdTipoFolha}/${cdEspecie}`);

  Relatorio = (cdPlano: string, referencia: string, cdTipoFolha: string, cdEspecie: string) =>
    this.CreateRequest<any>(RequestType.GET, `Relatorio/${cdPlano}/${referencia}/${cdTipoFolha}/${cdEspecie}`, null, ResponseType.Blob);

}

export const FichaFinanceiraAssistidoService = new FichaFinanceiraAssistido();
