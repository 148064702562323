import React, { ReactNode, ReactNodeArray } from "react";
import { HomeCard } from "../../../Home/HomeCard/HomeCard";
import { Col, Box, StaticField, Row } from "@intech/web-ui";
import { Blue, Red, Green } from "./styles";


interface Props {
  DS_PLANO: string;
  DS_CATEGORIA: string;
  DS_ESPECIE?: string;
  Resumo: any;
}

export const Header: React.FC<Props> = ({
  DS_PLANO,
  DS_CATEGORIA,
  DS_ESPECIE,
  Resumo
}) => {

  function ToNumber(Value: number) {
    if (Value === undefined)
      return 0;
    return (
      Value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    );
  }

  return (
    <>
      {/* <Row>
        <Col>
          <HomeCard titulo={DS_PLANO}>
            {DS_CATEGORIA}
          </HomeCard>
        </Col>

        <Col>
          <HomeCard titulo={"Tipo"}>
            {Resumo.DesTipoFolha}
          </HomeCard>
        </Col>

        {DS_ESPECIE &&
          <Col>
            <HomeCard titulo={"ESPÉCIE"}>
              {DS_ESPECIE}
            </HomeCard>
          </Col>
        }
      </Row> */}

      <Row>
        <Col>
          <HomeCard titulo={"BRUTO"}>
            <Blue>
              R$ {ToNumber(Resumo.Bruto)}
            </Blue>
          </HomeCard>
        </Col>

        <Col>
          <HomeCard titulo={"DESCONTOS"}>
            <Red>
              R$ {ToNumber(Resumo.Descontos)}
            </Red>
          </HomeCard>
        </Col>

        <Col>
          <HomeCard titulo={"LÍQUIDO"}>
            <Green>
              R$ {ToNumber(Resumo.Liquido)}
            </Green>
          </HomeCard>
        </Col>

      </Row>
    </>
  );
}
