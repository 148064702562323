import React, { Component } from "react";
import { History } from 'history';

import { SideMenuPage } from "@intech/web-ui";
import { Session } from "@intech/react-service";

import Rotas from "../Rotas";

import packageJson from "@root/package.json";
import { UsuarioService, FuncionarioService } from "../services";

interface Props {
  history?: History;
  match?: any;
  titulo?: string;
}

interface State {
  nomeUsuario: string;
  admin: boolean;
  loading: boolean;
}

export class MasterPage extends Component<Props, State> {

  state: State = {
    nomeUsuario: "",
    admin: false,
    loading: false
  }

  componentWillMount = async () => {
    try {

      var token = await Session.getToken();

      if (token) {
        var admin = await UsuarioService.VerificarAdmin();

        var dados = await FuncionarioService.Buscar();
        var nomeUsuario = dados.DadosPessoais.NOME_ENTID;

        await this.setState({
          nomeUsuario,
          admin
        });

      } else {
        await this.logout();
      }
    } catch (err) {
      if (err.message.indexOf("401") > -1) {
        await this.logout();
      } else {
        alert("Ops! Ocorreu um erro ao processar sua requisição.");
        console.error(err);
      }
    }

  }

  loading = async (valor: boolean) => {
    await this.setState({
      loading: valor
    });
  }

  logout = async () => {
    await Session.clear();
    await this.props.history.push("/login");
  }

  render() {
    return (
      <SideMenuPage
        logo={"./imagens/logo.jpg"}
        routes={Rotas}
        admin={this.state.admin}
        username={this.state.nomeUsuario}
        onLogout={this.logout}
        version={packageJson.version}
        userComponent={null}
        title={this.props.titulo}
        loading={false}
        {...this.props}>

      </SideMenuPage>
    );
  }
}