import { BaseService, RequestType, ResponseType } from "@intech/react-service";

class HistSaldo extends BaseService {
  constructor() {
    super("HistSaldo");
  }

  BuscarPorPlano = (cdPlano: string) =>
    this.CreateRequest<any>(RequestType.GET, `porPlano/${cdPlano}`);

}

export const HistSaldoService = new HistSaldo();
