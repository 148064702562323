import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { DadosPessoaisEntidade } from "../entidades/DadosPessoaisEntidade";

class DadosPessoais extends BaseService {
	constructor() {
		super("DadosPessoais");
	}

	Buscar = () =>
		this.CreateRequest<DadosPessoaisEntidade>(RequestType.GET, `Buscar`);

	Atualizar = (entidade: DadosPessoaisEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Atualizar`, entidade);

}

export const DadosPessoaisService = new DadosPessoais();
