import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { FichaFinanceiraEntidade } from "../entidades/FichaFinanceiraEntidade";
import { SaldoContribuicoesEntidade } from "../entidades/SaldoContribuicoesEntidade";
import { FichaFechamentoPrevesEntidade } from "../entidades/FichaFechamentoPrevesEntidade";

class FichaFinanceira extends BaseService {
  constructor() {
    super("FichaFinanceira");
  }

  BuscarContribuicao = (cdPlano: string) =>
    this.CreateRequest<Array<FichaFinanceiraEntidade>>(RequestType.GET, `BuscarContribuicao/${cdPlano}`);

  BuscarSaldoContribuicoes = (cdPlano: string) =>
    this.CreateRequest<FichaFinanceiraEntidade>(RequestType.GET, `BuscarSaldoContribuicoes/${cdPlano}`);

  BuscarUltimaPorPlano = (cdPlano: string) =>
    this.CreateRequest<Array<FichaFinanceiraEntidade>>(RequestType.GET, `BuscarUltimaPorPlano/${cdPlano}`);

  GetSalarioContribuicaoPorFundacaoPlano = (cdPlano: string) =>
    this.CreateRequest<any>(RequestType.GET, `GetSalarioContribuicaoPorFundacaoPlano/${cdPlano}`);

  BuscarResumoMesesPorPlanoAno = (cdPlano: string, anoRef: string) =>
    this.CreateRequest<Array<FichaFinanceiraEntidade>>(RequestType.GET, `BuscarResumoMesesPorPlanoAno/${cdPlano}/${anoRef}`);

  BuscarSaldoPorFundacaoEmpresaPlanoFundo = (cdPlano: string, cdFundo: string) =>
    this.CreateRequest<SaldoContribuicoesEntidade>(RequestType.GET, `BuscarSaldoPorFundacaoEmpresaPlanoFundo/${cdPlano}/${cdFundo}`);

  GetResumoAnosPorPlano = (cdPlano: string) =>
    this.CreateRequest<Array<FichaFechamentoPrevesEntidade>>(RequestType.GET, `GetResumoAnosPorPlano/${cdPlano}`);

  BuscarTiposPorFundacaoPlanoAnoMes = (cdPlano: string, anoRef: string, mesRef: string) =>
    this.CreateRequest<Array<FichaFechamentoPrevesEntidade>>(RequestType.GET, `BuscarTiposPorFundacaoPlanoAnoMes/${cdPlano}/${anoRef}/${mesRef}`);

  BuscarDatasInforme = () =>
    this.CreateRequest<Array<FichaFinanceiraEntidade>>(RequestType.GET, `BuscarDatasInforme`);

  BuscarInformePorAno = (ano: string) =>
    this.CreateRequest<Array<FichaFinanceiraEntidade>>(RequestType.GET, `BuscarInformePorAno/${ano}`);

}

export const FichaFinanceiraService = new FichaFinanceira();
