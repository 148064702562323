import React, { Component, FormEvent } from "react";
import { History } from "history";
import {
    Row, Col, Box, Alerta, TipoAlerta, CampoTexto, PosicaoTituloCampoTexto, Abas, AbasLista, Combo,
    PosicaoTituloCombo, Botao, TipoBotao, Form, ModalConfirma
} from "@intechprev/componentes-web";

import { MasterPage } from "..";
import {
    FuncionarioService, GrauInstrucaoService, SexoService, EstadoCivilService, GrauParentescoService,
    EntidadeService, DadosPessoaisService, PlanoService, BancoAgenciaService
} from "../../services";

import {
    FuncionarioDados, DadosPessoaisEntidade, EntidadeEntidade, GrauInstrucaoEntidade, GrauParentescoEntidade,
    SexoEntidade, EstadoCivilEntidade, PlanoVinculadoEntidade, LogSMSEntidade, BancoAgEntidade
} from "../../entidades";

import { Dependentes } from "./Dependentes";
import { DocumentosUpload } from "./DocumentosUpload";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


interface Props {
    history?: History;
}

interface State {
    dados: FuncionarioDados;
    dadosPessoais: DadosPessoaisEntidade;
    dadosEntidade: EntidadeEntidade;
    plano: PlanoVinculadoEntidade,
    dadosGrauInstrucao: Array<GrauInstrucaoEntidade>,
    dadosGrauParentesco: Array<GrauParentescoEntidade>,
    dadosSexo: Array<SexoEntidade>,
    dadosEstadoCivil: Array<EstadoCivilEntidade>,
    dadosBanco: Array<BancoAgEntidade>,
    dadosAgencia:  Array<BancoAgEntidade>,
    isPesquisando: boolean,
    modalConfirmaSalvar: boolean,
    cdPlano: string;
}

export class AlteracaoCadastral extends Component<Props, State> {
    public masterPage = React.createRef<MasterPage>();

    private alert = React.createRef<Alerta>();
    private form = React.createRef<Form>();

    state: State = {
        dados: new FuncionarioDados(),
        dadosPessoais: new DadosPessoaisEntidade(),
        dadosEntidade: new EntidadeEntidade(),
        plano: new PlanoVinculadoEntidade(),
        dadosGrauInstrucao: new Array<GrauInstrucaoEntidade>(),
        dadosGrauParentesco: new Array<GrauParentescoEntidade>(),
        dadosSexo: new Array<SexoEntidade>(),
        dadosEstadoCivil: new Array<EstadoCivilEntidade>(),
        dadosBanco: new Array<BancoAgEntidade>(),
        dadosAgencia: new Array<BancoAgEntidade>(),
        modalConfirmaSalvar: false,
        isPesquisando: true,
        cdPlano: "",
    }

    componentDidMount = async () => {
        await this.buscarPlanos();
        await this.buscar();
    }

    // buscar os planos do participante, se houver mais de um, mostra-los no combo para que o participante possa escolher
    buscarPlanos = async () => {
        try {
            var listaPlanos = await PlanoService.Buscar();

            var plano = listaPlanos[0];
            this.setState({ cdPlano: plano.CD_PLANO });

        } catch (err) {
            console.error(err);
        }
    }


    buscar = async () => {
        var dados = await FuncionarioService.Buscar();
        var sexo = await SexoService.BuscaTodos();
        var grauInstrucao = await GrauInstrucaoService.BuscarTodos();
        var estadoCivil = await EstadoCivilService.BuscarTodos();
        var grauParentesco = await GrauParentescoService.BuscarTodos();
        var plano = await PlanoService.BuscarPorCodigo(this.state.cdPlano);
        var bancos = await BancoAgenciaService.BuscarBancos();

        await this.setState({
            dados,
            dadosPessoais: dados.DadosPessoais,
            dadosEntidade: dados.Entidade,
            dadosGrauInstrucao: grauInstrucao,
            dadosSexo: sexo,
            dadosEstadoCivil: estadoCivil,
            dadosGrauParentesco: grauParentesco,
            plano: plano,
            dadosBanco: bancos,
            isPesquisando: false
        });
        
        this.ObterAgencias();
    }

    ObterAgencias = async () => {
        var agencias = await BancoAgenciaService.BuscarAgenciaDoBanco(this.state.dadosEntidade.NUM_BANCO);

        await this.setState({ dadosAgencia: agencias})
    }

    salvar = async () => {
        try {
            EntidadeService.Atualizar(this.state.dadosEntidade);
            DadosPessoaisService.Atualizar(this.state.dadosPessoais);

            toast.success("Registro Salvo com sucesso!");
        }
        catch {
            toast.success("Erro ao tentar salvar!");
        }
    }

    validar = async () => {
        await this.alert.current.limparErros();
        await this.form.current.validar();

        if (this.form.current.state.valido) {
            
            await this.setState({ modalConfirmaSalvar: !this.state.modalConfirmaSalvar });
            
        }
    }

    cancelaSalvar = async () => {
        await this.setState({ modalConfirmaSalvar: !this.state.modalConfirmaSalvar });
        toast.info("Alteração cancelada!");
    }

    confirmaSalvar = async () => {
        await this.setState({ modalConfirmaSalvar: !this.state.modalConfirmaSalvar });
        this.salvar();
    }

    render() {
        return (
            <MasterPage ref={this.masterPage} {...this.props}>
                {!this.state.isPesquisando &&
                    <>
                        {this.state.plano.CD_CATEGORIA === "2" &&
                            <Box>
                                <div id="pesquisando" className="alert alert-warning">
                                    <h4>Alteração cadastral não disponível para a categoria DESLIGADO!</h4>
                                </div>
                            </Box>
                        }

                        {this.state.plano.CD_CATEGORIA !== "2" &&
                            <>
                                <Row>
                                    <Col tamanho={"12"} className={"col-lg-12"}>
                                        <Abas>
                                            <AbasLista iconClassName="" linkClassName="Dados Pessoais">
                                                <Form ref={this.form}>
                                                    <div className="tab-content" >
                                                        <Box>
                                                            {/* <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"NOME_ENTID"}
                                                                        obrigatorio
                                                                        valor={this.state.dadosEntidade.NOME_ENTID}
                                                                        titulo={"Nome"}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row> */}

                                                            <Row>
                                                                {/* <Col>
                                                                    <Combo
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"SEXO"}
                                                                        titulo={"Sexo"}
                                                                        obrigatorio
                                                                        valor={this.state.dadosPessoais.SEXO}
                                                                        padrao={this.state.dadosPessoais.SEXO}
                                                                        opcoes={this.state.dadosSexo}
                                                                        nomeMembro={"DS_SEXO"}
                                                                        valorMembro={"CD_SEXO"}
                                                                        posicaoTitulo={PosicaoTituloCombo.cima}
                                                                        tituloClassName={"text-primary"}
                                                                    />
                                                                </Col> */}
                                                                <Col>
                                                                    <Combo
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"CD_ESTADO_CIVIL"}
                                                                        titulo={"Estado Civil"}
                                                                        obrigatorio={false}
                                                                        valor={this.state.dadosPessoais.CD_ESTADO_CIVIL}
                                                                        padrao={this.state.dadosPessoais.CD_ESTADO_CIVIL}
                                                                        opcoes={this.state.dadosEstadoCivil}
                                                                        nomeMembro={"DS_ESTADO_CIVIL"}
                                                                        valorMembro={"CD_ESTADO_CIVIL"}
                                                                        posicaoTitulo={PosicaoTituloCombo.cima}
                                                                        tituloClassName={"text-primary"}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Combo
                                                                        contexto={this}
                                                                        parent="dadosGrauInstrucao"
                                                                        nome={"CD_GRAU_INSTRUCAO"}
                                                                        titulo={"Grau Instrução"}
                                                                        obrigatorio={false}
                                                                        valor={this.state.dadosPessoais.CD_GRAU_INSTRUCAO}
                                                                        opcoes={this.state.dadosGrauInstrucao}
                                                                        nomeMembro={"DS_GRAU_INSTRUCAO"}
                                                                        valorMembro={"CD_GRAU_INSTRUCAO"}
                                                                        posicaoTitulo={PosicaoTituloCombo.cima}
                                                                        tituloClassName={"text-primary"}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {/* <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"NU_IDENT"}
                                                                        obrigatorio
                                                                        titulo="RG"
                                                                        valor={this.state.dadosPessoais.NU_IDENT}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"ORG_EMIS_IDENT"}
                                                                        obrigatorio
                                                                        titulo="Órgão Emissor RG"
                                                                        valor={this.state.dadosPessoais.ORG_EMIS_IDENT}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row> */}

                                                            <Row>
                                                                {/* <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"NATURALIDADE"}
                                                                        valor={this.state.dadosPessoais.NATURALIDADE.toString()}
                                                                        titulo={"Naturalidade"}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col> */}
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dados"
                                                                        nome={"FONE_ENTID"}
                                                                        titulo="Fone Fixo"
                                                                        valor={this.state.dadosEntidade.FONE_ENTID}
                                                                        tamanhoCampo="12"
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"FONE_CELULAR"}
                                                                        titulo="Celular"
                                                                        valor={this.state.dadosPessoais.FONE_CELULAR}
                                                                        tamanhoCampo="12"
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {/* <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"NOME_PAI"}
                                                                        obrigatorio
                                                                        titulo="Nome do Pai"
                                                                        valor={this.state.dadosPessoais.NOME_PAI}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"NOME_MAE"}
                                                                        obrigatorio
                                                                        titulo="Nome da Mãe"
                                                                        valor={this.state.dadosPessoais.NOME_MAE}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row> */}

                                                            <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"NOME_CONJUGE"}
                                                                        titulo="Conjuge"
                                                                        valor={this.state.dadosPessoais.NOME_CONJUGE}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"CPF_CONJUGE"}
                                                                        titulo="Cpf Conjuge"
                                                                        valor={this.state.dadosPessoais.CPF_CONJUGE}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosPessoais"
                                                                        nome={"EMAIL_AUX"}
                                                                        obrigatorio
                                                                        titulo="Email"
                                                                        valor={this.state.dadosPessoais.EMAIL_AUX}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Box>

                                                        <Box titulo="Endereço Residencial">
                                                            <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"END_ENTID"}
                                                                        obrigatorio
                                                                        titulo="Endereço"
                                                                        valor={this.state.dadosEntidade.END_ENTID}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"NR_END_ENTID"}
                                                                        obrigatorio
                                                                        titulo="Número"
                                                                        valor={this.state.dadosEntidade.NR_END_ENTID}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"COMP_END_ENTID"}
                                                                        titulo="Complemento"
                                                                        valor={this.state.dadosEntidade.COMP_END_ENTID}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima} />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"BAIRRO_ENTID"}
                                                                        obrigatorio
                                                                        titulo="Bairro"
                                                                        valor={this.state.dadosEntidade.BAIRRO_ENTID}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"CID_ENTID"}
                                                                        obrigatorio
                                                                        titulo="Cidade"
                                                                        valor={this.state.dadosEntidade.CID_ENTID}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"UF_ENTID"}
                                                                        obrigatorio
                                                                        titulo="UF"
                                                                        valor={this.state.dadosEntidade.UF_ENTID}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"CEP_ENTID"}
                                                                        obrigatorio
                                                                        titulo="Cep"
                                                                        valor={this.state.dadosEntidade.CEP_ENTID}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Box>
                                                        <Box titulo="Dados Bancários">
                                                            <Row>
                                                                <Col>
                                                                    <Combo
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"NUM_BANCO"}
                                                                        titulo={"Banco"}
                                                                        obrigatorio
                                                                        valor={this.state.dadosEntidade.NUM_BANCO}
                                                                        padrao={this.state.dadosEntidade.NUM_BANCO}
                                                                        opcoes={this.state.dadosBanco}
                                                                        nomeMembro={"DESC_BCO_AG"}
                                                                        valorMembro={"COD_BANCO"}
                                                                        posicaoTitulo={PosicaoTituloCombo.cima}
                                                                        tituloClassName={"text-primary"}
                                                                        onChange={this.ObterAgencias}
                                                                    />        
                                                                </Col>
                                                                <Col>
                                                                    <Combo
                                                                        contexto={this}
                                                                        parent="dadosEntidade"
                                                                        nome={"NUM_AGENCIA"}
                                                                        titulo={"Agência"}
                                                                        obrigatorio
                                                                        valor={this.state.dadosEntidade.NUM_AGENCIA}
                                                                        padrao={this.state.dadosEntidade.NUM_AGENCIA}
                                                                        opcoes={this.state.dadosAgencia}
                                                                        nomeMembro={"COD_AGENC"}
                                                                        valorMembro={"COD_AGENC"}
                                                                        posicaoTitulo={PosicaoTituloCombo.cima}
                                                                        tituloClassName={"text-primary"}
                                                                    />                                                                  
                                                                </Col>
                                                                <Col>
                                                                    <CampoTexto
                                                                        contexto={this}
                                                                        nome={"NUM_CONTA"}
                                                                        parent="dadosEntidade"
                                                                        obrigatorio
                                                                        titulo="Conta"
                                                                        valor={this.state.dadosEntidade.NUM_CONTA}
                                                                        tituloClassName={"text-primary"}
                                                                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Box>

                                                    </div>

                                                    <br />
                                                    <Alerta ref={this.alert} tipo={TipoAlerta.danger} padraoFormulario />
                                                    <Botao onClick={this.validar} titulo={"Salvar"} submit />
                                                </Form>

                                            </AbasLista>

                                            <AbasLista iconClassName="" linkClassName="Dependentes">
                                                <Dependentes
                                                    dadosSexo={this.state.dadosSexo}
                                                    dadosEstadoCivil={this.state.dadosEstadoCivil}
                                                    dadosGrauParentesco={this.state.dadosGrauParentesco}
                                                    dados={this.state.dados}
                                                />
                                            </AbasLista>
                                            <AbasLista iconClassName="" linkClassName="Documentos">
                                                <DocumentosUpload
                                                    dados={this.state.dados}
                                                />
                                            </AbasLista>
                                        </Abas>
                                    </Col>

                                    <ModalConfirma
                                        onClose={this.cancelaSalvar}
                                        onConfirm={this.confirmaSalvar}
                                        visivel={this.state.modalConfirmaSalvar}
                                        titulo={"Atenção!"} >
                                        Declaro para os devidos fins de direito que os dados fornecidos foram por mim inseridos no “Portal do Participante da PREVES atestando veracidade, 
                                        sob pena de responder civil e criminalmente e autorizo a PREVES utiliza-los nas formas da Lei Geral de Proteção de Dados – LGPD. ?
                                    </ModalConfirma>
                                    <ToastContainer />

                                </Row>

                            </>
                        }
                    </>
                }

                {this.state.isPesquisando &&
                    <div id="pesquisando" className="alert alert-info">Aguarde processando...</div>
                }
            </MasterPage>
        );
    }
}