import React from "react";
import { Badge, Button } from "@intech/web-ui";
import { FuncionarioEntidade, LoginEntidade } from "@entidades";
import { UsuarioService, FuncionarioService } from "@services";
import { Session } from "@intech/react-service";
import { useHistory } from "react-router-dom";

interface IProps {
  Funcionario: FuncionarioEntidade;
  Empresa: string;
}

export const ResultadoItem: React.FC<IProps> = ({
  Funcionario,
  Empresa
}) => {
  const history = useHistory();

  const Pensionista = Funcionario.PENSIONISTA === "S";

  async function Selecionar(cpf: string, inscricao: string) {
    try {
      let loginInfo = new LoginEntidade();
      loginInfo.Cpf = cpf;
      loginInfo.Inscricao = inscricao;
      const login = await UsuarioService.SelecionarParticipante(loginInfo);

      await Session.setToken(login.AccessToken, true);

      var dados = await FuncionarioService.Buscar();

      history.push("/");
    }
    catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
    }
  };

  return (
    <tr>
      <td>
        {Pensionista && (
          <Badge type="success">
            PENSIONISTA
          </Badge>
        )}
      </td>
      <td>
        {Funcionario.NOME_ENTID}
      </td>
      <td>
        {Funcionario.NUM_MATRICULA}
      </td>
      <td>
        {Funcionario.CPF_CGC}
      </td>
      <td>
        {`${Funcionario.CD_EMPRESA} - ${Empresa}`}
      </td>
      <td>
        {Funcionario.CD_PLANO}
      </td>
      <td className={"justify-content-center align-self-center"}>
        <Button
          type={"primary"}
          onClick={async () => await Selecionar(Funcionario.CPF_CGC, Funcionario.NUM_INSCRICAO)}
          title={"Selecionar"}
          loadingText={"Selecionando..."}
          usesLoading
        />

      </td>
    </tr>
  );
}
