import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { SitPlanoEntidade } from "../entidades/SitPlanoEntidade";

class SitPlano extends BaseService {
	constructor() {
		super("SitPlano");
	}

	BuscarTodos = () =>
		this.CreateRequest<Array<SitPlanoEntidade>>(RequestType.GET, ``);

}

export const SitPlanoService = new SitPlano();
