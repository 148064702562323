import React, { Component, FormEvent } from "react";

import _ from "lodash";

import { FooterBar } from "./../FooterBar";

import textos from "./../Textos.json";
import { Button, Col, Modal, Row } from "@intech/web-ui";
import NumberFormat from "react-number-format";
import { FaQuestion } from "react-icons/fa";

import { ModalHolder } from "../styles";

interface Props {
  Dados: any;
  proximoPasso: () => void;
  passoAnterior: () => void;
}

interface State {
  percentualBasicoParticipante: number;
  percentualBasicoPatrocinador: number;
  percentualBasicoParticipanteMinimo: number;
  percentualBasicoParticipanteMaximo: number;

  beneficioRiscoInvalidezParticipante: number;
  beneficioRiscoInvalidezPatrocinador: number;
  beneficioRiscoInvalidezParticipanteMinimo: number;
  beneficioRiscoInvalidezParticipanteMaximo: number;

  beneficioRiscoMorteParticipante: number;
  beneficioRiscoMortePatrocinador: number;
  beneficioRiscoMorteParticipanteMinimo: number;
  beneficioRiscoMorteParticipanteMaximo: number;

  beneficioRiscoSoma: number;

  aposentadoriaAdicional: number;
  aposentadoriaAdicional2: number;
  invalidezAdicional: number;
  pensaoMorteAdicional: number;
  sobrevivenciaAdicional: number;
  carregamentoContribuicaoAdicional: number;
  totalizadorTaxaAposentadoria: number;
  totalizadorTaxaContribuicao: number;

  sobrevivenciaParticipante: number;
  sobrevivenciaPatrocinador: number;
  sobrevivenciaMaxima: number;
  carregamentoContribuicaoBasicaParticipante: number;
  carregamentoContribuicaoBasicaPatrocinador: number;
  dataNascimento: Date;

  tipoAtivo: string;

  modalStatus: boolean;
  textoModal: any;

  comboInvalidez: [];
}

export class Passo3 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      percentualBasicoParticipante: this.props.Dados.percentualBasicoParticipante,
      percentualBasicoPatrocinador: 0,
      percentualBasicoParticipanteMinimo: this.props.Dados.percentualBasicoParticipanteMinimo,
      percentualBasicoParticipanteMaximo: this.props.Dados.percentualBasicoParticipanteMaximo,

      beneficioRiscoInvalidezParticipante: this.props.Dados.beneficioRiscoInvalidezParticipante,
      beneficioRiscoInvalidezPatrocinador: 0,
      beneficioRiscoInvalidezParticipanteMinimo: this.props.Dados.beneficioRiscoInvalidezParticipanteMinimo,
      beneficioRiscoInvalidezParticipanteMaximo: this.props.Dados.beneficioRiscoInvalidezParticipanteMaximo,

      beneficioRiscoMorteParticipante: this.props.Dados.beneficioRiscoMorteParticipante,
      beneficioRiscoMortePatrocinador: 0,
      beneficioRiscoMorteParticipanteMinimo: this.props.Dados.beneficioRiscoMorteParticipanteMinimo,
      beneficioRiscoMorteParticipanteMaximo: this.props.Dados.beneficioRiscoMorteParticipanteMaximo,

      beneficioRiscoSoma: this.props.Dados.beneficioRiscoSoma,

      aposentadoriaAdicional: this.props.Dados.aposentadoriaAdicional,
      aposentadoriaAdicional2: this.props.Dados.aposentadoriaAdicional2,
      invalidezAdicional: this.props.Dados.invalidezAdicional,
      pensaoMorteAdicional: this.props.Dados.pensaoMorteAdicional,
      sobrevivenciaAdicional: this.props.Dados.sobrevivenciaAdicional,
      carregamentoContribuicaoAdicional: this.props.Dados.carregamentoContribuicaoAdicional,
      totalizadorTaxaAposentadoria: this.props.Dados.totalizadorTaxaAposentadoria,
      totalizadorTaxaContribuicao: this.props.Dados.totalizadorTaxaContribuicao,

      sobrevivenciaParticipante: this.props.Dados.sobrevivenciaParticipante,
      sobrevivenciaPatrocinador: 0,
      carregamentoContribuicaoBasicaParticipante: this.props.Dados.carregamentoContribuicaoBasicaParticipante,
      carregamentoContribuicaoBasicaPatrocinador: 0,
      dataNascimento: this.props.Dados.dataNascimento,

      tipoAtivo: this.props.Dados.tipoAtivo,
      sobrevivenciaMaxima: 0,

      modalStatus: false,
      textoModal: "",

      comboInvalidez: [],
    };
  }

  componentDidMount = async () => {
    //await this.handleRiscoInvalidezChange(this.state.beneficioRiscoMorteParticipante);
    //await this.handleRiscoMorteChange(this.state.beneficioRiscoInvalidezParticipante);

    await this.handlePercentualBasicoChange(this.state.percentualBasicoParticipante);
    await this.valorSobrevivencia(this.props.Dados.tempoSobrevivencia);
  };

  renderContribuicaoPatrocinador(valor: string) {
    if (this.state.tipoAtivo === "A") return valor.replace(".", ",");

    if (this.state.tipoAtivo !== "A") {
      return "0,00";
    }
  }

  handleSobrevivenciaSlideChange = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);

    this.setState({ sobrevivenciaParticipante: valor });

    this.calculaContribuicaoAdicional();
  };

  handleOnChangeRiscoInvalidez = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);

    this.setState({ beneficioRiscoInvalidezParticipante: valor });

    this.handleRiscoInvalidezChange(valor);
  };

  handleRiscoInvalidezChange(valor: number): void {
    var numero = 0;
    if (valor == 0) numero = 1;
    else {
      numero = 1 - valor;
      numero = this.arredondar(numero, 2, 0);
    }

    var beneficioRiscoInvalidezPatrocinador = 0;
    if (this.state.tipoAtivo === "A") {
      beneficioRiscoInvalidezPatrocinador = this.state.beneficioRiscoInvalidezParticipante;
    }

    this.setState(
      {
        beneficioRiscoMorteParticipanteMaximo: numero,
        beneficioRiscoSoma: valor + numero,
        beneficioRiscoInvalidezPatrocinador: beneficioRiscoInvalidezPatrocinador,
      },
      () => {
        this.calculaContribuicaoAdicional();
      }
    );
  }

  handleOnChangeRiscoMorte = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);

    this.setState({ beneficioRiscoMorteParticipante: valor });

    this.handleRiscoMorteChange(valor);
  };

  handleRiscoMorteChange(valor: number): void {
    var numero = 0;
    if (valor == 0) numero = 1;
    else {
      numero = 1 - valor;
      numero = this.arredondar(numero, 2, 0);
    }

    var beneficioRiscoMortePatrocinador = 0;

    if (this.state.tipoAtivo === "A") {
      beneficioRiscoMortePatrocinador = this.state.beneficioRiscoMorteParticipante;
    }

    this.setState(
      {
        beneficioRiscoMorteParticipante: valor,
        beneficioRiscoInvalidezParticipanteMaximo: numero,
        beneficioRiscoSoma: valor + numero,
        beneficioRiscoMortePatrocinador: beneficioRiscoMortePatrocinador,
      },
      () => {
        this.calculaContribuicaoAdicional();
      }
    );
  }

  valorSobrevivencia(valor: number): void {
    var numero = 0;

    if (valor > 0) numero = 1;

    var sobrevivenciaPatrocinador = 0;

    if (this.state.tipoAtivo === "A") {
      sobrevivenciaPatrocinador = this.state.sobrevivenciaParticipante;
    }

    this.setState({ sobrevivenciaMaxima: numero, sobrevivenciaPatrocinador: sobrevivenciaPatrocinador });
  }

  arredondaValor(valor: number): number {
    var fator = Math.pow(10, 2);
    var numeroaux = valor * fator;
    var numeroArredondado = Math.round(numeroaux) / fator;

    return numeroArredondado;
  }

  arredondar(valor: number, casas: number, ceilOrFloor: number): number {
    var arredondado = valor;
    arredondado *= Math.pow(10, casas);
    if (ceilOrFloor == 0) {
      arredondado = Math.ceil(arredondado);
    } else {
      arredondado = Math.floor(arredondado);
    }
    arredondado /= Math.pow(10, casas);
    return arredondado;
  }

  handlePercentualBasicoSlideChange = (event: FormEvent<HTMLInputElement>) => {
    var valor = parseFloat(event.currentTarget.value);

    this.setState({ percentualBasicoParticipante: valor });

    this.handlePercentualBasicoChange(valor);
  };

  handlePercentualBasicoChange = async (valor: number) => {
    var carregamentoContribuicaoBasicaPatrocinador = 0;
    var percentualBasicoPatrocinador = 0;
    var carregamentoContribuicaoBasicaParticipante = valor * 0.06;

    if (this.state.tipoAtivo === "A") {
      carregamentoContribuicaoBasicaPatrocinador = this.state.carregamentoContribuicaoBasicaParticipante;
      percentualBasicoPatrocinador = this.state.percentualBasicoParticipante;
    }

    await this.setState({
      carregamentoContribuicaoBasicaParticipante: carregamentoContribuicaoBasicaParticipante,
      carregamentoContribuicaoBasicaPatrocinador: carregamentoContribuicaoBasicaPatrocinador,
      percentualBasicoPatrocinador: percentualBasicoPatrocinador,
    });

    this.calculaContribuicaoAdicional();
  };

  calculaContribuicaoAdicional() {
    var aposentadoriaAdicional = this.state.aposentadoriaAdicional;
    var invalidezAdicional = this.state.invalidezAdicional;
    var pensaoMorteAdicional = this.state.pensaoMorteAdicional;
    var sobrevivenciaAdicional = this.state.sobrevivenciaAdicional;

    // Checa se o número de entrada é NaN. Caso sim, o valor de entrada torna-se '0' para realização do cálculo.
    aposentadoriaAdicional = this.pegarValorValido(aposentadoriaAdicional);

    invalidezAdicional = this.pegarValorValido(invalidezAdicional);

    pensaoMorteAdicional = this.pegarValorValido(pensaoMorteAdicional);

    sobrevivenciaAdicional = this.pegarValorValido(sobrevivenciaAdicional);

    var soma = aposentadoriaAdicional + invalidezAdicional + pensaoMorteAdicional + sobrevivenciaAdicional;
    soma *= 0.06;

    this.setState(
      {
        carregamentoContribuicaoAdicional: soma,
      },
      () => {
        this.calculaTaxaContribuicao();
        this.calculaTaxaAposentadoria();
      }
    );
  }

  pegarValorValido(valor: number | string) {
    if (typeof valor === "string") {
      valor = valor.replace(".", "").replace(",", ".");
      valor = Number(valor);
    }
    if (isNaN(valor)) return 0;

    return valor;
  }

  calculaTaxaAposentadoria() {
    var percentualBasico = this.state.percentualBasicoParticipante;
    var invalidez = this.state.beneficioRiscoInvalidezParticipante;
    var pensaoMorte = this.state.beneficioRiscoMorteParticipante;
    var sobrevivencia = this.state.sobrevivenciaParticipante;
    var carregamentoContribuicaoBasica = this.state.carregamentoContribuicaoBasicaParticipante;
    var aposentadoriaAdicional = this.pegarValorValido(this.state.aposentadoriaAdicional);

    if (isNaN(aposentadoriaAdicional)) aposentadoriaAdicional = 0;

    var totalizadorTaxaAposentadoria = 0;

    totalizadorTaxaAposentadoria =
      percentualBasico - invalidez - pensaoMorte - sobrevivencia - carregamentoContribuicaoBasica + aposentadoriaAdicional * 0.94;

    var numeroArredondado = this.arredondaValor(totalizadorTaxaAposentadoria);

    this.setState({
      totalizadorTaxaAposentadoria: numeroArredondado,
    });
  }

  calculaTaxaContribuicao() {
    var totalizadorTaxaContribuicao = 0;
    var percentualBasico = this.pegarValorValido(this.state.percentualBasicoParticipante);
    // var beneficioRiscoParticipante = this.pegarValorValido(this.state.beneficioRiscoInvalidezParticipante);
    // var beneficioRiscoMorteParticipante = this.pegarValorValido(this.state.beneficioRiscoMorteParticipante);
    // var sobrevivenciaParticipante = this.pegarValorValido(this.state.sobrevivenciaParticipante);

    var percentualBasicoPatrocinador = this.pegarValorValido(this.state.percentualBasicoPatrocinador);
    // var beneficioRiscoInvalidezPatrocinador = this.pegarValorValido(this.state.beneficioRiscoInvalidezPatrocinador);
    // var beneficioRiscoMortePatrocinador = this.pegarValorValido(this.state.beneficioRiscoMortePatrocinador);
    // var sobrevivenciaPatrocinador = this.pegarValorValido(this.state.sobrevivenciaPatrocinador);

    var aposentadoriaAdicional = this.pegarValorValido(this.state.aposentadoriaAdicional);
    var invalidezAdicional = this.pegarValorValido(this.state.invalidezAdicional);
    var pensaoMorteAdicional = this.pegarValorValido(this.state.pensaoMorteAdicional);
    var sobrevivenciaAdicional = this.pegarValorValido(this.state.sobrevivenciaAdicional);

    totalizadorTaxaContribuicao =
      percentualBasico + //beneficioRiscoParticipante + beneficioRiscoMorteParticipante + sobrevivenciaParticipante +
      percentualBasicoPatrocinador + //beneficioRiscoInvalidezPatrocinador + beneficioRiscoMortePatrocinador + sobrevivenciaPatrocinador +
      aposentadoriaAdicional +
      invalidezAdicional +
      pensaoMorteAdicional +
      sobrevivenciaAdicional;

    var numeroArredondado = this.arredondaValor(totalizadorTaxaContribuicao);

    this.setState({
      totalizadorTaxaContribuicao: numeroArredondado,
    });
  }

  verificarValorMaximo(campo: number, valorMaximo: number) {
    var valor = campo;

    var resultado = valor !== valorMaximo;

    return resultado;
  }

  verificarValorAposentadoriaAdicional(campo: number, valorMaximo: number) {
    var resultado = false;
    var valor = campo;

    if (this.props.Dados.tipoAtivo === "A" && (valor = valorMaximo)) resultado = true;

    return resultado;
  }

  desabilitaCamposSobrevivencia() {
    var campoDesabilitado = this.verificarValorMaximo(this.state.sobrevivenciaParticipante, 1);
    return campoDesabilitado;
  }

  proximo = () => {
    this.atualizaDados();

    // metódo do formulario Pai
    this.props.proximoPasso();
  };

  anterior = () => {
    // metódo do formulario Pai
    this.props.passoAnterior();
  };

  atualizaDados() {
    this.props.Dados.percentualBasicoParticipante = this.state.percentualBasicoParticipante;
    this.props.Dados.percentualBasicoParticipanteMinimo = this.state.percentualBasicoParticipanteMinimo;
    this.props.Dados.percentualBasicoParticipanteMaximo = this.state.percentualBasicoParticipanteMaximo;
    this.props.Dados.beneficioRiscoInvalidezParticipante = this.state.beneficioRiscoInvalidezParticipante;
    this.props.Dados.beneficioRiscoInvalidezParticipanteMinimo = this.state.beneficioRiscoInvalidezParticipanteMinimo;
    this.props.Dados.beneficioRiscoInvalidezParticipanteMaximo = this.state.beneficioRiscoInvalidezParticipanteMaximo;
    this.props.Dados.beneficioRiscoMorteParticipante = this.state.beneficioRiscoMorteParticipante;
    this.props.Dados.beneficioRiscoMorteParticipanteMinimo = this.state.beneficioRiscoMorteParticipanteMinimo;
    this.props.Dados.beneficioRiscoMorteParticipanteMaximo = this.state.beneficioRiscoMorteParticipanteMaximo;
    this.props.Dados.beneficioRiscoSoma = this.state.beneficioRiscoSoma;
    this.props.Dados.aposentadoriaAdicional = this.pegarValorValido(this.state.aposentadoriaAdicional);
    this.props.Dados.aposentadoriaAdicional2 = this.pegarValorValido(this.state.aposentadoriaAdicional2);
    this.props.Dados.invalidezAdicional = this.pegarValorValido(this.state.invalidezAdicional);
    this.props.Dados.pensaoMorteAdicional = this.pegarValorValido(this.state.pensaoMorteAdicional);
    this.props.Dados.sobrevivenciaAdicional = this.pegarValorValido(this.state.sobrevivenciaAdicional);
    this.props.Dados.carregamentoContribuicaoAdicional = this.state.carregamentoContribuicaoAdicional;
    this.props.Dados.totalizadorTaxaAposentadoria = this.state.totalizadorTaxaAposentadoria;
    this.props.Dados.totalizadorTaxaContribuicao = this.state.totalizadorTaxaContribuicao;
    this.props.Dados.sobrevivenciaParticipante = this.state.sobrevivenciaParticipante;
    this.props.Dados.carregamentoContribuicaoBasicaParticipante = this.state.carregamentoContribuicaoBasicaParticipante;

    this.props.Dados.percentualBasicoPatrocinador = this.state.percentualBasicoPatrocinador;
    this.props.Dados.beneficioRiscoInvalidezPatrocinador = this.state.beneficioRiscoInvalidezPatrocinador;
    this.props.Dados.beneficioRiscoMortePatrocinador = this.state.beneficioRiscoMortePatrocinador;
    this.props.Dados.sobrevivenciaPatrocinador = this.state.sobrevivenciaPatrocinador;
    this.props.Dados.carregamentoContribuicaoBasicaPatrocinador = this.state.carregamentoContribuicaoBasicaPatrocinador;
  }

  toggleModal = (texto: any) => {
    var textoModal = texto;

    const body = document.querySelector("body");
    if (!this.state.modalStatus) {
      body.classList.add("modal-open");
    } else {
      body.classList.remove("modal-open");
    }

    this.setState({
      modalStatus: !this.state.modalStatus,
      textoModal: textoModal,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Row>
            <Col>
              <h2 className="text-center">Ótimo! Agora vamos definir os percentuais de cada contribuição.</h2>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5 className={"mt-5 mb-2"}>Percentual Básico Participante</h5>
              <div className="islider">
                <input
                  type="range"
                  id={"percBasico"}
                  name={"percBasico"}
                  min={this.state.percentualBasicoParticipanteMinimo}
                  max={this.state.percentualBasicoParticipanteMaximo}
                  step={0.5}
                  value={this.state.percentualBasicoParticipante}
                  className="slider"
                  onChange={this.handlePercentualBasicoSlideChange}
                />
                <div className="value">&nbsp; {this.state.percentualBasicoParticipante}%</div>
              </div>
              {/* <Slider
                                cor="#33C3F0;"
                                contexto={this}
                                nome={"percentualBasico"}
                                min={this.state.percentualBasicoParticipanteMinimo}
                                max={this.state.percentualBasicoParticipanteMaximo}
                                step={0.1}
                                formato={"%"}
                                valor={this.state.percentualBasicoParticipante}
                                onChange={this.handlePercentualBasicoChange(this.state.percentualBasicoParticipante)}
                            /> */}
            </Col>

            <Col className="text-center">
              <h5 className={"mt-5 mb-2"}>Patrocinador</h5>
              <div>{this.state.percentualBasicoPatrocinador.toString()}%</div>
            </Col>

            <Col>
              <h5 className={"mt-5 mb-2"}> </h5>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-dark rounded-circle bg-dark text-white"
                  onClick={() => {
                    this.toggleModal(this.props.Dados.tipoAtivo === "A" ? textos.percentualBasico : textos.percentualBasicoCDT);
                  }}
                >
                  <i className="fa fa-question"></i>
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5 className={"mt-5 mb-2"}>Beneficio de Risco - Invalidez Participante</h5>

              <div className="islider">
                <input
                  type="range"
                  id={"riscoInvalidez"}
                  name={"riscoInvalidez"}
                  min={this.state.beneficioRiscoInvalidezParticipanteMinimo}
                  max={this.state.beneficioRiscoInvalidezParticipanteMaximo}
                  step={0.1}
                  value={this.state.beneficioRiscoInvalidezParticipante}
                  className="slider"
                  onChange={this.handleOnChangeRiscoInvalidez}
                />
                <div className="value">&nbsp; {this.state.beneficioRiscoInvalidezParticipante}%</div>
              </div>
            </Col>

            <Col className="text-center">
              <h5 className={"mt-5 mb-2"}>Patrocinador</h5>
              <div>{this.state.beneficioRiscoInvalidezPatrocinador.toString()}%</div>
            </Col>

            <Col>
              <h5 className={"mt-5 mb-2"}> </h5>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-dark rounded-circle bg-dark text-white"
                  onClick={() => {
                    this.toggleModal(this.props.Dados.tipoAtivo === "A" ? textos.beneficioRisco : textos.beneficioRiscoCDT);
                  }}
                >
                  <i className="fa fa-question"></i>
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5 className={"mt-5 mb-2"}>Beneficio de Risco - Morte Participante</h5>
              <div className="islider">
                <input
                  type="range"
                  id={"riscoMorte"}
                  name={"riscoMorte"}
                  min={this.state.beneficioRiscoMorteParticipanteMinimo}
                  max={this.state.beneficioRiscoMorteParticipanteMaximo}
                  step={0.1}
                  value={this.state.beneficioRiscoMorteParticipante}
                  className="slider"
                  onChange={this.handleOnChangeRiscoMorte}
                />
                <div className="value">&nbsp; {this.state.beneficioRiscoMorteParticipante}%</div>
              </div>
            </Col>

            <Col className="text-center">
              <h5 className={"mt-5 mb-2"}>Patrocinador</h5>
              <div>{this.state.beneficioRiscoMortePatrocinador.toString()}%</div>
            </Col>

            <Col>
              <h5 className={"mt-5 mb-2"}> </h5>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-dark rounded-circle bg-dark text-white"
                  onClick={() => {
                    this.toggleModal(textos.beneficioRisco);
                  }}
                >
                  <i className="fa fa-question"></i>
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5 className={"mt-5 mb-2"}>Sobrevivência Participante</h5>
              <div className="islider">
                <input
                  type="range"
                  id={"sobrevivencia"}
                  name={"sobrevivencia"}
                  min={0}
                  max={this.state.sobrevivenciaMaxima}
                  step={0.1}
                  value={this.state.sobrevivenciaParticipante}
                  className="slider"
                  onChange={this.handleSobrevivenciaSlideChange}
                />
                <div className="value">&nbsp; {this.state.sobrevivenciaParticipante}%</div>
              </div>
            </Col>

            <Col className="text-center">
              <h5 className={"mt-5 mb-2"}>Patrocinador</h5>
              <div>{this.state.sobrevivenciaPatrocinador.toString()}%</div>
            </Col>

            <Col>
              <h5 className={"mt-5 mb-2"}></h5>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-dark rounded-circle bg-dark text-white"
                  onClick={() => {
                    this.toggleModal(this.props.Dados.tipoAtivo === "A" ? textos.sobrevivencia : textos.sobrevivenciaCDT);
                  }}
                >
                  <i className="fa fa-question"></i>
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5 className={"mt-5 mb-2"}>Carregamento Contribuição Básica</h5>
              <div>{this.state.carregamentoContribuicaoBasicaParticipante.toFixed(2)}%</div>
            </Col>

            <Col className="text-center">
              <h5 className={"mt-5 mb-2"}>Patrocinador</h5>
              <div>{this.state.carregamentoContribuicaoBasicaPatrocinador.toFixed(2)}%</div>
            </Col>

            <Col>
              <h5 className={"mt-5 mb-2"}> </h5>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-dark rounded-circle bg-dark text-white"
                  onClick={() => {
                    this.toggleModal(
                      this.props.Dados.tipoAtivo === "A" ? textos.carregamentoContribuicaoBasica : textos.carregamentoContribuicaoAdicionalCDT
                    );
                  }}
                >
                  <i className="fa fa-question"></i>
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <br></br>
              <h3 className="text-center">Que tal contribuir adicionalmente para melhorar sua aposentadoria?</h3>
              <h5 className="text-center text-secondary">Contribuição adicional sem paridade</h5>
            </Col>
          </Row>

          <Row>
            <Col className={"col-lg-4"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>{"Aposentadoria (%)"}</label>
                </b>
              </div>

              <div className={"input-group"}>
                <NumberFormat
                  name={"aposentadoriaAdicional"}
                  id={"aposentadoriaAdicional"}
                  className={"form-control"}
                  decimalSeparator=","
                  decimalScale={2}
                  // prefix="%"
                  fixedDecimalScale={true}
                  disabled={this.verificarValorAposentadoriaAdicional(this.state.percentualBasicoParticipante, 8.5)}
                  value={this.state.aposentadoriaAdicional}
                  onChange={(event: any) => this.setState({ aposentadoriaAdicional: event.target.value }, this.calculaContribuicaoAdicional)}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => {
                      this.toggleModal(textos.aposentadoriaAdicional);
                    }}
                    type={"primary"}
                    icon={<FaQuestion />}
                  />
                </div>
              </div>
            </Col>

            <Col className={"col-lg-4"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>{"Invalidez (%)"}</label>
                </b>
              </div>

              <div className={"input-group"}>
                <NumberFormat
                  name={"invalidezAdicional"}
                  id={"invalidezAdicional"}
                  className={"form-control"}
                  decimalSeparator=","
                  decimalScale={2}
                  // prefix="%"
                  fixedDecimalScale={true}
                  disabled={this.verificarValorMaximo(this.state.beneficioRiscoSoma, 1)}
                  value={this.state.invalidezAdicional}
                  onChange={(event: any) => this.setState({ invalidezAdicional: event.target.value }, this.calculaContribuicaoAdicional)}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => {
                      this.toggleModal(textos.invalidezAdicional);
                    }}
                    type={"primary"}
                    icon={<FaQuestion />}
                  />
                </div>
              </div>
            </Col>

            <Col className={"col-lg-4"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>{"Pensão por Morte (%)"}</label>
                </b>
              </div>

              <div className={"input-group"}>
                <NumberFormat
                  name={"pensaoMorteAdicional"}
                  id={"pensaoMorteAdicional"}
                  className={"form-control"}
                  decimalSeparator=","
                  decimalScale={2}
                  // prefix="%"
                  fixedDecimalScale={true}
                  disabled={this.verificarValorMaximo(this.state.beneficioRiscoSoma, 1)}
                  value={this.state.pensaoMorteAdicional}
                  onChange={(event: any) => this.setState({ pensaoMorteAdicional: event.target.value }, this.calculaContribuicaoAdicional)}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => {
                      this.toggleModal(textos.pensaoMorteAdicional);
                    }}
                    type={"primary"}
                    icon={<FaQuestion />}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className={"col-lg-3"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>{"Sobrevivência (%)"}</label>
                </b>
              </div>

              <div className={"input-group"}>
                <NumberFormat
                  name={"sobrevivenciaAdicional"}
                  id={"sobrevivenciaAdicional"}
                  className={"form-control"}
                  decimalSeparator=","
                  decimalScale={2}
                  // prefix="%"
                  fixedDecimalScale={true}
                  disabled={this.desabilitaCamposSobrevivencia()}
                  value={this.state.sobrevivenciaAdicional}
                  onChange={(event: any) => this.setState({ sobrevivenciaAdicional: event.target.value }, this.calculaContribuicaoAdicional)}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => {
                      this.toggleModal(textos.sobrevivenciaAdicional);
                    }}
                    type={"primary"}
                    icon={<FaQuestion />}
                  />
                </div>
              </div>
            </Col>

            <Col className={"col-lg-3"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>{"Carregamento Contribuição (%)"}</label>
                </b>
              </div>

              <div className={"input-group"}>
                <NumberFormat
                  name={"carregamentoContribuicaoAdicional"}
                  id={"carregamentoContribuicaoAdicional"}
                  className={"form-control"}
                  decimalSeparator=","
                  decimalScale={2}
                  // prefix="%"
                  fixedDecimalScale={true}
                  disabled={true}
                  value={this.state.carregamentoContribuicaoAdicional}
                />

                <div className="input-group-append">
                  <Button
                    onClick={() => {
                      this.toggleModal(textos.carregamentoContribuicaoAdicional);
                    }}
                    type={"primary"}
                    icon={<FaQuestion />}
                  />
                </div>
              </div>
            </Col>

            <Col className={"col-lg-3"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>{"Totalizador Taxa Aposentadoria (%)"}</label>
                </b>
              </div>

              <NumberFormat
                name={"totalizadorTaxaAposentadoria"}
                id={"totalizadorTaxaAposentadoria"}
                className={"form-control"}
                decimalSeparator=","
                decimalScale={2}
                // prefix="%"
                fixedDecimalScale={true}
                disabled={true}
                value={this.state.totalizadorTaxaAposentadoria}
              />
            </Col>

            <Col className={"col-lg-3"}>
              <div className={"col-md-12 col-form-label text-primary"}>
                <b>
                  <label htmlFor={"taxaJuros"}>{"Totalizador Taxa Contribuição (%)"}</label>
                </b>
              </div>

              <NumberFormat
                name={"totalizadorTaxaContribuicao"}
                id={"totalizadorTaxaContribuicao"}
                className={"form-control"}
                decimalSeparator=","
                decimalScale={2}
                // prefix="%"
                fixedDecimalScale={true}
                disabled={true}
                value={this.state.totalizadorTaxaContribuicao}
              />
            </Col>
          </Row>

          {this.state.modalStatus && (
            <ModalHolder>
              <Modal
                type={"body"}
                onClose={this.toggleModal}
                title={"Informações"}
                closeButtonTitle={"Ok, entendi!"}
                content={this.state.textoModal}
              />
            </ModalHolder>
          )}

          <hr />

          <FooterBar tituloAvancarAlternativo={"Simular"} tipeAvancarAlternativo={"danger"} avancar={this.proximo} voltar={this.anterior} />
        </div>
      </div>
    );
  }
}
export default Passo3;
