import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { FundacaoEntidade } from "../entidades/FundacaoEntidade";

class Fundacao extends BaseService {
	constructor() {
		super("Fundacao");
	}

	BuscarPorCodigo = (cdFundacao: string) =>
		this.CreateRequest<FundacaoEntidade>(RequestType.GET, `BuscarPorCodigo/${cdFundacao}`);

	BuscarTodas = () =>
		this.CreateRequest<Array<FundacaoEntidade>>(RequestType.GET, `BuscarTodas`);

}

export const FundacaoService = new Fundacao();
