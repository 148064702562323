import React, { Component } from "react";
import { History } from "history";

import moment from "moment";

import _ from "lodash";

import { PlanoService, SalarioBaseService, FichaFechamentoService, FuncionarioService, IndiceService } from "@services";

import {
  PlanoVinculadoEntidade,
  SalarioBaseEntidade,
  SaldoContribuicoesEntidade,
  UltimaContribuicaoEntidade,
  FuncionarioDados,
  IndiceEntidade,
  IndiceValoresEntidade
} from "@entidades";

import { FooterBar } from "./../FooterBar";
import { SimuladorCard } from "./../SimuladorCard";
import { Alert, Box, Col, ComboBox, Row, StaticField } from "@intech/web-ui";

interface Props {
  Dados: any;
  proximoPasso: () => void;
}

interface State {
  cdPlano: string,
  plano: PlanoVinculadoEntidade,
  salario: SalarioBaseEntidade,
  saldos: SaldoContribuicoesEntidade,
  ultimaContribuicao: UltimaContribuicaoEntidade,
  listaPlanos: Array<PlanoVinculadoEntidade>;
  dadosPessoais: FuncionarioDados;
  indiceEntidade: IndiceEntidade;
  indiceValoresEntidade: IndiceValoresEntidade;

  idadeMinima: number;
  idadeMaxima: number;
  idadeAposentadoria: number;
  idadeAtual: number;
  percentualAVista: number;
  aporte: number;
  termoAceito: boolean;

  tempoAposentadoriaMinima: number;
  tempoAposentadoriaMaxima: number;
  tempoAposentadoria: number;

  tempoSobrevivenciaMinima: number;
  tempoSobrevivenciaMaxima: number;
  tempoSobrevivencia: number;

  remuneracaoInicialMinima: number;
  remuneracaoInicialMaxima: number;
  remuneracaoInicial: number;

  remuneracaoFinalMinima: number;
  remuneracaoFinalMaxima: number;
  remuneracaoFinal: number;

  taxaCrescimentoSalarial: number;
  taxaCrescimentoSalarialMinima: number;
  taxaCrescimentoSalarialMaxima: number;
  dataNascimento: Date;
  tipoAtivo: string;

  rgps: number;
  termosAceitos: boolean;

  isPesquisando: boolean,
}

export class Passo1 extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      // States Passo 1:
      cdPlano: this.props.Dados.cdPlano,
      plano: this.props.Dados.plano,
      salario: this.props.Dados.salario,
      saldos: this.props.Dados.saldos,
      ultimaContribuicao: this.props.Dados.ultimaContribuicao,
      listaPlanos: this.props.Dados.listaPlanos,
      dadosPessoais: this.props.Dados.dadosPessoais,
      indiceEntidade: this.props.Dados.indice,
      indiceValoresEntidade: null,

      // States Passo 2:
      idadeMinima: this.props.Dados.idadeMinima,
      idadeMaxima: this.props.Dados.idadeMaxima,
      idadeAposentadoria: this.props.Dados.idadeAposentadoria,
      idadeAtual: this.props.Dados.idadeAtual,
      percentualAVista: this.props.Dados.percentualAVista,
      aporte: this.props.Dados.aporte,
      termoAceito: this.props.Dados.termoAceito,

      tempoAposentadoriaMinima: this.props.Dados.tempoAposentadoriaMinima,
      tempoAposentadoriaMaxima: this.props.Dados.tempoAposentadoriaMaxima,
      tempoAposentadoria: this.props.Dados.tempoAposentadoria,

      tempoSobrevivenciaMinima: this.props.Dados.tempoSobrevivenciaMinima,
      tempoSobrevivenciaMaxima: this.props.Dados.tempoSobrevivenciaMaxima,
      tempoSobrevivencia: this.props.Dados.tempoSobrevivencia,

      remuneracaoInicialMinima: this.props.Dados.remuneracaoInicialMinima,
      remuneracaoInicialMaxima: this.props.Dados.remuneracaoInicialMaxima,
      remuneracaoInicial: this.props.Dados.remuneracaoInicial,

      remuneracaoFinalMinima: this.props.Dados.remuneracaoFinalMinima,
      remuneracaoFinalMaxima: this.props.Dados.remuneracaoFinalMaxima,
      remuneracaoFinal: this.props.Dados.remuneracaoFinal,

      taxaCrescimentoSalarial: this.props.Dados.taxaCrescimentoSalarial,
      taxaCrescimentoSalarialMinima: this.props.Dados.taxaCrescimentoSalarialMinima,
      taxaCrescimentoSalarialMaxima: this.props.Dados.taxaCrescimentoSalarialMaxima,
      dataNascimento: null,
      tipoAtivo: this.props.Dados.tipoAtivo,

      rgps: this.props.Dados.rgps,

      termosAceitos: this.props.Dados.termosAceitos,

      isPesquisando: true,
    }

  }

  componentDidMount = async () => {
    await this.buscarPlanos();
  }

  // buscar os planos do participante, se houver mais de um, mostra-los no combo para que o participante possa escolher
  buscarPlanos = async () => {
    try {
      var listaPlanos = await PlanoService.Buscar();

      var plano = listaPlanos[0];
      var cdPlano = plano.CD_PLANO;
      this.setState({ plano, listaPlanos, cdPlano });
      await this.buscarDados();

      this.setState({ isPesquisando: false });

    } catch (err) {
      console.error(err);
    }
  }

  buscarDados = async () => {
    try {
      var codigoIndiceRGPS = 'RGPS';
      var indiceValoresEntidade = await IndiceService.BuscarUltimoPorCodigoData(codigoIndiceRGPS);

      var plano = await PlanoService.BuscarPorCodigo(this.state.cdPlano);

      var salario = await SalarioBaseService.Buscar();

      var saldos = await FichaFechamentoService.BuscarSaldoPorPlano(this.state.cdPlano);

      var contrib = await FichaFechamentoService.BuscarContribuicaoParticipanteTotalAtualizada(this.state.cdPlano);

      saldos.ValorParticipante = contrib;

      var ultimaContribuicao = await FichaFechamentoService.BuscarUltimaContribuicaoPorPlano(this.state.cdPlano);

      var dadosPessoais = await FuncionarioService.Buscar();

      var dataInscricao = moment(plano.DT_INSC_PLANO, "DD/MM/YYYY");
      var dataNascimento = moment(dadosPessoais.DadosPessoais.DT_NASCIMENTO, "DD/MM/YYYY");

      var idadeAtual = moment().diff(dataNascimento, "years");

      var idadeMinima = this.state.idadeMinima;
      var idadeMaxima = this.state.idadeMaxima;

      var idadeMinima = this.state.idadeMinima;
      var idadeMaxima = this.state.idadeMaxima;

      if (idadeAtual > this.state.idadeMinima)
        idadeMinima = idadeAtual;

      var rgps = indiceValoresEntidade.VALOR_IND;

      await this.setState({
        plano,
        salario,
        saldos,
        ultimaContribuicao,
        dadosPessoais,
        idadeMinima,
        idadeMaxima,
        idadeAposentadoria: idadeMinima,
        idadeAtual,
        indiceValoresEntidade,
        rgps,
        remuneracaoInicial: salario.VL_SALARIO,
        remuneracaoFinal: salario.VL_SALARIO,
        tipoAtivo: dadosPessoais.TIPO,
        // remuneracaoFinal: rgps + 1,
      });

    } catch (err) {
      console.error(err);
    }
  }

  carregarPlano = async () => {
    var plano = null;
    var cdPlano = "";

    if (this.state.cdPlano)
      plano = _.filter(this.state.listaPlanos, (plano: any) => plano.CD_PLANO === this.state.cdPlano)[0];
    else
      plano = this.state.listaPlanos[0];

    cdPlano = plano.CD_PLANO;

    await this.setState({
      plano,
      cdPlano
    });

  }

  validaPagina() {
    var termosAceitos = true;
    this.setState({ termosAceitos })
  }

  proximo = () => {
    this.atualizaDados()

    // metódo do formulario Pai
    this.props.proximoPasso();
  };

  atualizaDados() {

    this.props.Dados.cdPlano = this.state.cdPlano;

    this.props.Dados.plano = this.state.plano;

    this.props.Dados.salario = this.state.salario;
    this.props.Dados.saldos = this.state.saldos;
    this.props.Dados.ultimaContribuicao = this.state.ultimaContribuicao;
    this.props.Dados.listaPlanos = this.state.listaPlanos
    this.props.Dados.dadosPessoais = this.state.dadosPessoais;

    // States Passo 1:
    this.props.Dados.idadeMinima = this.state.idadeMinima;
    this.props.Dados.idadeMaxima = this.state.idadeMaxima;
    this.props.Dados.idadeAposentadoria = this.state.idadeAposentadoria;
    this.props.Dados.idadeAtual = this.state.idadeAtual;
    this.props.Dados.percentualAVista = this.state.percentualAVista;
    this.props.Dados.aporte = this.state.aporte;
    this.props.Dados.termoAceito = this.state.termoAceito;

    this.props.Dados.tempoAposentadoriaMinima = this.state.tempoAposentadoriaMinima;
    this.props.Dados.tempoAposentadoriaMaxima = this.state.tempoAposentadoriaMaxima;
    this.props.Dados.tempoAposentadoria = this.state.tempoAposentadoria;

    this.props.Dados.tempoSobrevivenciaMinima = this.state.tempoSobrevivenciaMinima;
    this.props.Dados.tempoSobrevivenciaMaxima = this.state.tempoSobrevivenciaMaxima;
    this.props.Dados.tempoSobrevivencia = this.state.tempoSobrevivencia;

    this.props.Dados.remuneracaoInicialMinima = this.state.remuneracaoInicialMinima;
    this.props.Dados.remuneracaoInicialMaxima = this.state.remuneracaoInicialMaxima;
    this.props.Dados.remuneracaoInicial = this.state.remuneracaoInicial;

    this.props.Dados.remuneracaoFinalMinima = this.state.remuneracaoFinalMinima;
    this.props.Dados.remuneracaoFinalMaxima = this.state.remuneracaoFinalMaxima;
    this.props.Dados.remuneracaoFinal = this.state.remuneracaoFinal;

    this.props.Dados.taxaCrescimentoSalarial = this.state.taxaCrescimentoSalarial;
    this.props.Dados.taxaCrescimentoSalarialMinima = this.state.taxaCrescimentoSalarialMinima;
    this.props.Dados.taxaCrescimentoSalarialMaxima = this.state.taxaCrescimentoSalarialMaxima;

    this.props.Dados.rgps = this.state.rgps;

    this.props.Dados.termosAceitos = this.state.termosAceitos;
    this.props.Dados.tipoAtivo = this.state.tipoAtivo;
    this.props.Dados.SaldoAcumulado = this.state.saldos.ValorSaldoAtualizado;

  }

  renderNaoParticipante() {

    return (
      <div>
        <Box>
          <h4>Simulador de Benefício não disponível para a categoria DESLIGADO!</h4>
          <p>Se desejar, Click <a href="http://preves.es.gov.br/SIMULADOR/" target="_blank">aqui</a> para ir para o simulador de Não Participante.</p>
        </Box>
      </div>
    )
  }

  renderParticipante() {

    return (
      <div>

        <Row>
          {/* Na teoria deverá mostrar um combo com os planos para que o participante selecione um, se houver mais de um plano... */}
          {this.state.listaPlanos.length > 1 &&
            <ComboBox
              title={"Selecione um plano"}
              onChange={this.carregarPlano}
              value={this.state.cdPlano}
              data={this.state.listaPlanos}
              memberName={"DS_PLANO"}
              memberValue={"CD_PLANO"}
              required
            />
          }
        </Row>

        <Row>
          <Col>
            <SimuladorCard titulo={this.state.plano.DS_PLANO}>
              {this.state.plano.DS_CATEGORIA}
            </SimuladorCard>
          </Col>

          <Col>
            <SimuladorCard titulo={"Data de Inscrição"}>
              {this.state.plano.DT_INSC_PLANO}
            </SimuladorCard>
          </Col>

          <Col>
            <SimuladorCard titulo={"Salário Participação"}>
              <StaticField
                value={this.state.salario.VL_SALARIO}
                type={"money"}
              />
            </SimuladorCard>
          </Col>

          <Col>
            <SimuladorCard titulo={"Regime de Tributação"}>
              {this.state.plano.TIPO_IRRF === "2" ? "Regressivo" : "Progressivo"}
            </SimuladorCard>
          </Col>

          <Col>
            <SimuladorCard titulo={"Rgps Atual"}>
              <StaticField
                value={this.state.rgps}
                type={"money"}
              />
            </SimuladorCard>
          </Col>

        </Row>
        <Row className={"mt-4"}>
          <Col>
            <Box title={"Sua Última Contribuição : PARTICIPANTE"} badgeText={`Posição em: ${this.state.ultimaContribuicao.DataReferencia}`}>

              <table className={"table table-borderless table-sm"}>
                <tbody>
                  <tr>
                    <td>Aposentadoria:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorAposentadoriaParticipante}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Benefício Risco (Invalidez/Morte):</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorBeneficioRiscoParticipante}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Sobrevivencia:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorSobrevivenciaParticipante}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Carregamento:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorCarregamentoParticipante}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Totais:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorTotalParticipante}
                        type={"money"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Col>

          <Col>
            <Box title={"Sua Última Contribuição : PATROCINADORA"} badgeText={`Posição em: ${this.state.ultimaContribuicao.DataReferencia}`}>

              <table className={"table table-borderless table-sm"}>
                <tbody>
                  <tr>
                    <td>Aposentadoria:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorAposentadoriaEmpresa}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Benefício Risco (Invalidez/Morte):</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorBeneficioRiscoEmpresa}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Sobrevivencia:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorSobrevivenciaEmpresa}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Carregamento:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorCarregamentoEmpresa}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Totais:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.ultimaContribuicao.ValorTotalEmpresa}
                        type={"money"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Col>
        </Row>

        <Row>
          <Col>
            <Box title={"Seu Saldo"} badgeText={`Posição em: ${this.state.saldos.DataCota}`}>

              <table className={"table table-borderless table-sm"}>
                <tbody>
                  <tr>
                    <td>Contribuições Participante (total):</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.saldos.ValorParticipante}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Contribuições Patronais (total):</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.saldos.ValorPatrocinadora}
                        type={"money"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Contribuições Totais:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.saldos.ValorParticipante + this.state.saldos.ValorPatrocinadora}
                        type={"money"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr />

              <table className={"table table-borderless table-sm"}>
                <tbody>
                  <tr>
                    <td>Aposentadoria Complementar:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.saldos.TotalAposentadoriaComplementar}
                        type={"money"}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td> Rendimento do Plano:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.saldos.ValorSaldoAtualizado - this.state.saldos.TotalAposentadoriaComplementar}
                        type={"money"}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td> Rendimento do Plano:</td>
                    <td className={'text-right'}>
                      <StaticField
                        value={this.state.saldos.ValorSaldoAtualizado - this.state.saldos.TotalAposentadoriaComplementar}
                        type={"money"}
                      />
                    </td>
                  </tr>

                </tbody>
              </table>

              <Alert type={"success"}>
                <b>
                  Saldo Acumulado Atualizado em: {this.state.saldos.DataCotaAtualizacao} Cota utilizada: <StaticField value={this.state.saldos.ValorCotaAtualizacao} type={"text"} />
                </b>
                <p className={"float-right"}>
                  <b>
                    <StaticField
                      value={this.state.saldos.ValorSaldoAtualizado}
                      type={"money"}
                    />
                  </b>
                </p>
              </Alert>
            </Box>
          </Col>
        </Row>
        <hr />

        <FooterBar
          avancar={this.proximo}
        />

      </div>
    )
  }

  render() {

    return (
      <div>
        {!this.state.isPesquisando &&
          <>
            {this.state.plano.CD_CATEGORIA === "2" &&
              this.renderNaoParticipante()
            }

            {this.state.plano.CD_CATEGORIA !== "2" &&
              <>
                {this.renderParticipante()}
              </>
            }
          </>
        }

        {this.state.isPesquisando &&
          <div id="pesquisando" className="alert alert-info">Aguarde processando...</div>
        }
      </div >
    );
  }
}

export default Passo1;
