import React from 'react';
import { History } from "history";
import { Row, Col, Box, Botao } from "@intechprev/componentes-web";

import { MasterPage } from "..";
import { MensagemService, PlanoService, UsuarioService } from "../../services";

import ListaMensagens from '../_shared/mensagem/ListaMensagens';

interface Props {
    history?: History;
}

interface State {
    mensagens: Array<any>,
    planos: Array<any>,
    admin: boolean,
}

export class Mensagem extends React.Component<Props, State> {
    public masterPage = React.createRef<MasterPage>();

    state: State = {
        mensagens: [],
        planos: [],
        admin: false
    }

    componentDidMount = async() => {

        try {
            var plano = await PlanoService.Buscar();
            var admin = await UsuarioService.VerificarAdmin();

            await this.setState({
                admin
            });

            plano.map(async (plano: any) => {

                var mensagens = await MensagemService.BuscarPorPlano(plano.CD_PLANO);
                plano.mensagens = mensagens;
                await this.setState({
                    planos: [...this.state.planos, plano]
                });
            });

        } catch(err) {
            console.error(err);
        }
    }

    handleClick = () => {
        this.props.history.push('/mensagem/nova');
    }

    render() {
        return (
            <MasterPage ref={this.masterPage} {...this.props}>
                <Row>
                    {
                        this.state.planos.map((plano, index) => {
                            return (
                                <Col key={index}>
                                    <Box titulo={"Mensagens"}>
                                        {this.state.admin &&
                                            <div>
                                                <Botao className="btn btn-primary" onClick={this.handleClick} icone={"fa-envelope"} titulo={"Nova Mensagem"} />
                                                <br/>
                                                <br/>
                                            </div>
                                        }

                                        {plano.mensagens.length > 0 &&
                                            <ListaMensagens mostrarDados={false} mensagens={plano.mensagens} />}

                                        {plano.mensagens.length === 0 &&
                                            <div id="alertMensagem" className="alert alert-danger">Nenhuma mensagem enviada.</div>}
                                    </Box>
                                </Col>
                            )
                        })
                    }
                </Row>
            </MasterPage>
        );
    }
}