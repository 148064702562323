export class SaldoContribuicoesEntidade {
  public DataReferencia: Date;
  public QuantidadeCotasParticipante: number;
  public QuantidadeCotasPatrocinadora: number;
  public QuantidadeCotasTotal: number;
  public ValorParticipante: number;
  public ValorPatrocinadora: number;
  public ValorTotal: number;
  public DataCota: Date;
  public ValorCota: number;
  public TotalAposentadoriaComplementar: number;
  public ValorSaldoAtualizado: number;
  public DataCotaAtualizacao: Date;
  public ValorCotaAtualizacao: number;
  public ValoresPortados: number;
}