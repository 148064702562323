import { BaseService, RequestType, ResponseType } from "@intech/react-service";

import { SalarioBaseEntidade } from "../entidades/SalarioBaseEntidade";

class SalarioBase extends BaseService {
  constructor() {
    super("SalarioBase");
  }

  Buscar = () =>
    this.CreateRequest<SalarioBaseEntidade>(RequestType.GET, `Buscar`);
}

export const SalarioBaseService = new SalarioBase();
