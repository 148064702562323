import { Box, Col, Button } from "@intech/web-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FichaFinanceiraAssistidoEntidade,
  FuncionarioDados,
  ProcessoBeneficioEntidade,
} from "@entidades";
import { FichaFinanceiraAssistidoService, FuncionarioService } from "@services";
import {
  Header,
  HeaderInfo,
  HeaderProvDesc,
  HeaderValor,
  Titulo,
  SubTitulo,
} from "./styles";
import { UltimaFolhaItem } from "./UltimaFolhaItem";

interface IProps {
  beneficio: ProcessoBeneficioEntidade;
}

export const UltimaFolha: React.FC<IProps> = ({ beneficio }) => {
  const history = useHistory();

  const [ultimaFolha, setUltimaFolha] = useState<any>(null);
  const [DataPagamento, setDataPagamento] = useState<string>("");
  const [dados, setDados] = useState<FuncionarioDados>(null);

  useEffect(() => {
    (async () => {
      if (beneficio) {
        var ultimaFolha = await FichaFinanceiraAssistidoService.BuscarUltimaPorPlanoProcesso(
          beneficio.CD_PLANO,
          beneficio.CD_ESPECIE,
          beneficio.ANO_PROCESSO,
          beneficio.NUM_PROCESSO.toString()
        );
        setUltimaFolha(ultimaFolha);

        if (beneficio.CD_PLANO === "0001") {
          const UltimaFolha: any = ultimaFolha;

          const DataPagamento = await FichaFinanceiraAssistidoService.BuscarDataPagamentoPorTipoFolhaReferencia(
            UltimaFolha.Resumo.TipoFolha,
            moment(UltimaFolha.Resumo.Referencia, "DD/MM/YYYY").format("DD.MM.YYYY")
          );

          setDataPagamento(
            moment(
              DataPagamento.DT_CRED_BANC, "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          );

          const dadosPessoais = await FuncionarioService.Buscar();

          setDados(dadosPessoais);
        }
      }
    })();
  }, [beneficio]);

  if (ultimaFolha && dados !== null) {
    return (
      <Col className="col-lg-6">
        <Box
          title={`Último Pagamento`}
          badgeText={`Referência: ${moment(
            ultimaFolha.Resumo.Referencia,
            "DD/MM/YYYY"
          ).format("MM/YYYY")}`}
          badgeType="secondary"
        >
          <Titulo className="text-center">
            Valor Líquido:{" "}
            {`R$ ${ultimaFolha.Resumo.Liquido.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
          </Titulo>

          {beneficio && beneficio.CD_PLANO === "0001" &&
            <>
              <SubTitulo className="text-center">
                Pago em {DataPagamento}
              </SubTitulo>

              <SubTitulo className="text-center">
                Conta creditada: {dados.Entidade.NUM_CONTA}
              </SubTitulo>
            </>
          }

          <div className="mb-5"></div>

          <Header>
            <HeaderProvDesc>{"Tipo"}</HeaderProvDesc>

            <HeaderInfo>{"Rubrica (Competência)"}</HeaderInfo>

            <HeaderValor>{"Valor"}</HeaderValor>
          </Header>

          {ultimaFolha.Proventos.map(
            (rubrica: FichaFinanceiraAssistidoEntidade, index: number) => {
              return (
                <UltimaFolhaItem
                  key={index}
                  provento={true}
                  rubrica={rubrica}
                />
              );
            }
          )}

          {ultimaFolha.Descontos.map(
            (rubrica: FichaFinanceiraAssistidoEntidade, index: number) => {
              return (
                <UltimaFolhaItem
                  key={index}
                  provento={false}
                  rubrica={rubrica}
                />
              );
            }
          )}

          <Button
            onClick={() => history.push("/contracheque")}
            title="Demonstrativos de Pagamento de Benefício"
            type="primary"
            block
            className="mt-4"
          />
        </Box>
      </Col>
    );
  } else return null;
};
