export class DadosPessoaisEntidade {
	public COD_ENTID: number;
	public CD_NACIONALIDADE: string;
	public CD_GRAU_INSTRUCAO: string;
	public CD_ESTADO_CIVIL: string;
	public SEXO: string;
	public NATURALIDADE: string;
	public UF_NATURALIDADE: string;
	public DT_NASCIMENTO: Date;
	public NU_IDENT: string;
	public ORG_EMIS_IDENT: string;
	public DT_EMIS_IDENT?: Date;
	public NU_CTPS: string;
	public SERIE_CTPS: string;
	public UF_EMIS_CTPS: string;
	public COD_BANCO_COB: string;
	public COD_AGENC_COB: string;
	public CD_TIPO_COB: string;
	public NUM_CONTA_COB: string;
	public NOME_PAI: string;
	public NOME_MAE: string;
	public CD_PAIS: string;
	public NR_DEP_COB: string;
	public QTD_DEPENDENTE?: number;
	public CD_EMP_COB: string;
	public PS_DOENCA_CRONICA: string;
	public EMAIL_AUX: string;
	public FONE_CELULAR: string;
	public DT_FALECIMENTO?: Date;
	public ENVIAR_CARTAO: string;
	public CARTAO_ENVIADO: string;
	public NUMERO_CARTAO?: number;
	public SEGUNDA_VIA_CARTAO: string;
	public CARTAO_CANCELADO: string;
	public ALTERAR_DADOS_PORT: string;
	public CANCELAR_LIM_PORT: string;
	public SEGUNDA_VIA_SENHA: string;
	public SEGUNDA_VIA_EXTRATO: string;
	public CD_CANCELAMENTO_CARTAO: string;
	public LIBERAR_CARTAO: string;
	public INTERDICAO: string;
	public CNT_ABERT_CRED: string;
	public COD_ENTID_CURADOR?: number;
	public NOME_CONJUGE: string;
	public CPF_CONJUGE: string;
	public TEMPO_MILITAR: string;
	public END_CORREIO: string;
	public UF_EMIS_IDENT: string;
	public CD_OPERADORA: string;
	public APOSENTADO_INSS: string;
	public DIR_DOCUMENTOS: string;
	public VL_MARGEM_CONSIG?: number;
	public REG_ESTRANGEIRO: string;
	public PAIS_ORIGEM_PASSAPORTE: string;
	public DT_CHEGADA?: Date;
	public NOME_SOCIAL: string;
	public IND_RECEBER_EMAIL: string;
	public ID_EMP_ONLINE: string;
	public CPF_CGC: string;
	public NOME_ENTID: string;
	public DS_SEXO: string;
}
