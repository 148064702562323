import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@intechprev/componentes-web";

import { MasterPage } from "..";
import { PlanoService } from "@services";

import { PlanoVinculadoEntidade } from "@entidades";
import { LoadingComponent } from "@components";

export const Planos: React.FC = ({ ...rest }) => {
  const [Loading, setLoading] = useState<boolean>(false);
  const [ListaPlanos, setListaPlanos] = useState<Array<PlanoVinculadoEntidade>>(new Array<PlanoVinculadoEntidade>());

  useEffect(() => {
    (async () => {
      setLoading(true);

      const ListaPlanos = await PlanoService.Buscar();

      setListaPlanos(ListaPlanos);

      setLoading(false);
    })();
  }, []);


  return (
    <MasterPage {...rest}>
      {Loading && <LoadingComponent />}

      { !Loading &&
        <Box>
          <table className="table" id="tabelaPlanos">
            <thead>
              <tr>
                <th style={{ width: 250 }}>Plano</th>
                <th style={{ width: 280 }}>Situação</th>
                <th style={{ width: 180 }}>Categoria</th>
                <th style={{ width: 150 }}>Data de inscrição</th>
                <th style={{ width: 130 }}></th>
              </tr>
            </thead>
            <tbody>
              {
                ListaPlanos.map((plano, index) => {
                  return (
                    <tr key={index}>
                      <td>{plano.DS_PLANO}</td>
                      <td>{plano.DS_SIT_PLANO}</td>
                      <td>{plano.DS_CATEGORIA}</td>
                      <td>{plano.DT_INSC_PLANO}</td>
                      <td align="center">
                        <Link className={"btn btn-primary btn-sm"} onClick={() => localStorage.setItem("empresa", plano.CD_EMPRESA)} to={`/planos/${plano.CD_PLANO}`}>Detalhes</Link>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </Box>
      }
    </MasterPage>
  );
}