import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { BancoAgEntidade } from "../entidades/BancoAgEntidade";

class BancoAgencia extends BaseService {
	constructor() {
		super("BancoAgencia");
	}

	BuscarBancos = () =>
		this.CreateRequest<Array<BancoAgEntidade>>(RequestType.GET, `BuscarBancos`);

	BuscarAgenciaDoBanco = (cod_banco: string) =>
		this.CreateRequest<Array<BancoAgEntidade>>(RequestType.GET, `BuscarAgenciaDoBanco/${cod_banco}`);

}

export const BancoAgenciaService = new BancoAgencia();
