import { BaseService, RequestType, ResponseType } from "@intech/react-service";

class Versao extends BaseService {
	constructor() {
		super("Versao");
	}

	Get = () =>
		this.CreateRequest<any>(RequestType.GET, ``);

}

export const VersaoService = new Versao();
