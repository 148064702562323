import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { EmailEntidade } from "../entidades/EmailEntidade";

class Relacionamento extends BaseService {
	constructor() {
		super("Relacionamento");
	}

	Enviar = (relacionamentoEntidade: EmailEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Enviar`, relacionamentoEntidade);

}

export const RelacionamentoService = new Relacionamento();
