import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { DocumentoPastaEntidade, DocumentoEntidade } from "../../entidades";

import { Row, Col, Box, Form, Botao, Alerta, CampoTexto, TipoAlerta, TipoBotao, PosicaoTituloCampoTexto } from '@intechprev/componentes-web';
import { DocumentoService } from "../../services";
import { Tabelas } from "./Tabelas";

import config from '../../config.json';

const apiUrl = config.apiUrl;

interface Props {
    history?: any;
    match?: any;
    oidPasta: number;
}

interface State {
    admin: boolean;
    planos: Array<any>,
    documentos: Array<any>,
    pastas: Array<any>,
    nomePasta: string,
    nomeDocumento: string,
    arquivoUpload: string,
    podeCriarDocumento: boolean,
    oidArquivoUpload: number,
    pastaAtual: any,
    pastaPai: any,
    visibilidadeFileInput: boolean,
    uploadPercentage: number,
    uploading: boolean,
    documentoPastaEntidade: DocumentoPastaEntidade;
    documentoEntidade: DocumentoEntidade;

}

export class AbaUpload extends Component<Props, State> {
    public formDocumento = React.createRef<Form>();
    public formPasta = React.createRef<Form>();
    public alertDocumento = React.createRef<Alerta>();
    public alertPasta = React.createRef<Alerta>();

    constructor(props: Props) {
        super(props);

        this.state = {
            admin: false,
            planos: [],
            documentos: [],
            pastas: [],
            nomePasta: "",
            nomeDocumento: "",
            arquivoUpload: "",
            podeCriarDocumento: false,
            oidArquivoUpload: 0,
            pastaAtual: null,
            pastaPai: "",
            visibilidadeFileInput: true,
            uploadPercentage: 0,
            uploading: false,
            documentoPastaEntidade: new DocumentoPastaEntidade(),
            documentoEntidade: new DocumentoEntidade(),
        }
    }

    componentDidMount = async () => {
        await this.buscarLista();
    }

    UNSAFE_componentWillReceiveProps() {
        window.location.reload();
    }

    buscarLista = async () => {
        var resultado = await DocumentoService.BuscarPorPasta(this.props.oidPasta);

        var pastaPai = "";

        if (resultado.pastaAtual && resultado.pastaAtual.OID_DOCUMENTO_PASTA_PAI)
            pastaPai = resultado.pastaAtual.OID_DOCUMENTO_PASTA_PAI;

        await this.setState({
            documentos: resultado.documentos,
            documentoEntidade: resultado.documentos,

            pastas: resultado.pastas,
            pastaAtual: resultado.pastaAtual,
            pastaPai
        });
    }

    salvarPasta = async (e: any) => {

        await this.alertPasta.current.limparErros();
        await this.formPasta.current.validar();

        if (this.alertPasta.current.state.mensagem.length === 0 && this.alertPasta.current.props.mensagem.length === 0) {
            try {
                await DocumentoService.CriarPasta(this.state.documentoPastaEntidade);
                await this.setState({
                    nomePasta: ""
                });

                await this.buscarLista();

            } catch (err) {
                console.error(err);
            }
        }
    }

    uploadFile = async (e: any) => {
        try {
            const formData = new FormData()
            var arquivoUpload = e.target.files[0];

            formData.append("File", arquivoUpload, arquivoUpload.name);

            await this.setState({ uploading: true });

            var { data: oidArquivoUpload } = await axios.post(apiUrl + '/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: async progressEvent => {
                    await this.setState({
                        uploadPercentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    });
                },
            });

            await this.setState({
                podeCriarDocumento: true,
                oidArquivoUpload,
                visibilidadeFileInput: false,
                uploading: false,
                uploadPercentage: 0
            });
        } catch (err) {
            console.error(err);
        }
    }

    salvarDocumento = async () => {

        if (this.props.oidPasta === undefined || this.props.oidPasta === 0) {
            if (window.confirm('Selecione uma pasta abaixo, ou crie uma nova!')) {
                document.location.reload();
            }
        }
        else {

            await this.alertDocumento.current.limparErros();
            await this.formDocumento.current.validar();

            if (this.alertDocumento.current.state.mensagem.length === 0 && this.alertDocumento.current.props.mensagem.length === 0) {
                try {

                    let documento = new DocumentoEntidade();
                    documento.OID_ARQUIVO_UPLOAD = this.state.oidArquivoUpload;
                    documento.TXT_TITULO = this.state.nomeDocumento;
                    documento.IND_ATIVO = "SIM";
                    documento.OID_DOCUMENTO_PASTA = this.props.oidPasta;
                    documento.CD_PLANO = '0001'; //this.state.plano;
                    await DocumentoService.Criar(documento);

                    await this.setState({
                        nomeDocumento: "",
                        arquivoUpload: "",
                        oidArquivoUpload: 0,
                        visibilidadeFileInput: true,
                        podeCriarDocumento: false
                    });
                    await this.buscarLista();

                } catch (err) {
                    console.error(err);
                }
            }
        }

    }

    render() {
        return (
            <div>
                <Row>
                    <Col className={"lg-4"}>
                        <Box titulo={"UPLOAD DE DOCUMENTOS"}>
                            <Form ref={this.formDocumento}>

                                <CampoTexto
                                    contexto={this}
                                    nome={"nomeDocumento"}
                                    max={50}
                                    valor={this.state.nomeDocumento}
                                    titulo={"Título"}
                                    obrigatorio
                                    posicaoTitulo={PosicaoTituloCampoTexto.cima}
                                    tituloClassName={"text-primary"}
                                />

                                <div className="form-group">

                                    <label htmlFor="selecionar-documento"><b>Arquivo</b></label><br />

                                    {this.state.uploading &&
                                        <div className="progress" style={{ marginBottom: 10 }}>
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: this.state.uploadPercentage + "%" }}></div>
                                        </div>
                                    }

                                    {this.state.visibilidadeFileInput && !this.state.uploading &&
                                        <input name="selecionar-documento" id="selecionar-documento" type="file" onChange={this.uploadFile} />
                                    }

                                    {!this.state.visibilidadeFileInput && !this.state.uploading &&
                                        <div>
                                            <Alerta tipo={TipoAlerta.success} mensagem={"Arquivo enviado com sucesso"} />
                                            <Botao titulo={"Enviar outro arquivo"} tipo={TipoBotao.light}
                                                onClick={async () => await this.setState({ visibilidadeFileInput: true, oidArquivoUpload: 0, podeCriarDocumento: false })} />
                                        </div>
                                    }
                                    <hr />

                                    <Botao titulo={"Salvar"} submit desativado={!this.state.podeCriarDocumento}
                                        onClick={this.salvarDocumento} />
                                </div>

                                <Alerta ref={this.alertDocumento} padraoFormulario tipo={TipoAlerta.danger} />

                            </Form>
                        </Box>

                        <Box titulo={"CRIAÇÃO DE PASTA"}>
                            <Form ref={this.formPasta}>

                                <CampoTexto contexto={this} parent={"documentoPastaEntidade"} nome={"NOM_PASTA"} max={50} valor={this.state.documentoPastaEntidade.NOM_PASTA} titulo={"Nome"} obrigatorio />
                                <hr />

                                <div className="form-group">
                                    <Botao className={"btn btn-primary"} titulo={"Salvar"} submit onClick={this.salvarPasta} />
                                </div>

                                <Alerta ref={this.alertPasta} padraoFormulario tipo={TipoAlerta.danger} />

                            </Form>
                        </Box>
                    </Col>
                </Row>

                <Row>
                    <Col tamanho={"8"}>
                        <Box>
                            {this.state.pastaAtual &&
                                <Link className={"btn btn-primary mb-4"} to={`/documentos/${this.state.pastaPai}`}>
                                    <i className={"fa fa-chevron-left mr-2"}></i>
                                    Voltar
                                </Link>
                            }

                            {(this.state.pastas.length > 0 || this.state.documentos.length > 0) &&
                                <div>
                                    <Tabelas {...this.props} itens={this.state.pastas} campoTexto={"NOM_PASTA"} icone={"fa-folder-open text-warning"} tipo={"pasta"} />
                                    <Tabelas {...this.props} itens={this.state.documentos} campoTexto={"TXT_TITULO"} icone={"fa-file text-info"} tipo={"documento"} />
                                </div>
                            }

                            {this.state.pastas.length === 0 && this.state.documentos.length === 0 &&
                                <div className="alert alert-danger">Nenhum item disponível.</div>
                            }
                        </Box>
                    </Col>
                </Row>
            </div>

        );
    }
}