import styled, { css } from "styled-components";

interface IProps {
  progress: "todo" | "doing" | "done" | "error";
}

const ItemProgress = {
  todo: css`
    color: silver;
    border-bottom: 4px solid silver;
  `,
  doing: css`
    color: black;
    border-bottom: 4px solid #33C3F0;
  `,
  done: css`
    color: black;
    border-bottom: 4px solid #33C3F0;
  `,
  error: css`
    color: black;
    border-bottom: 4px solid #33C3F0;
  `
}

const IconProgress = {
  todo: css`
    color: silver;
    background-color: white;
  `,
  doing: css`
    color: white;
    background-color: #33C3F0;
  `,
  done: css`
    color: white;
    background-color: #33C3F0;
  `,
  error: css`
    color: black;
    border-bottom: 4px solid #33C3F0;
  `
}

export const NavContainer = styled.ol`
  margin: 0;
  padding-bottom: 2.2rem;
  list-style-type: none;
  align-content: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const NavItem = styled.li<IProps>`
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0 0.7rem;
  min-height: 55px;
  ${props => ItemProgress[props.progress]}
`;

export const NavTexto = styled.span`
  padding: 0 1.5rem;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const NavIndex = styled.span`
  padding: 0 1.5rem;

  @media (min-width: 651px) {
    display: none;
  }
`;

export const NavIcon = styled.span<IProps>`
  width: 25px;
  height: 25px;
  border-radius: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => IconProgress[props.progress]}

  @media (max-width: 650px) {
    display: inline;
  }
`;