import { Box } from '@intech/web-ui';
import React from 'react';
import { Container, Loader, LoaderText } from './styles';

const LoadingComponent: React.FC = () => {
  return (
    <Box>
      <Container>
        <Loader src={'./imagens/loading.gif'} alt='loader' />

        <LoaderText>Carregando...</LoaderText>
      </Container>
    </Box>
  );
}

export default LoadingComponent;