import React from "react";

import { PlanoButtonContainer, PlanoButton } from "./styles";

const ListaPlanos: React.FC<any> = ({ planos, onChange, cdPlanoAtivo }) => {
  return (
    <div>
      {planos.map((plano: any, index: number) => (
        <PlanoButtonContainer key={index}>
          <PlanoButton
            ativo={plano.CD_PLANO === cdPlanoAtivo}
            onClick={() => onChange(plano)}
          >
            <b>{plano.DS_PLANO}</b>
            <div>Situação: {plano.DS_SIT_PLANO}</div>
            <div>Categoria: {plano.DS_CATEGORIA}</div>
          </PlanoButton>
        </PlanoButtonContainer>
      ))}
    </div>
  );
};

export default ListaPlanos;
