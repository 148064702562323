import React from 'react';
import { FaCheck, FaRegCircle, FaEllipsisH } from 'react-icons/fa';

import './Navbar.css';
import { NavContainer, NavItem, NavTexto, NavIcon, NavIndex } from './styles';

interface IProps {
  currentStep: number;
}

export const Navbar: React.FC<IProps> = ({
  currentStep
}) => {

  const icons = {
    todo: <FaRegCircle />,
    doing: <FaEllipsisH />,
    done: <FaCheck />
  }

  function renderStyles(currentStep: number, thisStep: number) {
    if (currentStep < thisStep) {
      return "todo"
    }
    if (currentStep === thisStep) {
      return "doing"
    }
    return "done";
  }

  return (
    <NavContainer>
      <NavItem
        progress={renderStyles(currentStep, 0)}
        value={0}
      >
        <NavIndex>1</NavIndex>
        <NavTexto>{"Dados Básicos"}</NavTexto>
        <NavIcon progress={renderStyles(currentStep, 0)}>
          {icons[renderStyles(currentStep, 0)]}
        </NavIcon>
      </NavItem>

      <NavItem
        progress={renderStyles(currentStep, 1)}
        value={1}
      >
        <NavIndex>2</NavIndex>
        <NavTexto>{"Informações Adicionais"}</NavTexto>
        <NavIcon progress={renderStyles(currentStep, 1)}>
          {icons[renderStyles(currentStep, 1)]}
        </NavIcon>
      </NavItem>

      <NavItem
        progress={renderStyles(currentStep, 2)}
        value={2}
      >
        <NavIndex>3</NavIndex>
        <NavTexto>{"Contribuições"}</NavTexto>
        <NavIcon progress={renderStyles(currentStep, 2)}>
          {icons[renderStyles(currentStep, 2)]}
        </NavIcon>
      </NavItem>

      <NavItem
        progress={renderStyles(currentStep, 3)}
        value={3}
      >
        <NavIndex>4</NavIndex>
        <NavTexto>{"Resultado"}</NavTexto>
        <NavIcon progress={renderStyles(currentStep, 3)}>
          {icons[renderStyles(currentStep, 3)]}
        </NavIcon>
      </NavItem>
    </NavContainer>
  );
}