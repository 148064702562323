import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { MensagemEntidade } from "../entidades/MensagemEntidade";

class Mensagem extends BaseService {
	constructor() {
		super("Mensagem");
	}

	BuscarTodas = () =>
		this.CreateRequest<Array<MensagemEntidade>>(RequestType.GET, `BuscarTodas`);

	BuscarporFundacaoEmpresaPlano = (cdFundacao: string, cdEmpresa: string, cdPlano: string) =>
		this.CreateRequest<Array<MensagemEntidade>>(RequestType.GET, `BuscarporFundacaoEmpresaPlano/${cdFundacao}/${cdEmpresa}/${cdPlano}`);

	BuscarPorPlano = (cdPlano: string) =>
		this.CreateRequest<Array<MensagemEntidade>>(RequestType.GET, `BuscarPorPlano/${cdPlano}`);

	Criar = (mensagem: MensagemEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Criar`, mensagem);

}

export const MensagemService = new Mensagem();
