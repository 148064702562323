import React, { useRef, useState } from 'react';
import { Input, Alert, Form, Button } from '@intech/web-ui';
import { useHistory } from 'react-router-dom';
import { Session } from "@intech/react-service";

// import { Subtitle } from './styles';

import { FaArrowRight, FaEye, FaEyeSlash } from 'react-icons/fa';
import { UsuarioService } from '@services';
import { LoginEntidade } from '@entidades';

import { BaseAuth } from './Base';

// import "./index.scss";

export { EsqueciSenha } from "./EsqueciSenha";
// export { Termos } from "./Termos";
// export { PrimeiroAcesso } from "./PrimeiroAcesso";

export const Login: React.FC = () => {
  const history = useHistory();
  const form = useRef<Form>(null);

  const [cpf, setCpf] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [senhaVisivel, setSenhaVisivel] = useState(false);
  const [AlertText, setAlertText] = useState<React.ReactNode>("");

  async function onLoginSubmit() {
    setAlertText("");

    try {
      const erros = await form.current.validate();
      if (erros) {
        setAlertText(erros);
        return null;
      }

      const user = new LoginEntidade();
      user.Cpf = cpf;
      user.Senha = password;

      const login = await UsuarioService.Login(user);

      await Session.setToken(login.AccessToken);

      const matriculas = await UsuarioService.BuscarMatriculas();

      // Se tiver só uma matricula, "auto seleciona" ela
      if (matriculas.length > 1) {
        history.push("/selecionarMatricula");
      } else {
        history.push("/");
      }
    }
    catch (e) {
      const error = e.response ? e.response.data : e;
      console.log(error);
      setAlertText(error);
    }
  };

  return (
    <BaseAuth>
      <h5>
        <b>Área de Acesso Restrito</b>
        <br />
        <small>
          Para informações, entre em contato com a{" "}
          <a href="http://www.preves.es.gov.br/">
            Preves
          </a>
        </small>
        <br />
        <br />
      </h5>

      <Form ref={form}>
        <Input
          value={cpf}
          onChange={setCpf}
          placeholder='CPF (somente números)'
          required
        />

        <Input
          value={password}
          onChange={setPassword}
          placeholder={"Senha"}
          type={senhaVisivel ? 'text' : 'password'}
          icon={senhaVisivel ? <FaEyeSlash /> : <FaEye />}
          buttonLeft={false}
          group
          onButtonClick={() => setSenhaVisivel(old => !old)}
          required
        />

        <Button
          icon={<FaArrowRight />}
          onClick={onLoginSubmit}
          type={"primary"}
          title={"Login"}
          loadingText={"Carregando..."}
          usesLoading
          // block
          className={"w-100 mb-2"}
          submit
        />

        <Button
          icon={<FaArrowRight />}
          onClick={() => history.push('esqueciSenha')}
          type='light'
          title={"Esqueci Minha Senha / Primeiro Acesso"}
          // block
          className={"w-100 mb-2"}
        />

        <br />

        <Alert type={"danger"}>
          {AlertText}
        </Alert>
      </Form>
    </BaseAuth>
  );
}
