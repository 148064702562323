import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { WebBloqueioFuncEntidade } from "../entidades/WebBloqueioFuncEntidade";
import { FuncionalidadeEntidade } from "../entidades/FuncionalidadeEntidade";

class Funcionalidade extends BaseService {
  constructor() {
    super("Funcionalidade");
  }

  Buscar = () =>
    this.CreateRequest<WebBloqueioFuncEntidade[]>(RequestType.GET, `Buscar`);

  BuscarPorIndAtivo = (IND_ATIVO: string) =>
    this.CreateRequest<FuncionalidadeEntidade[]>(RequestType.GET, `BuscarPorIndAtivo/${IND_ATIVO}`);

  BuscarBloqueiosPorNumFuncionalidade = (NUM_FUNCIONALIDADE: number, CD_PLANO: string, CD_PLANO2: string, CD_PLANO3: string) =>
    this.CreateRequest<string>(RequestType.GET, `BuscarBloqueiosPorNumFuncionalidade/${NUM_FUNCIONALIDADE}/${CD_PLANO}/${CD_PLANO2}/${CD_PLANO3}`);

}

export const FuncionalidadeService = new Funcionalidade();
