import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { FileUploadEntidade } from "../entidades/FileUploadEntidade";

class Upload extends BaseService {
	constructor() {
		super("Upload");
	}

	UploadFile = (model: FileUploadEntidade) =>
		this.CreateRequest<any>(RequestType.POST, ``, model);

}

export const UploadService = new Upload();
