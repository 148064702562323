import React from "react";
import { StaticField } from "@intech/web-ui";
import { InfoRendEntidade } from "@entidades";

interface LinhaProps {
  linha: InfoRendEntidade
}

export interface TdProps {
  align: "left" | "right";
}

export const Linha: React.FC<LinhaProps> = ({
  linha
}) => {

  function ToMoney(Valor: number | undefined) {
    if (Valor === undefined)
      return 0
    return (
      Valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    );
  }

  return (
    <tr>
      <td align={"left"}>{linha.DES_INFO_REND}</td>
      <td align={"right"} style={{ width: 150 }}>
        {linha.VAL_LINHA != 0 && (
          <b>
            <StaticField
              value={linha.VAL_LINHA}
              type={linha.COD_LINHA === "608" ? "text" : "money"}
            />
          </b>
        )}
      </td>
    </tr>
  );
}
