import React, { Component } from 'react';

import { History } from "history";

import { MasterPage } from "@components";

import './slide.scss';

import Passo1 from "./Passo1";
import Passo2 from "./Passo2";
import Passo3 from "./Passo3";
import Passo4 from './Passo4';

import { FuncionarioDados, IndiceEntidade, PlanoVinculadoEntidade, SalarioBaseEntidade, SaldoContribuicoesEntidade, UltimaContribuicaoEntidade } from '@entidades';

import _ from "lodash";
import { NumFuncionalidade } from '@src/helpers/BloqueioFuncionalidade';
import { Navbar } from './Navbar';

interface Props {
  history?: History;
  location?: any;
  match?: any;
  textoModal?: any;
}

interface State {
  passo: number,
  totalPassos: number,
  passoFinal: boolean,

  dados: any,
}

export class Simulador extends Component<Props, State> {
  static propTypes: { steps: any; printBtn: any; };

  constructor(props: Props) {
    super(props);

    this.state = {
      passo: 0,
      totalPassos: 3,
      passoFinal: false,

      dados: {
        // States Passo 1:
        cdPlano: "",
        plano: new PlanoVinculadoEntidade(),
        salario: new SalarioBaseEntidade(),
        saldos: new SaldoContribuicoesEntidade(),
        ultimaContribuicao: new UltimaContribuicaoEntidade(),
        listaPlanos: [],
        dadosPessoais: new FuncionarioDados(),
        indice: new IndiceEntidade(),

        // States Passo 2:
        idadeMinima: 40,
        idadeMaxima: 75,
        idadeAposentadoria: 20,
        idadeAtual: 40,

        percentualAVista: 0,
        aporte: 0,
        termoAceito: false,

        tempoAposentadoriaMinima: 20,
        tempoAposentadoriaMaxima: 50,
        tempoAposentadoria: 20,

        tempoSobrevivenciaMinima: 0,
        tempoSobrevivenciaMaxima: 20,
        tempoSobrevivencia: 5,

        remuneracaoInicialMinima: 0,
        remuneracaoInicialMaxima: 100000,
        remuneracaoInicial: 0,

        remuneracaoFinalMinima: 0,
        remuneracaoFinalMaxima: 100000,
        remuneracaoFinal: 0,

        taxaCrescimentoSalarial: 2,
        taxaCrescimentoSalarialMinima: 2,
        taxaCrescimentoSalarialMaxima: 3,

        rgps: 0,

        termosAceitos: false,

        // States Passo 3:
        percentualBasicoParticipante: 3,
        percentualBasicoParticipanteMinimo: 3,
        percentualBasicoParticipanteMaximo: 8.5,

        beneficioRiscoInvalidezParticipante: 0,
        beneficioRiscoInvalidezParticipanteMinimo: 0,
        beneficioRiscoInvalidezParticipanteMaximo: 1,

        beneficioRiscoMorteParticipante: 0,
        beneficioRiscoMorteParticipanteMinimo: 0,
        beneficioRiscoMorteParticipanteMaximo: 1,

        beneficioRiscoSoma: 0,

        aposentadoriaAdicional: 0,
        aposentadoriaAdicional2: "",
        invalidezAdicional: 0.00,
        pensaoMorteAdicional: 0,
        sobrevivenciaAdicional: 0,
        carregamentoContribuicaoAdicional: 0,
        totalizadorTaxaAposentadoria: 0,
        totalizadorTaxaContribuicao: 0,

        sobrevivenciaParticipante: 0,
        // Multiplica o valor do percentual basico por 0.06
        carregamentoContribuicaoBasicaParticipante: 0.18,

        tipoAtivo: '',

        steps: [
          { titulo: 'Dados Básicos' },
          { titulo: 'Informações Adicionais' },
          { titulo: 'Contribuições' },
          { titulo: 'Resultado' },
        ],
      },
    }

  }

  proximoPasso = () => {
    var { passo } = this.state

    passo = passo + 1

    if (passo >= this.state.totalPassos) {
      this.setState({
        passo: this.state.totalPassos,
        passoFinal: true
      })
    }
    else {
      this.setState({
        passo: passo,
        passoFinal: false
      })
    }
  }

  passoAnterior = () => {
    var { passo } = this.state

    if (passo > 0) {
      passo = passo - 1
    }

    this.setState({
      passo: passo,
      passoFinal: false
    })
  }

  passoInicial = () => {
    this.setState({
      passo: 0,
      passoFinal: false
    })
  }

  render() {
    return (
      <MasterPage Funcionalidade={NumFuncionalidade.SIMULADOR_APOSENTADORIA} {...this.props}>
        <div className='container'>
          <Navbar currentStep={this.state.passo} />
          <hr />

          {this.state.passo === 0 &&
            <Passo1
              Dados={this.state.dados}
              proximoPasso={this.proximoPasso}
            />
          }

          {this.state.passo === 1 &&
            <Passo2
              Dados={this.state.dados}
              proximoPasso={this.proximoPasso}
              passoAnterior={this.passoAnterior}
            />
          }

          {this.state.passo === 2 &&
            <Passo3
              Dados={this.state.dados}
              proximoPasso={this.proximoPasso}
              passoAnterior={this.passoAnterior}
            />
          }

          {this.state.passo === 3 &&
            <Passo4
              Dados={this.state.dados}
              passoAnterior={this.passoAnterior}
              passoInicial={this.passoInicial}
            />
          }
        </div>
      </MasterPage >
    );
  }
}

export default Simulador