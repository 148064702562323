import React, { useEffect, useState } from "react";
import { Row, Col, Box, StaticField, Alert } from "@intech/web-ui";

import { FuncionarioService } from "@services";
import { FuncionarioDados } from "@entidades";
import moment from "moment";
import { buscarBloqueio, NumFuncionalidade } from "@src/helpers/BloqueioFuncionalidade";
import { LoadingComponent, MasterPage } from "@components";

export const DadosPessoais: React.FC = ({ ...rest }) => {
  const [Loading, setLoading] = useState<boolean>(false);
  const [MotivoBloqueio, setMotivoBloqueio] = useState<string>("");
  const [Dados, setDados] = useState<FuncionarioDados>(new FuncionarioDados());

  useEffect(() => {
    (async () => {
      setLoading(true);
      setMotivoBloqueio(await buscarBloqueio(NumFuncionalidade.SEUS_DADOS));

      const dadosPessoais = await FuncionarioService.Buscar();

      setDados(dadosPessoais);

      setLoading(false);
    })();
  }, []);

  if (MotivoBloqueio !== "") {
    return (
      <MasterPage {...rest}>
        <Alert type={"danger"}>
          {MotivoBloqueio}
        </Alert>
      </MasterPage>
    );
  }

  return (
    <MasterPage Funcionalidade={NumFuncionalidade.SEUS_DADOS} {...rest}>
      {Loading && <LoadingComponent />}

      {Dados.DadosPessoais && !Loading &&
        <Row>
          <Col className={"col-lg-6"}>
            <Box title={"Dados Pessoais"}>
              <Row form>
                <StaticField
                  title="Nome"
                  value={Dados.DadosPessoais.NOME_ENTID}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Sexo"
                  value={Dados.SEXO}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Estado Civil"
                  value={Dados.DS_ESTADO_CIVIL}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="RG"
                  value={Dados.DadosPessoais.NU_IDENT}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Órgão Emissor RG"
                  value={Dados.DadosPessoais.ORG_EMIS_IDENT}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Emissão RG"
                  value={Dados.DadosPessoais.DT_EMIS_IDENT ?
                    moment(Dados.DadosPessoais.DT_EMIS_IDENT, "DD/MM/YYYY").format("DD/MM/YYYY")
                    : "-"
                  }
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="CPF"
                  value={Dados.DadosPessoais.CPF_CGC}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Data de nascimento"
                  value={Dados.DadosPessoais.DT_NASCIMENTO ?
                    moment(Dados.DadosPessoais.DT_NASCIMENTO, "DD/MM/YYYY").format("DD/MM/YYYY")
                    : "-"
                  }
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Idade"
                  value={Dados.IDADE}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Nome do Pai"
                  value={Dados.DadosPessoais.NOME_PAI}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Nome da Mãe"
                  value={Dados.DadosPessoais.NOME_MAE}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="E-mail"
                  value={Dados.DadosPessoais.EMAIL_AUX}
                  labelPosition={"up"}
                  inline
                />
              </Row>
            </Box>
          </Col>

          <Col>
            <Box title={"Dados Funcionais"}>

              <Row form>
                <StaticField
                  title="Empresa"
                  value={Dados.NOME_EMPRESA}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Matrícula"
                  value={Dados.Funcionario.NUM_MATRICULA}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Cargo"
                  value={Dados.Funcionario.DS_CARGO}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Lotação"
                  value={Dados.Funcionario.DS_LOTACAO}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Data de Admissão"
                  value={Dados.Funcionario.DT_ADMISSAO ?
                    moment(Dados.Funcionario.DT_ADMISSAO, "DD/MM/YYYY").format("DD/MM/YYYY")
                    : "-"
                  }
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Data de Recadastro"
                  value={Dados.Funcionario.DT_RECADASTRO ?
                    moment(Dados.Funcionario.DT_RECADASTRO, "DD/MM/YYYY").format("DD/MM/YYYY")
                    : "-"
                  }
                  labelPosition={"up"}
                  inline
                />
              </Row>

            </Box>

            <Box title={"Endereço"}>
              <Row form>
                <StaticField
                  title="Endereço"
                  value={Dados.Entidade.END_ENTID}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Número"
                  value={Dados.Entidade.NR_END_ENTID}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Complemento"
                  value={Dados.Entidade.COMP_END_ENTID}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="Bairro"
                  value={Dados.Entidade.BAIRRO_ENTID}
                  labelPosition={"up"}
                  inline
                />
              </Row>

              <Row form>
                <StaticField
                  title="Cidade"
                  value={Dados.Entidade.CID_ENTID}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="UF"
                  value={Dados.Entidade.UF_ENTID}
                  labelPosition={"up"}
                  inline
                />

                <StaticField
                  title="CEP"
                  value={Dados.CEP}
                  labelPosition={"up"}
                  inline
                />
              </Row>
            </Box>
          </Col>
        </Row>
      }
    </MasterPage>
  );
}