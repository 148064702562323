import { IndiceValoresEntidade } from "./IndiceValoresEntidade";

export class IndiceEntidade {
	public COD_IND: string;
	public DESC_IND: string;
	public PERIODIC: string;
	public DEC_PRORATA?: number;
	public PRORATA_DU: string;
	public ATRASADO: string;
	public CDI: string;
	public ANBID: string;
	public DIA_ANIV?: number;
	public COD_CETIP: string;
	public COD_CUSTOD: string;
	public COD_SPC: string;
	public VALORES: Array<IndiceValoresEntidade>;
}
