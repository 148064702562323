import React from "react";
import axios from "axios";
import {
    Box, Tabela, ColunaTabela, Botao, TipoBotao, Row, Col, CampoTexto, PosicaoTituloCampoTexto,
    Alerta, TipoAlerta, Combo, PosicaoTituloCombo, Form, ModalConfirma
} from "@intechprev/componentes-web";


import {  DocAtualizacaoCadastralService } from "../../services";

import config from '../../config.json';
import { FuncionarioDados } from "../../entidades";

const apiUrl = config.apiUrl;

interface Props {
    history?: History;
    match?: any;

    dados: FuncionarioDados;
}

interface State {
    documentos: Array<any>,
    nomeDocumento: string,
    arquivoUpload: string,
    podeCriarDocumento: boolean,
    oidArquivoUpload: number,
    oidPasta: number,
    pastaAtual: any,
    pastaPai: any,
    visibilidadeFileInput: boolean,
    uploadPercentage: number,
    uploading: boolean,
}


export class DocumentosUpload extends React.Component<Props, State> {

    private alert = React.createRef<Alerta>();
    private formDocumento = React.createRef<Form>();
    public alertDocumento = React.createRef<Alerta>();

    constructor(props: Props) {
        super(props);

        this.state = {
            documentos: [],
            nomeDocumento: "",
            arquivoUpload: "",
            podeCriarDocumento: false,
            oidArquivoUpload: 0,
            oidPasta: 0, //props.match.params.pasta,

            pastaAtual: null,
            pastaPai: "",
            visibilidadeFileInput: true,
            uploadPercentage: 0,
            uploading: false,
        }
    }

    componentDidMount = async () => {
    }

    uploadFile = async (e: any) => {
        try {
            const formData = new FormData()
            var arquivoUpload = e.target.files[0];

            formData.append("File", arquivoUpload, arquivoUpload.name);

            await this.setState({ uploading: true });

            var { data: oidArquivoUpload } = await axios.post(apiUrl + '/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: async progressEvent => {
                    await this.setState({
                        uploadPercentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    });
                },
            });

            await this.setState({
                podeCriarDocumento: true,
                oidArquivoUpload,
                visibilidadeFileInput: false,
                uploading: false,
                uploadPercentage: 0
            });

            // grava tabela de documentos atualização cadastral
            DocAtualizacaoCadastralService.Incluir(oidArquivoUpload, this.props.dados.Funcionario.NUM_INSCRICAO, 0)
            
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return (
            <div className="tab-content" >
                <Box titulo={"UPLOAD DE DOCUMENTOS"}>
                    <Form ref={this.formDocumento}>

                        <CampoTexto 
                          contexto={this} 
                          nome={"nomeDocumento"} 
                          max={50} 
                          valor={this.state.nomeDocumento} 
                          titulo={"Título"} 
                          obrigatorio 
                          posicaoTitulo={PosicaoTituloCampoTexto.cima}
                          tituloClassName={"text-primary"}
                        />

                        <div className="form-group">

                            <label htmlFor="selecionar-documento"><b>Arquivo</b></label><br />

                            {this.state.uploading &&
                                <div className="progress" style={{ marginBottom: 10 }}>
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: this.state.uploadPercentage + "%" }}></div>
                                </div>
                            }

                            {this.state.visibilidadeFileInput && !this.state.uploading &&
                                <input name="selecionar-documento" id="selecionar-documento" type="file" onChange={this.uploadFile} />
                            }

                            {!this.state.visibilidadeFileInput && !this.state.uploading &&
                                <div>
                                    <Alerta tipo={TipoAlerta.success} mensagem={"Arquivo enviado com sucesso"} />
                                    <Botao titulo={"Enviar outro arquivo"} tipo={TipoBotao.light}
                                        onClick={async () => await this.setState({ visibilidadeFileInput: true, oidArquivoUpload: 0, podeCriarDocumento: false })} />
                                </div>
                            }
                            <hr />
                        </div>

                        <Alerta ref={this.alertDocumento} padraoFormulario tipo={TipoAlerta.danger} />

                    </Form>
                </Box>
            </div>
        );
    }
}