import { BaseService, RequestType, ResponseType } from "@intech/react-service";
import { EntidadeEntidade } from "../entidades/EntidadeEntidade";

class Entidade extends BaseService {
	constructor() {
		super("Entidade");
	}

	Atualizar = (entidade: EntidadeEntidade) =>
		this.CreateRequest<any>(RequestType.POST, `Atualizar`, entidade);

}

export const EntidadeService = new Entidade();
