import React from "react";
import axios from "axios";
import {
  Box, Tabela, ColunaTabela, Botao, TipoBotao, Row, Col, CampoTexto, PosicaoTituloCampoTexto,
  Alerta, TipoAlerta, Combo, PosicaoTituloCombo, Form, ModalConfirma
} from "@intechprev/componentes-web";
import {
  DependenteEntidade, GrauParentescoEntidade, SexoEntidade, EstadoCivilEntidade, PlanoVinculadoEntidade,
  DocumentoPastaEntidade, DocumentoEntidade, FuncionarioDados, BancoAgEntidade
} from "../../entidades";

import { DependenteService, PlanoService, DocAtualizacaoCadastralService, DocumentoService, BancoAgenciaService } from "../../services";

import { EnderecoDependente } from "./EnderecoDependente";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import config from '../../config.json';

const apiUrl = config.apiUrl;

interface Props {
  history?: History;
  match?: any;

  dadosSexo: Array<SexoEntidade>;
  dadosEstadoCivil: Array<EstadoCivilEntidade>,
  dadosGrauParentesco: Array<GrauParentescoEntidade>,
  dados: FuncionarioDados,
}

interface State {
  listaDependentes: Array<DependenteEntidade>;
  listaPlanos: Array<PlanoVinculadoEntidade>;
  dependente: DependenteEntidade;
  excluirDependente: DependenteEntidade;
  dadosBanco: Array<BancoAgEntidade>,
  dadosAgencia: Array<BancoAgEntidade>,
  modalVisivel: boolean;
  simNao: string;
  isInclusao: boolean;
  isAlteracao: boolean;
  modalStatus: boolean;
  modalDocumentosVisivel: boolean;
  isPesquisando: boolean;
  nomeDocumento: string;
  visibilidadeFileInput: boolean;
  uploading: boolean;
  podeCriarDocumento: boolean;
  oidArquivoUpload: number;
  oidPasta: number;
  pastaAtual: any;
  pastaPai: any;
  uploadPercentage: number;
  alertaUpload: boolean;
  totalArquivoUpload: number;
}

const comboSimNao = [
  {
    texto: "SIM",
    valor: "S"
  },
  {
    texto: "NÃO",
    valor: "N"
  }
]

export class Dependentes extends React.Component<Props, State> {

  private alert = React.createRef<Alerta>();
  private formDep = React.createRef<Form>();
  private formDocumento = React.createRef<Form>();
  public alertDocumento = React.createRef<Alerta>();

  constructor(props: Props) {
    super(props);

    this.state = {
      listaDependentes: [],
      listaPlanos: [],
      dependente: new DependenteEntidade(),
      excluirDependente: new DependenteEntidade(),
      dadosBanco: new Array<BancoAgEntidade>(),
      dadosAgencia: new Array<BancoAgEntidade>(),

      modalVisivel: false,
      modalDocumentosVisivel: false,
      simNao: comboSimNao[0].valor,
      isInclusao: false,
      isAlteracao: false,
      modalStatus: false,
      isPesquisando: true,
      nomeDocumento: "",
      visibilidadeFileInput: true,
      uploading: false,
      podeCriarDocumento: false,
      oidArquivoUpload: 0,
      oidPasta: 0,
      pastaAtual: null,
      pastaPai: "",
      uploadPercentage: 0,
      alertaUpload: false,
      totalArquivoUpload: 0,
    }
  }

  componentDidMount = async () => {
    await this.load();
  }

  load = async () => {
    await this.setState({ isPesquisando: true })

    var dependentes = await DependenteService.BuscarTodos();
    // busca os planos em que o participante esta vinculado
    var planos = await PlanoService.Buscar();

    var bancos = await BancoAgenciaService.BuscarBancos();

    await this.setState({
      listaDependentes: dependentes,
      listaPlanos: planos,
      isPesquisando: false,
      dadosBanco: bancos,
    });

    this.ObterAgencias();
  }

  ObterAgencias = async () => {
    if (this.state.dependente.NUM_BANCO === undefined || this.state.dependente.NUM_BANCO === null || this.state.dependente.NUM_BANCO === "")
      var agencias = await BancoAgenciaService.BuscarAgenciaDoBanco("000");
    else
      var agencias = await BancoAgenciaService.BuscarAgenciaDoBanco(this.state.dependente.NUM_BANCO);

    await this.setState({ dadosAgencia: agencias })
  }

  salvarDependente = async () => {
    console.log("Salvando");

    //await this.setState({ modalVisivel: !this.state.modalVisivel })

    try {
      if (this.state.isAlteracao) {
        await DependenteService.Atualizar(this.state.dependente);
        toast.success("Registro Salvo com sucesso!");
      }

      if (this.state.isInclusao) {
        await DependenteService.Incluir(this.state.dependente);
        toast.success("Registro Incluído com sucesso!");
      }
      await this.load();
      this.toggleModal();
    }
    catch {
      toast.success("Erro ao tentar salvar o Registro!");
    }
  }

  toggleModalExcluir = async (dependente: DependenteEntidade) => {
    await this.setState({
      modalStatus: !this.state.modalStatus,
      excluirDependente: dependente,
    });
  }

  toggleModalDocumentos = async () => {
    await this.setState({
      modalDocumentosVisivel: !this.state.modalDocumentosVisivel
    });
  }

  cancelaExclusaoDependente = async () => {
    this.setState({ modalStatus: !this.state.modalStatus })
  }

  confirmaExclusaoDependente = async (dependente: DependenteEntidade) => {
    this.setState({ modalStatus: !this.state.modalStatus })
    try {
      await DependenteService.Excluir(this.state.excluirDependente);
      toast.warning("Registro Excluido com sucesso!");
    }
    catch {
      toast.success("Erro ao Excluir o Registro!");
    }

    await this.load();
  }

  selecionarDependente = async (dependente: DependenteEntidade) => {

    await this.setState({
      dependente,
      isAlteracao: true,
      isInclusao: false,
    });

    await this.toggleModal();
  }

  incluirDependente = async () => {
    var dependente = new DependenteEntidade();
    dependente.CD_PLANO = this.state.listaPlanos[0].CD_PLANO;
    dependente.SEXO_DEP = this.props.dadosSexo[0].CD_SEXO;
    dependente.CD_ESTADO_CIVIL = this.props.dadosEstadoCivil[0].CD_ESTADO_CIVIL;
    dependente.CD_GRAU_PARENTESCO = this.props.dadosGrauParentesco[0].CD_GRAU_PARENTESCO;
    dependente.ABATIMENTO_IRRF = comboSimNao[0].valor;

    await this.setState({
      dependente,
      isAlteracao: false,
      isInclusao: true,
    });

    await this.toggleModal();
  }


  toggleModal = async () => {
    await this.setState({
      modalVisivel: !this.state.modalVisivel
    });
  }

  validarDep = async () => {
    await this.alert.current.limparErros();
    await this.formDep.current.validar();

    if (this.state.totalArquivoUpload == 0 && this.state.isInclusao) {
      await this.setState({ alertaUpload: true })
    }
    else
      await this.setState({ alertaUpload: false })

    if (!this.state.alertaUpload && this.formDep.current.state.valido) {
      this.salvarDependente();
    }
  }

  uploadFile = async (e: any) => {
    try {
      const formData = new FormData()
      var arquivoUpload = e.target.files[0];
      var total = this.state.totalArquivoUpload + 1;

      formData.append("File", arquivoUpload, arquivoUpload.name);

      await this.setState({ uploading: true });

      var { data: oidArquivoUpload } = await axios.post(apiUrl + '/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: async progressEvent => {
          await this.setState({
            uploadPercentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
          });
        },
      });

      await this.setState({
        podeCriarDocumento: true,
        oidArquivoUpload,
        visibilidadeFileInput: false,
        uploading: false,
        uploadPercentage: 0,
        totalArquivoUpload: total,
        alertaUpload: false,
      });

      // grava tabela de documentos atualização cadastral
      DocAtualizacaoCadastralService.Incluir(oidArquivoUpload, this.props.dados.Funcionario.NUM_INSCRICAO, 0)

    } catch (err) {
      console.error(err);
    }
  }


  renderModal = () => {
    if (this.state.modalVisivel) {
      return (
        <div className="modal" role="dialog" style={{ "display": this.state.modalVisivel ? "inherit" : "none" }}>
          <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div className="modal-content">

              <div className="modal-header">
                <h5 className="modal-title">Dependente</h5>
                <button type="button" className="close" data-dismiss="modal" onClick={this.toggleModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Form ref={this.formDep}>
                  <Row>
                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"NOME_DEP"}
                        obrigatorio
                        valor={this.state.dependente.NOME_DEP}
                        titulo={"Nome Dependente"}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Combo
                        contexto={this}
                        parent="dependente"
                        nome={"CD_PLANO"}
                        titulo={"Plano"}
                        obrigatorio
                        valor={this.state.dependente.CD_PLANO}
                        padrao={this.state.dependente.CD_PLANO}
                        opcoes={this.state.listaPlanos}
                        nomeMembro={"DS_PLANO"}
                        valorMembro={"CD_PLANO"}
                        posicaoTitulo={PosicaoTituloCombo.cima}
                        tituloClassName={"text-primary"}
                      />
                    </Col>
                    <Col>
                      <Combo
                        contexto={this}
                        parent="dependente"
                        nome={"SEXO_DEP"}
                        titulo={"Sexo"}
                        obrigatorio
                        valor={this.state.dependente.SEXO_DEP}
                        padrao={this.state.dependente.SEXO_DEP}
                        opcoes={this.props.dadosSexo}
                        nomeMembro={"DS_SEXO"}
                        valorMembro={"CD_SEXO"}
                        posicaoTitulo={PosicaoTituloCombo.cima}
                        tituloClassName={"text-primary"}
                      />
                    </Col>
                    <Col>
                      <Combo
                        contexto={this}
                        parent="dependente"
                        nome={"CD_ESTADO_CIVIL"}
                        titulo={"Estado Civil"}
                        obrigatorio
                        valor={this.state.dependente.CD_ESTADO_CIVIL}
                        padrao={this.state.dependente.CD_ESTADO_CIVIL}
                        opcoes={this.props.dadosEstadoCivil}
                        nomeMembro={"DS_ESTADO_CIVIL"}
                        valorMembro={"CD_ESTADO_CIVIL"}
                        textoVazio={"Escolha o Estado Civil"}
                        posicaoTitulo={PosicaoTituloCombo.cima}
                        tituloClassName={"text-primary"}
                      />
                    </Col>
                    <Col>
                      <Combo
                        contexto={this}
                        parent="dependente"
                        nome={"CD_GRAU_PARENTESCO"}
                        titulo={"Grau Parentesco"}
                        obrigatorio
                        valor={this.state.dependente.CD_GRAU_PARENTESCO}
                        padrao={this.state.dependente.CD_GRAU_PARENTESCO}
                        opcoes={this.props.dadosGrauParentesco}
                        nomeMembro={"DS_GRAU_PARENTESCO"}
                        valorMembro={"CD_GRAU_PARENTESCO"}
                        posicaoTitulo={PosicaoTituloCombo.cima}
                        tituloClassName={"text-primary"}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"IDENTIDADE"}
                        titulo="RG"
                        valor={this.state.dependente.IDENTIDADE}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>
                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"ORGAO_EXP"}
                        titulo="Órgão Emissor RG"
                        valor={this.state.dependente.ORGAO_EXP}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>

                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"DT_EXPEDICAO"}
                        mascara={"99/99/9999"}
                        valor={this.state.dependente.DT_EXPEDICAO}
                        titulo={"Data Emissão RG"}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"CPF"}
                        titulo="CPF"
                        valor={this.state.dependente.CPF}
                        tamanhoCampo="12"
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>
                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"DT_NASC_DEP"}
                        mascara={"99/99/9999"}
                        obrigatorio
                        valor={this.state.dependente.DT_NASC_DEP}
                        titulo={"Data Nascimento"}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"NATURALIDADE"}
                        valor={this.state.dependente.NATURALIDADE}
                        titulo={"Naturalidade"}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>

                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"UF_NATURALIDADE"}
                        valor={this.state.dependente.UF_NATURALIDADE}
                        titulo={"Uf Naturalidade"}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>

                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"FONE_CELULAR"}
                        valor={this.state.dependente.FONE_CELULAR}
                        titulo={"Fone Celular"}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>

                  </Row>
                  <Row>
                    <Col>
                      <CampoTexto
                        contexto={this}
                        parent="dependente"
                        nome={"EMAIL_DEP"}
                        valor={this.state.dependente.EMAIL_DEP}
                        titulo={"Email"}
                        tituloClassName={"text-primary"}
                        posicaoTitulo={PosicaoTituloCampoTexto.cima}
                      />
                    </Col>
                  </Row>

                  <EnderecoDependente
                    dependente={this.state.dependente}
                  />

                  <Box titulo="Dados Bancários">
                    <Row>
                      <Col>
                        <Combo
                          contexto={this}
                          parent="dependente"
                          nome={"NUM_BANCO"}
                          titulo={"Banco"}
                          valor={this.state.dependente.NUM_BANCO}
                          padrao={this.state.dependente.NUM_BANCO}
                          opcoes={this.state.dadosBanco}
                          nomeMembro={"DESC_BCO_AG"}
                          valorMembro={"COD_BANCO"}
                          posicaoTitulo={PosicaoTituloCombo.cima}
                          tituloClassName={"text-primary"}
                          onChange={this.ObterAgencias}
                        />
                      </Col>
                      <Col>
                        <Combo
                          contexto={this}
                          parent="dependente"
                          nome={"NUM_AGENCIA"}
                          titulo={"Agência"}
                          valor={this.state.dependente.NUM_AGENCIA}
                          padrao={this.state.dependente.NUM_AGENCIA}
                          opcoes={this.state.dadosAgencia}
                          nomeMembro={"COD_AGENC"}
                          valorMembro={"COD_AGENC"}
                          posicaoTitulo={PosicaoTituloCombo.cima}
                          tituloClassName={"text-primary"}
                        />
                      </Col>
                      <Col>
                        <CampoTexto
                          contexto={this}
                          nome={"NUM_CONTA"}
                          parent="dependente"
                          titulo="Conta"
                          valor={this.state.dependente.NUM_CONTA}
                          tituloClassName={"text-primary"}
                          posicaoTitulo={PosicaoTituloCampoTexto.cima}
                        />
                      </Col>
                    </Row>
                  </Box>

                  {this.state.isInclusao &&
                    <Box titulo="Documento Comprobatório">
                      <Row>
                        <Col>
                          <CampoTexto
                            contexto={this}
                            nome={"nomeDocumento"}
                            max={50}
                            valor={this.state.nomeDocumento}
                            titulo={"Título"}
                            obrigatorio
                            posicaoTitulo={PosicaoTituloCampoTexto.cima}
                            tituloClassName={"text-primary"}
                          />

                          <div className="form-group">

                            <label htmlFor="selecionar-documento"><b>Documento Comprobatório</b></label><br />

                            {this.state.uploading &&
                              <div className="progress" style={{ marginBottom: 10 }}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: this.state.uploadPercentage + "%" }}></div>
                              </div>
                            }

                            {this.state.visibilidadeFileInput && !this.state.uploading &&
                              <input name="selecionar-documento" id="selecionar-documento" type="file" onChange={this.uploadFile} required />
                            }

                            {!this.state.visibilidadeFileInput && !this.state.uploading &&
                              <div>
                                <Alerta tipo={TipoAlerta.success} mensagem={"Documento Comprobatório enviado com sucesso"} />
                                <Botao titulo={"Enviar outro Documento"} tipo={TipoBotao.light}
                                  onClick={async () => await this.setState({ visibilidadeFileInput: true, oidArquivoUpload: 0, podeCriarDocumento: false })} />
                              </div>
                            }

                            {this.state.alertaUpload &&
                              <Alerta tipo={TipoAlerta.danger} mensagem={"É necessário anexar um Documento Comprobatório...."} />
                            }

                            <hr />
                          </div>

                        </Col>
                      </Row>
                    </Box>
                  }
                  <Alerta ref={this.alert} tipo={TipoAlerta.danger} padraoFormulario />

                </Form>
              </div>

              <div className="modal-footer">
                <Botao onClick={this.validarDep} titulo={"Salvar"} submit />
                <Botao onClick={this.toggleModal} tipo={TipoBotao.info} titulo={"Fechar"} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    else
      return <div></div>
  }

  render() {
    return (
      <div>
        {!this.state.isPesquisando &&
          <div className="tab-content" >
            <Box>
              <div>
                <Row>
                  <Col col-lg="4">
                    <Botao
                      titulo={"Incluir Novo Dependente"}
                      onClick={this.incluirDependente}
                      className={"mb-3"}
                      submit
                      usaLoading
                      tipo={TipoBotao.info}
                      icone={"fa fa-plus"}
                    />
                  </Col>
                </Row>
              </div>

              <Tabela dados={this.state.listaDependentes}
                paginacaoHabilitada={false}
                onSelecionar={this.selecionarDependente}
                onExcluir={this.toggleModalExcluir}
              >
                <ColunaTabela titulo={"Nome"} propriedadeValor={"NOME_DEP"} />
              </Tabela>

              <ModalConfirma
                onClose={this.cancelaExclusaoDependente}
                onConfirm={this.confirmaExclusaoDependente}
                visivel={this.state.modalStatus}
                titulo={"Atenção!"}
              >
                Confirma a Exclusão do Registro ?
              </ModalConfirma>

              <ToastContainer />

              {this.renderModal()}
            </Box>
          </div>
        }

        {this.state.isPesquisando &&
          <div id="pesquisando" className="alert alert-info">Aguarde pesquisando os Dependentes...</div>
        }
      </div>
    );
  }
}