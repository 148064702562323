import React, { useEffect, useState } from 'react';
import { PlanoService, FichaFinanceiraAssistidoService } from "@services";
import { Alert } from "@intech/web-ui";
import { useHistory } from "react-router-dom";
import { PlanoVinculadoEntidade, ContrachequeEntidade } from '@entidades';
import { Especie } from './Especie';
import { LoadingComponent, MasterPage } from '@components';
import { NumFuncionalidade } from '../../helpers/BloqueioFuncionalidade';

export const Contracheque: React.FC = (props) => {
  const history = useHistory();

  const [AlertText, setAlertText] = useState<string>("");
  const [Planos, setPlanos] = useState<Array<PlanoVinculadoEntidade>>([]);
  const [Especies, setEspecies] = useState<Array<ContrachequeEntidade>>(undefined);

  useEffect(() => {
    (async () => {
      try {
        var resultPlanos = await PlanoService.Buscar();
        await BuscarDatas(resultPlanos);
      }
      catch (err) {
        if (err.message.indexOf("401") > -1) {
          history.push("/login");
        }
        else {
          const error = err.response ? err.response.data : err;
          setAlertText(error);
        }
      }
    })();
  }, []);

  async function BuscarDatas(planos: Array<PlanoVinculadoEntidade>) {
    for (var i = 0; i < planos.length; i++) {
      var datas = await FichaFinanceiraAssistidoService.BuscarDatas(planos[i].CD_PLANO);
      //planos[i].contracheque = datas;

      setPlanos([...Planos, planos[i]]);
      setEspecies(datas);
    }
  }

  function renderEspecies() {
    if (Especies !== undefined) {
      if (Especies.length > 0) {
        return Especies.map((especie: ContrachequeEntidade, index: number) => {
          return (
            <Especie key={index} especie={especie} />
          )
        });
      }

      return (
        <Alert type={"info"}>
          {"Nenhum contracheque disponível para este plano."}
        </Alert>
      );
    }

    if (AlertText !== "") {
      return (
        <Alert type={"danger"}>
          {AlertText}
        </Alert>
      );
    }

    return <LoadingComponent />;
  }

  return (
    <MasterPage Funcionalidade={NumFuncionalidade.CONTRACHEQUE_ASISSTIDOS_PENSIONISTAS} {...props}>
      {renderEspecies()}
    </MasterPage>
  );
}