import React from 'react';
import { Box } from '@intech/web-ui';
import { SaldoContribuicoesEntidade } from '@entidades';

interface IProps {
  saldoAcumulado: SaldoContribuicoesEntidade | undefined;
  referencia: string;
}

export const SaldoAcumulado: React.FC<IProps> = ({
  saldoAcumulado,
  referencia
}) => {

  function ToMoney(Valor: number | undefined) {
    if (Valor === undefined)
      return 0;
    if (Valor < 0)
      return 0;
    return (
      Valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    );
  }

  function renderContribuicao() {
    if (saldoAcumulado !== undefined) {
      return (
        <Box title={"Saldo Acumulado Atualizado"} badgeText={`Referência em ${referencia}`}>
          <h2 className={"text-primary text-center"}>Parabéns! Você já acumulou</h2>
          <h2 className={"text-primary text-center"}>{`R$ ${ToMoney(saldoAcumulado.ValorSaldoAtualizado)}`}</h2>

          <div className={"table-responsive"}>
            <table className={"table table-striped table-sm"}>
              <tbody>
                <tr>
                  <td style={{ "textAlign": "left" }}>
                    <b>
                      {"Subconta Participante"}
                    </b>
                  </td>
                  <td style={{ "textAlign": "right" }}>
                    <b>
                      R$ {ToMoney(saldoAcumulado.ValorParticipante)}
                    </b>
                  </td>
                </tr>

                <tr>
                  <td style={{ "textAlign": "left" }}>
                    <b>
                      {"Subconta Patrocinadora"}
                    </b>
                  </td>
                  <td style={{ "textAlign": "right" }}>
                    <b>
                      R$ {ToMoney(saldoAcumulado.ValorPatrocinadora)}
                    </b>
                  </td>
                </tr>

                <tr>
                  <td style={{ "textAlign": "left" }}>
                    <b>
                      {"Valores Portados"}
                    </b>
                  </td>
                  <td style={{ "textAlign": "right" }}>
                    <b>
                      R$ {ToMoney(saldoAcumulado.ValoresPortados)}
                    </b>
                  </td>
                </tr>

                <tr>
                  <td style={{ "textAlign": "left" }}>
                    <b>
                      {"TOTAL"}
                    </b>
                  </td>
                  <td style={{ "textAlign": "right" }}>
                    <b>
                      R$ {ToMoney(saldoAcumulado.ValorParticipante + saldoAcumulado.ValorPatrocinadora + saldoAcumulado.ValoresPortados)}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Box>
      );
    }

    return null;
  }

  return renderContribuicao();
}