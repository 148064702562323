import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DadosPessoaisEntidade, PlanoVinculadoEntidade } from '@entidades';
import { FuncionarioService, PlanoService } from '@services';
import { Assistido } from './Assistido';
import { Ativo } from './Ativo';
import { Desligado } from './Desligado';
import { ListaPlanos, LoadingComponent, MasterPage } from '@components';
import { Alerta, TipoAlerta } from '@intechprev/componentes-web';
import { Header, Subtitulo, Titulo } from './styles';

export const Home: React.FC = (props) => {
  const history = useHistory();

  const [DadosPessoais, setDadosPessoais] = useState<DadosPessoaisEntidade>(new DadosPessoaisEntidade());
  const [planos, setPlanos] = useState<Array<PlanoVinculadoEntidade>>([]);
  const [plano, setPlano] = useState<PlanoVinculadoEntidade>(null);
  const [cdPlano, setCdPlano] = useState("");
  const [Loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const dados = await FuncionarioService.Buscar();
        setDadosPessoais(dados.DadosPessoais);

        const planos = await PlanoService.Buscar();

        const temPlano = planos.length > 0;
        setPlanos(planos);
        setPlano(temPlano ? planos[0] : new PlanoVinculadoEntidade());
        setCdPlano(temPlano ? planos[0].CD_PLANO : "");
        setLoading(false);
      }
      catch (err) {
        if (err.message.indexOf("401") > -1) {
          history.push("/login");
        }
        setLoading(false);
      }
    })();
  }, []);

  function condicaoAtivo() {
    return (
      plano !== undefined && (
        plano.CD_CATEGORIA === "1" ||
        plano.CD_CATEGORIA === "3" ||
        plano.CD_CATEGORIA === "6" ||
        plano.CD_CATEGORIA === "5")
    );
  }

  function renderPlano() {
    if (Loading) {
      return (
        <LoadingComponent />
      );
    }
    if (plano) {
      if (condicaoAtivo())
        return <Ativo plano={plano} />
      else if (plano.CD_CATEGORIA === "2")
        return <Desligado plano={plano} />
      else if (plano.CD_CATEGORIA === "4")
        return <Assistido plano={plano} />
      // else if (pensionista)
      //   return <Pensionista plano={plano}/>;
    }
    return (
      <Alerta tipo={TipoAlerta.info} mensagem={"Nenhuma informação disponível para você neste momento."}
      />
    );
  }

  async function carregarPlano(Plano: PlanoVinculadoEntidade) {
    const CdPlano = Plano.CD_PLANO;
    const planoSelecionado = planos.filter(plano => plano.CD_PLANO === CdPlano)[0];

    setCdPlano(CdPlano);
    setPlano(planoSelecionado);
  };

  return (
    <MasterPage {...props}>

      <Header>
        <Titulo>
          Olá {DadosPessoais.NOME_ENTID}!{" "}
          {DadosPessoais.SEXO === "M" ? "Bem-vindo" : "Bem-vinda"} ao
            Portal PREVES!
          </Titulo>

        <Subtitulo>
          Atualmente você tem o(s) seguinte(s) planos com a gente:
          </Subtitulo>

        <div className="mb-3">(clique nos botões para mais informações)</div>

        <ListaPlanos planos={planos} onChange={carregarPlano} cdPlanoAtivo={cdPlano} />
      </Header>

      {renderPlano()}
    </MasterPage>
  );
}
