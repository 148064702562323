import { StaticField } from "@intech/web-ui";
import styled, { css } from "styled-components";

export const Titulo = styled.h2`
  display: flex;
  flex: 1;
  padding: 5px;
  justify-content: center;
`;

export const SubTitulo = styled.h5`
  display: flex;
  flex: 1;
  padding: 5px;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  font-weight: bold;
  font-size: 17px;
`;

export const HeaderProvDesc = styled.div`
  display: flex;
  padding: 0px 25px 0px 10px;
  align-items: center;
  margin: 3px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex: 1;
  padding: 5px;
`;

export const HeaderValor = styled.div`
  display: flex;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
`;

export const Rubrica = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  font-size: 15px;
  &:nth-of-type(2n+1) {
    background-color: rgba(0,0,0,0.09);
  }
`;