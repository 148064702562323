import React from 'react';
import moment from 'moment';
import { DependenteEntidade } from '@entidades';

import { LoadingComponent } from '@components';
import { Alert, Box } from '@intech/web-ui';
interface IProps {
  ListaDependentes: Array<DependenteEntidade>;
}

export const Pensionista: React.FC<IProps> = ({
  ListaDependentes
}) => {

  function renderDependentes() {

    if (ListaDependentes !== undefined) {
      if (ListaDependentes.length > 0) {
        const Itens = ListaDependentes.map((dep: DependenteEntidade, index: number) => {
          return (
            <tr key={index} className={"text-center"}>
              <td>
                {dep.DS_GRAU_PARENTESCO}
              </td>
              <td>
                {dep.NOME_DEP}
              </td>
              <td>
                {dep.SEXO_DEP == "F" ? "FEMININO" : "MASCULINO"}
              </td>
              <td>
                {dep.DT_NASC_DEP}
              </td>
              <td>
                {moment(dep.DT_TERMINO_VALIDADE, "DD/MM/YYYY").format("DD/MM/YYYY") === "31/12/2999" ? "Vitalício" : dep.DT_TERMINO_VALIDADE}
              </td>
            </tr>
          );
        });
        return (
          <Box title={"Beneficiários do Plano"}>
            <div className={"table-responsive"}>
              <table className={"table table-striped table-sm"}>
                <thead>
                  <tr className={"text-center"}>
                    <th style={{ "minWidth": "150px" }}>Grau de Parentesco</th>
                    <th style={{ "minWidth": "250px" }}>Nome</th>
                    <th>Sexo</th>
                    <th style={{ "minWidth": "150px" }}>Data de Nascimento</th>
                    <th style={{ "minWidth": "150px" }}>Data de Validade</th>
                  </tr>
                </thead>

                <tbody>
                  {Itens}
                </tbody>
              </table>
            </div>
          </Box>
        );
      }

      if (ListaDependentes.length === 0) {
        return <Alert type={"info"}>{"Nehum Beneficiário cadastrado para este plano."}</Alert>;
      }
    }

    return <LoadingComponent />;
  }

  return renderDependentes();
}