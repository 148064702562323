import { BaseService, RequestType, ResponseType } from "@intech/react-service";

class SimNaoParticipante extends BaseService {
	constructor() {
		super("SimNaoParticipante");
	}

	Calcular = (dados: any) =>
		this.CreateRequest<any>(RequestType.POST, `Calcular`, dados);

}

export const SimNaoParticipanteService = new SimNaoParticipante();
