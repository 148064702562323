import { darken, lighten } from "polished";
import styled from "styled-components";

export const PlanoButtonContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin: 10px;

  @media (min-width: 768px) {
    width: 350px;
  }
`;
// border: 1px solid ${(props) => props.theme.colors.primary};
export const PlanoButton = styled.div<any>`
  width: 100%;
  border: 1px solid #92B849;
  border-radius: 5px;
  background: ${(props) =>
    props.ativo ? "#92B849" : "#FFFFFF"};
  color: ${(props) => (props.ativo ? "#FFFFFF" : "#92B849")};
  padding: 5px;
  text-align: center;
  transition: background 0.3s;

  &:hover {
    background: ${(props) =>
    props.ativo
      ? darken(0.1, "#92B849")
      : lighten(0.4, "#FFFFFF")};
    cursor: pointer;
  }

  @media (min-width: 768px) {
    width: 350px;
  }
`;
