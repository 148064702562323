import styled, { css } from "styled-components";
import { lighten, darken } from "polished";
import { Col } from "@intech/web-ui";

export const Header = styled.div`
  margin-bottom: 30px;
`;

export const Titulo = styled.h3`

`;

export const Subtitulo = styled.h5`

`;

